import {
    Event_Schedule_Types_Enum as TypeEnum,
    EventFieldsFragment,
    EventScheduleFieldsFragment
} from '@graphql/hasura';
import { EventAccessContext } from '@lib/firebase';
import { formatDate } from '@lib/localization/date';
import {
    areIntervalsOverlapping,
    compareAsc,
    differenceInMinutes,
    isAfter,
    isBefore,
    isWithinInterval,
    sub
} from 'date-fns';

export type EventScheduleNode = {
    children: Array<EventScheduleNode>;
    parent?: EventScheduleNode;
} & EventScheduleFieldsFragment;

export function currentDate(): Date {
    if (typeof window !== 'undefined' && window.location.hash) {
        const debug_hash_prefix = 'debug-now-';
        const hash = window.location.hash.substring(1);
        if (hash.startsWith(debug_hash_prefix)) {
            const debugDate: any = new Date(hash.substr(debug_hash_prefix.length));
            if (debugDate instanceof Date) {
                return debugDate;
            }
        }
    }
    return new Date();
}

export function didEventScheduleStart(
    es: EventScheduleFieldsFragment | EventScheduleNode,
    current_date_offset = 0
): boolean {
    const currDate = currentDate();
    currDate.setTime(currDate.getTime() + current_date_offset * 1000);
    return isAfter(currDate, new Date(es.start_time));
}

export function didEventScheduleFinish(
    es: EventScheduleFieldsFragment | EventScheduleNode,
    current_date_offset = 0
): boolean {
    const currDate = currentDate();
    currDate.setTime(currDate.getTime() + current_date_offset * 1000);
    return isAfter(currDate, new Date(es.end_time));
}

export function compareEventSchedulesStartTime(
    es1: EventScheduleFieldsFragment | EventScheduleNode,
    es2: EventScheduleFieldsFragment | EventScheduleNode
): number {
    return compareAsc(new Date(es1.start_time), new Date(es2.start_time));
}

export function isEventScheduleLiveByTime(
    es: EventScheduleFieldsFragment | EventScheduleNode
): boolean {
    let inInterval = false;

    try {
        inInterval = isWithinInterval(currentDate(), {
            start: new Date(es.start_time),
            end: new Date(es.end_time)
        });
    } catch (e) {
        return false;
    }
    return inInterval;
}

export function areEventSchedulesOverlapping(
    es1: EventScheduleFieldsFragment | EventScheduleNode,
    es2: EventScheduleFieldsFragment | EventScheduleNode
): boolean {
    return areIntervalsOverlapping(
        { start: new Date(es1.start_time), end: new Date(es1.end_time) },
        { start: new Date(es2.start_time), end: new Date(es2.end_time) }
    );
}

export function getOverlappingSchedules(
    schedules: EventScheduleFieldsFragment[],
    schedule: EventScheduleFieldsFragment
): EventScheduleFieldsFragment[] {
    const overlapping: EventScheduleFieldsFragment[] = [];

    schedules.map((es) => {
        if (es.id !== schedule.id && areEventSchedulesOverlapping(es, schedule)) {
            overlapping.push(es);
        }
    });

    return overlapping;
}

export function getOndemandSources(
    schedules: EventScheduleFieldsFragment[],
    context: EventAccessContext
) {
    return schedules.filter(
        (es) =>
            es.ondemand_source &&
            es.ondemand_source.src &&
            (!es.ondemand_source.config ||
                typeof es.ondemand_source.config.allowed_contexts === 'undefined' ||
                es.ondemand_source.config.allowed_contexts.includes(context))
    );
}

export function getParentOndemandSource(
    schedules: EventScheduleFieldsFragment[],
    schedule: EventScheduleFieldsFragment
) {
    const overlappingSchedulesWithOndemandSources = getOverlappingSchedules(
        schedules,
        schedule
    ).filter((es) => {
        return (
            !!es.ondemand_source &&
            [TypeEnum.Day, TypeEnum.Block, TypeEnum.TopicBlock, TypeEnum.Stream].includes(
                es.type_id as TypeEnum
            )
        );
    });

    overlappingSchedulesWithOndemandSources.sort((a, b) => {
        const order = [TypeEnum.Stream, TypeEnum.TopicBlock, TypeEnum.Block, TypeEnum.Day];

        const posA = order.indexOf(a.type_id as TypeEnum);
        const posB = order.indexOf(b.type_id as TypeEnum);

        if (posA === posB) {
            return 0;
        }

        if (posA === -1) {
            return 1;
        }

        if (posB === -1) {
            return -1;
        }

        return posA - posB;
    });

    return overlappingSchedulesWithOndemandSources?.[0].ondemand_source;
}

export function isBreakProgram(es1: EventScheduleFieldsFragment | EventScheduleNode): boolean {
    const title = es1.title.toLowerCase();
    return es1.type_id === TypeEnum.Org && title.indexOf('pause') >= 0;
}

/**
 * if @param program itself is of type stream the
 * function will just @return program
 *
 * @param schedules
 * @param program
 */
export function findStreamOfProgram(
    schedules: Pick<EventFieldsFragment, 'event_schedules'>['event_schedules'],
    program: EventScheduleFieldsFragment | null | undefined
): EventScheduleFieldsFragment | undefined {
    if (!program) {
        return undefined;
    }
    if (program.type_id === TypeEnum.Stream) {
        return program;
    }
    const pIndex = schedules.findIndex((p) => p.id === program.id);
    for (let i = pIndex - 1; i >= 0; i--) {
        const possibleStream = schedules[i];
        if (
            possibleStream.type_id === TypeEnum.Stream &&
            areEventSchedulesOverlapping(possibleStream, program)
        ) {
            return possibleStream;
        }
    }
    return undefined;
}

export function fromToScheduleString(
    schedule: EventScheduleNode | EventScheduleFieldsFragment
): string {
    const [startDate, endDate] = [new Date(schedule.start_time), new Date(schedule.end_time)];
    if (differenceInMinutes(startDate, endDate) !== 0) {
        return `${formatDate(startDate, 'kk:mm')} - ${formatDate(endDate, 'kk:mm')}`;
    }
    return `${formatDate(startDate, 'kk:mm')}`;
}

export function hasScheduleOnDemandSources(
    schedules: Pick<EventFieldsFragment, 'event_schedules'>['event_schedules']
): boolean {
    return schedules.some((s) => s.ondemand_source && s.ondemand_source.src);
}

export function generateTreeSchedule(
    schedules: Pick<EventFieldsFragment, 'event_schedules'>['event_schedules'],
    allowedParents: {
        [key: string]: TypeEnum[];
    } = {
        block: [TypeEnum.Day],
        topic_block: [TypeEnum.Day, TypeEnum.Block, TypeEnum.Stream],
        stream: [TypeEnum.Day, TypeEnum.Block, TypeEnum.TopicBlock],
        lecture: [TypeEnum.Day, TypeEnum.Block, TypeEnum.TopicBlock, TypeEnum.Stream],
        symposium: [TypeEnum.Day, TypeEnum.TopicBlock, TypeEnum.Stream],
        org: [TypeEnum.Day, TypeEnum.Block, TypeEnum.TopicBlock, TypeEnum.Stream],
        start_or_end: [TypeEnum.Day, TypeEnum.Block, TypeEnum.TopicBlock]
    }
): EventScheduleNode[] {
    const grouped: EventScheduleNode[] = [];
    let possibleParents: EventScheduleNode[] = [];
    schedules.forEach((s) => {
        const el: EventScheduleNode = { ...s, children: [] };
        const elStartTime = new Date(el.start_time);
        const elEndTime = new Date(el.end_time);
        if (el.type_id === 'day') {
            possibleParents = [];
            grouped.push(el);
            possibleParents.push(el);
        } else {
            for (let i = possibleParents.length - 1; i >= 0; i--) {
                const parent = possibleParents[i];
                const parentStartTime = new Date(parent.start_time);
                const parentEndTime = new Date(parent.end_time);
                let allowedAsParent = true;
                if (el.type_id && allowedParents[el.type_id] && parent.type_id) {
                    allowedAsParent = allowedParents[el.type_id].includes(parent.type_id);
                }

                el.parent = allowedAsParent ? parent : undefined;

                if (
                    parent.children &&
                    allowedAsParent &&
                    areIntervalsOverlapping(
                        { start: parentStartTime, end: parentEndTime },
                        { start: elStartTime, end: elEndTime }
                    )
                ) {
                    const beforeIndex = parent.children.findIndex((p) =>
                        isBefore(sub(elStartTime, { seconds: 30 }), new Date(p.start_time))
                    );
                    if (beforeIndex > -1) {
                        parent.children.splice(beforeIndex, 0, el);
                    } else {
                        parent.children.push(el);
                    }

                    break;
                }
            }
            possibleParents.push(el);
        }
    });
    return grouped;
}

export function flattenSchedulesByTypes(
    schedules: Pick<EventFieldsFragment, 'event_schedules'>['event_schedules'],
    types: TypeEnum[]
): EventScheduleFieldsFragment[] {
    const flattenSchedules: EventScheduleFieldsFragment[] = [];

    schedules.forEach((schedule) => {
        const type = schedule.type_id as TypeEnum;
        if (types.includes(type)) {
            flattenSchedules.push(schedule);
        }
    });

    return flattenSchedules;
}

export function generateAllowedSchedules(
    scheduleNodes: EventScheduleNode[],
    allowedScheduleIds: string[],
    provideAccess = false
) {
    if (provideAccess) {
        scheduleNodes.map((scheduleNode) => {
            allowedScheduleIds.push(scheduleNode.id);
        });
    }

    scheduleNodes.map((scheduleNode) => {
        if (scheduleNode.children.length) {
            if (
                provideAccess ||
                allowedScheduleIds.some((schedule_id) => schedule_id === scheduleNode.id)
            ) {
                generateAllowedSchedules(scheduleNode.children, allowedScheduleIds, true);
            } else {
                generateAllowedSchedules(scheduleNode.children, allowedScheduleIds);
            }
        }
    });
}
