// Config file
// eslint-disable-next-line simple-import-sort/sort
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { Event_Schedules, EventScheduleFieldsFragment } from '@graphql/hasura';

import { useRouter } from 'next/router';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    useCollection,
    useCollectionData,
    useDocumentData,
    useDocumentDataOnce
} from 'react-firebase-hooks/firestore';
import {
    EventAccessTypeEnum,
    useMicrositeConfig
} from '@components/Site/provider/MicrositeConfigProvider';
import { EventType } from '@components/Event';
import {
    generateAllowedSchedules,
    generateTreeSchedule
} from '@lib/data-transform/event-schedules';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;

/**
 * Initialize default firebase app or return if already initialized
 * @param config
 */

export function getFirebaseApp(config: Object): firebase.app.App {
    return !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
}

export type DocResultType<T> = [T | undefined, boolean, Error | undefined];
export type CollectionResultType<T> = [
    T[] | undefined,
    boolean,
    Error | undefined,
    firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | null
];

export interface IUserHasuraClaims {
    'x-hasura-default-role': string;
    'x-hasura-allowed-roles': string[];
    'x-hasura-user-id'?: string;
    'x-hasura-email'?: string;
    'microsite-id'?: string;
}

type FirebaseAppContextType = {
    app: firebase.app.App;
    user: firebase.User | null | undefined;
    userIsLoading: boolean;
    userIdToken: string;
    userClaims: firebase.auth.IdTokenResult['claims'] | null;
    userHasuraClaims: IUserHasuraClaims | null;
    userData: Data<IUserDocument, '', ''> | undefined;
    userDataDocRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null;
    userDataIsLoading: boolean;
};

const FirebaseAppContext = React.createContext<null | FirebaseAppContextType>(null);
FirebaseAppContext.displayName = 'FirebaseAppContext';

const onIdTokenChange = (
    setIdTokenResult: React.Dispatch<React.SetStateAction<firebase.auth.IdTokenResult | null>>
) => {
    return firebase.auth().onIdTokenChanged((user) => {
        if (user) {
            // User is signed in or token was refreshed.
            user.getIdTokenResult()
                .then((idTokenResult) => {
                    setIdTokenResult(idTokenResult);
                })
                .catch((err) => {
                    console.error(err);
                    setIdTokenResult(null);
                });
        } else {
            setIdTokenResult(null);
        }
    });
};

const FirebaseAppProvider: React.FunctionComponent<{ firebaseConfig: Object }> = ({
    firebaseConfig,
    ...props
}) => {
    const router = useRouter();
    const app = getFirebaseApp(firebaseConfig);
    const [user, userIsLoading] = useAuthState(app.auth());
    const userDataDocRef = user ? app.firestore().doc(`/users/${user.uid}`) : null;
    const [userData, userDataIsLoading] = useDocumentData<IUserDocument>(userDataDocRef, {
        idField: 'id'
    });
    const [idTokenResult, setIdTokenResult] = useState<firebase.auth.IdTokenResult | null>(null);
    const isTokenSignInProcessRef = useRef(false);
    const userClaims = idTokenResult ? idTokenResult.claims : null;
    const userHasuraClaims = userClaims ? userClaims['https://hasura.io/jwt/claims'] || null : null;
    const userIdToken = idTokenResult ? idTokenResult.token : '';

    useEffect(() => {
        if (user) {
            const unsubscribeOnIdTokenChange = onIdTokenChange(setIdTokenResult);

            return () => {
                unsubscribeOnIdTokenChange();
            };
        }
    }, [user]);

    useEffect(() => {
        const signInToken = router.query.signInToken as string;

        if (signInToken && !isTokenSignInProcessRef.current) {
            isTokenSignInProcessRef.current = true;
            app.auth()
                .signInWithCustomToken(signInToken)
                .finally(() => {
                    const pathWithoutSignInToken = document.location.search
                        .replace(/[?&]signInToken=[^&]+/, '')
                        .replace(/^&/, '?');
                    router.replace(`${document.location.pathname}${pathWithoutSignInToken}`);
                });
        } else if (!signInToken && isTokenSignInProcessRef.current) {
            isTokenSignInProcessRef.current = false;
        }
    }, [router]);

    useEffect(() => {
        if (userClaims && userClaims.ext_overrides) {
            Object.keys(userClaims.ext_overrides).forEach((key) => {
                localStorage.setItem(key, userClaims.ext_overrides[key]);
            });
        }
    }, [userClaims]);

    // Log out users that don't have Hasura claims.
    useEffect(() => {
        if (userClaims && !userClaims['https://hasura.io/jwt/claims']) {
            app.auth().signOut();
        }
    }, [userClaims]);

    return (
        <FirebaseAppContext.Provider
            value={{
                app,
                user,
                userIsLoading,
                userClaims,
                userHasuraClaims,
                userDataDocRef,
                userIdToken,
                userData,
                userDataIsLoading
            }}
            {...props}
        />
    );
};

function useFirebaseApp(): FirebaseAppContextType {
    const context = React.useContext(FirebaseAppContext);
    if (!context) {
        throw new Error(`useFirebaseApp must be used within a FirebaseProvider`);
    }
    return context;
}

type UserAccountType =
    | 'participant'
    | 'referent'
    | 'journalist'
    | 'pharma employee'
    | 'cooperation'
    | 'fomf account'
    | 'other'
    | 'access_link';

export interface IUserDocument {
    id: string;
    email?: string;
    account_type?: UserAccountType;
    firstname?: string;
    lastname?: string;
    title?: string;
    speciality?: string;
    role?: string;
    ub_id?: string;
    efn_number?: string;
    accepted_privacy_policy?: boolean;
    country?: string;
    phone_mobile?: string;
    phone?: string;
    avatar?: {
        src: string;
        title: string;
    };
}

export type EventAccessContext = 'livestream' | 'ondemand' | 'ovp' | 'exhibition';

interface UbScheduleAccess {
    contexts: EventAccessContext[];
}

export interface IEventAccessDocument {
    full_access?: boolean;
    access_type?: string;
    allowed_contexts?: EventAccessContext[];
    schedules?: string[];
    schedules_ub?: number[];
    schedules_ub_extended?: { [key: string]: UbScheduleAccess };
}

export interface IEventAccessController {
    isLoading: () => boolean;
    isAccessLinkUser: () => boolean;
    isPharmaEmployee: () => boolean;
    isAllowedInContext: (context: EventAccessContext) => boolean;
    getAllowedContexts: () => EventAccessContext[];
    canViewLivestream: () => boolean;
    canViewOnDemand: () => boolean;
    canViewOnDemandVideoPage: () => boolean;
    canViewSchedule: (
        schedule: EventScheduleFieldsFragment,
        context: EventAccessContext | EventAccessContext[]
    ) => boolean;
    canSubmitEvaluations: () => boolean;
    canViewMyEventPresence: () => boolean;
    canSubmitPresenceConfirmations: () => boolean;
    canViewExhibition: () => boolean;
    isAdmin: () => boolean;
    isEventManager: () => boolean;
    isDirector: () => boolean;
}

const useEventAccess = (event: EventType | undefined): IEventAccessController => {
    const { app, user, userIsLoading, userData, userClaims } = useFirebaseApp();
    const router = useRouter();
    const { eventAccessType } = useMicrositeConfig();
    const [accessData, accessDataIsLoading] = useDocumentData<IEventAccessDocument>(
        user && event?.id
            ? app.firestore().doc(`/users/${user.uid}/events_access/${event.id}`)
            : null
    );
    const allowedScheduleIds = useRef<string[]>([]);

    if (event && user && !allowedScheduleIds.current.length && accessData?.schedules?.length) {
        const schedulesTree = generateTreeSchedule(event.event_schedules);

        accessData.schedules.map((s) => {
            allowedScheduleIds.current.push(s);
        });

        generateAllowedSchedules(schedulesTree, allowedScheduleIds.current);
    }

    function userIsAuthenticated() {
        return !!user;
    }

    function isAccessLinkUser() {
        return userData?.account_type === 'access_link';
    }

    function isCooperationUser() {
        return userData?.account_type === 'cooperation';
    }

    function isJournalist() {
        return userData?.account_type === 'journalist';
    }

    function isPharmaEmployee() {
        return userData?.account_type === 'pharma employee';
    }

    function hasFullAccess() {
        return typeof accessData !== 'undefined' && accessData.full_access === true;
    }

    function isAdmin() {
        return userClaims?.role === 'admin' || userClaims?.isAdmin === true;
    }

    function isEventManager() {
        return userClaims?.role === 'event_manager';
    }

    function isDirector() {
        return userClaims?.role === 'director';
    }

    function getAllowedContexts(): EventAccessContext[] {
        if (typeof accessData === 'undefined') {
            return [];
        }
        // Allow all contexts, if no list is provided.
        else if (typeof accessData.allowed_contexts !== 'object') {
            return ['livestream', 'ondemand', 'exhibition'];
        }

        return accessData.allowed_contexts;
    }

    function hasDrupalSchedules() {
        return (
            typeof accessData !== 'undefined' &&
            ((typeof accessData.schedules_ub !== 'undefined' &&
                accessData.schedules_ub.length > 0) ||
                (typeof accessData?.schedules_ub_extended === 'object' &&
                    Object.keys(accessData.schedules_ub_extended).length > 0))
        );
    }

    function hasSchedules() {
        return (
            typeof accessData !== 'undefined' &&
            typeof accessData.schedules !== 'undefined' &&
            accessData.schedules.length > 0
        );
    }

    function hasAccessToDrupalSchedule(schedule: EventScheduleFieldsFragment) {
        if (accessData?.schedules_ub_extended) {
            return schedule.ub_id
                ? Boolean(accessData.schedules_ub_extended[schedule.ub_id])
                : false;
        }

        return (
            typeof schedule.ub_id === 'number' &&
            typeof accessData !== 'undefined' &&
            typeof accessData.schedules_ub !== 'undefined' &&
            accessData.schedules_ub.length > 0 &&
            accessData.schedules_ub.some((s) => s == schedule.ub_id)
        );
    }

    function hasAccessToSchedule(schedule: EventScheduleFieldsFragment) {
        return allowedScheduleIds.current.some((s) => s === schedule.id);
    }

    function isAllowedInContext(context: EventAccessContext) {
        if (typeof accessData === 'undefined') {
            return false;
        }

        if (eventAccessType === EventAccessTypeEnum.DrupalAllowedFunctions) {
            // Match the context from `accessData.schedules_ub_extended`
            if (typeof accessData.schedules_ub_extended === 'object') {
                return Object.values(accessData.schedules_ub_extended).some((schedule_info) =>
                    schedule_info.contexts.includes(context)
                );
            }
        }

        return getAllowedContexts().includes(context);
    }

    function hasOndemandSourceExplicitContext(schedule: EventScheduleFieldsFragment) {
        return schedule.ondemand_source?.config && schedule.ondemand_source.config.allowed_contexts;
    }

    function contextAllowedInOndemandSource(
        schedule: EventScheduleFieldsFragment,
        context: EventAccessContext
    ) {
        return (
            !hasOndemandSourceExplicitContext(schedule) ||
            (hasOndemandSourceExplicitContext(schedule) &&
                schedule.ondemand_source?.config.allowed_contexts.includes(context))
        );
    }

    function scheduleIsAllowedInContext(
        schedule: EventScheduleFieldsFragment,
        context: EventAccessContext | EventAccessContext[]
    ): boolean {
        if (typeof accessData === 'undefined') {
            return false;
        }

        // If we deal with an array of contexts, then it suffices at least one of them to be true.
        if (typeof context === 'object') {
            return context.some((c) => scheduleIsAllowedInContext(schedule, c));
        }

        const hasOndemandSourceDependency = ['ondemand', 'ovp', 'exhibition'].includes(context);

        if (eventAccessType === EventAccessTypeEnum.DrupalAllowedFunctions) {
            // Match the context from `accessData.schedules_ub_extended`
            if (typeof accessData.schedules_ub_extended === 'object') {
                const schedule_info =
                    schedule.ub_id && accessData.schedules_ub_extended[schedule.ub_id]
                        ? accessData.schedules_ub_extended[schedule.ub_id]
                        : null;

                if (schedule_info) {
                    if (hasOndemandSourceDependency) {
                        return (
                            contextAllowedInOndemandSource(schedule, context) &&
                            schedule_info.contexts.includes(context)
                        );
                    }
                    return schedule_info.contexts.includes(context);
                }

                return false;
            }
        }

        if (hasOndemandSourceDependency) {
            return contextAllowedInOndemandSource(schedule, context);
        }

        return getAllowedContexts().includes(context);
    }

    const eventAccess: IEventAccessController = {
        isLoading: () => {
            return (
                userIsLoading ||
                accessDataIsLoading ||
                typeof router.query.signInToken !== 'undefined'
            );
        },
        isAccessLinkUser: () => isAccessLinkUser(),
        isPharmaEmployee: () => isPharmaEmployee(),
        isAllowedInContext: (context: EventAccessContext) => isAllowedInContext(context),
        getAllowedContexts: () => getAllowedContexts(),
        canViewLivestream: () => {
            switch (eventAccessType) {
                case EventAccessTypeEnum.Authenticated: {
                    return userIsAuthenticated();
                }
                case EventAccessTypeEnum.DrupalAllowedFunctions: {
                    return (
                        userIsAuthenticated() &&
                        isAllowedInContext('livestream') &&
                        (hasFullAccess() || hasSchedules() || hasDrupalSchedules())
                    );
                }
                case EventAccessTypeEnum.AllowedSchedules: {
                    return (
                        userIsAuthenticated() &&
                        isAllowedInContext('livestream') &&
                        (hasFullAccess() || hasSchedules())
                    );
                }
            }
            return false;
        },
        canViewOnDemand: () => {
            switch (eventAccessType) {
                case EventAccessTypeEnum.Authenticated: {
                    return userIsAuthenticated();
                }
                case EventAccessTypeEnum.DrupalAllowedFunctions: {
                    return (
                        userIsAuthenticated() &&
                        isAllowedInContext('ondemand') &&
                        (hasFullAccess() || hasSchedules() || hasDrupalSchedules())
                    );
                }
                case EventAccessTypeEnum.AllowedSchedules: {
                    return (
                        userIsAuthenticated() &&
                        isAllowedInContext('ondemand') &&
                        (hasFullAccess() || hasSchedules())
                    );
                }
            }
            return false;
        },
        canViewOnDemandVideoPage: () => {
            return (
                userIsAuthenticated() &&
                userData?.account_type === 'access_link' &&
                typeof accessData?.allowed_contexts === 'object' &&
                accessData.allowed_contexts.includes('ovp')
            );
        },
        canViewSchedule: (schedule, context) => {
            switch (eventAccessType) {
                case EventAccessTypeEnum.Authenticated: {
                    if (userData?.account_type === 'access_link') {
                        return hasAccessToSchedule(schedule);
                    }
                    return userIsAuthenticated();
                }
                case EventAccessTypeEnum.DrupalAllowedFunctions: {
                    return (
                        userIsAuthenticated() &&
                        (hasFullAccess() ||
                            hasAccessToSchedule(schedule) ||
                            hasAccessToDrupalSchedule(schedule)) &&
                        scheduleIsAllowedInContext(schedule, context)
                    );
                }
                case EventAccessTypeEnum.AllowedSchedules: {
                    return (
                        userIsAuthenticated() &&
                        (hasFullAccess() || hasAccessToSchedule(schedule)) &&
                        scheduleIsAllowedInContext(schedule, context)
                    );
                }
            }
            return false;
        },
        canSubmitEvaluations: () => {
            return !isAccessLinkUser();
        },
        canViewMyEventPresence: () => {
            return !isAccessLinkUser();
        },
        canSubmitPresenceConfirmations: () => {
            return !isAccessLinkUser();
        },
        canViewExhibition: () => {
            if (userClaims && userClaims.role === 'admin') {
                return true;
            }
            return (
                userIsAuthenticated() &&
                !isAccessLinkUser() &&
                !isCooperationUser() &&
                !isJournalist()
            );
        },
        isAdmin,
        isEventManager,
        isDirector
    };

    return eventAccess;
};

export interface IStreamPresenceDocumentData {
    user_id: string;
    event_id: string;
    event_schedule_id: string;
    clicks?: IStreamPresenceClickMap;
    clicks_todo: number;
    created_at: firebase.firestore.FieldValue;
}

export interface IStreamPresenceClickMap {
    [key: string]: firebase.firestore.FieldValue;
}

export type StreamPresenceCollectionReturnValue = [
    firebase.firestore.QuerySnapshot<IStreamPresenceDocumentData> | undefined,
    boolean,
    Error | undefined,
    firebase.firestore.CollectionReference<IStreamPresenceDocumentData> | undefined
];

const useStreamPresenceCollection = (eventId: string): StreamPresenceCollectionReturnValue => {
    const { app, user } = useFirebaseApp();

    const callBackFunc = useCallback(
        (eventId, user): StreamPresenceCollectionReturnValue => {
            const collectionRef = user
                ? app.firestore().collection(`/events/${eventId}/stream_presence`)
                : null;
            const collectionQuery = user ? collectionRef?.where('user_id', '==', user.uid) : null;

            const [snapshot, loading, error] = useCollection(collectionQuery);

            return [snapshot, loading, error, collectionRef] as StreamPresenceCollectionReturnValue;
        },
        [eventId, user]
    );

    return callBackFunc(eventId, user);
};

export type LivestreamQuestionType = {
    id: string;
    message: string;
    user_id: string;
    created_at: firebase.firestore.Timestamp;
    event_id: string;
    event_schedule_id: string;
    category?: 'new' | 'done' | 'rejected' | 'later' | 'support';
    support_status?: 'email_sent';
    is_stared?: boolean;
    user_agent: string;
    device_vendor: string;
};

const useLivestreamQuestionsCollection = (eventId: string) => {
    const { app } = useFirebaseApp();
    const collectionRef = app
        .firestore()
        .collection(`events/${eventId}/questions`)
        .orderBy('created_at', 'asc');

    return useCollectionData<LivestreamQuestionType>(collectionRef, { idField: 'id' });
};

export interface IUsersPreRegistrationDocument {
    email?: string;
    title?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    company?: string;
    role?: string;
    speciality?: string;
    registration_email_submitted?: boolean;
    registration_completed?: boolean;
}

export const useUsersPreRegistrationDocumentData = (eventId: string, documentId: string) => {
    const { app } = useFirebaseApp();
    const documentRef =
        eventId && documentId
            ? app.firestore().doc(`events/${eventId}/users_pre_registration/${documentId}`)
            : null;

    return useDocumentData<IUsersPreRegistrationDocument>(documentRef);
};

export type IEventEvaluationEditableValues = {
    rating_content?: number;
    rating_didactics?: number;
    rating_practical_relevance?: number;
    message?: string;
};

export interface IEventEvaluationDocumentData extends IEventEvaluationEditableValues {
    user_id: string;
    event_id: string;
    event_schedule_id: string;
    created_at: firebase.firestore.FieldValue;
    updated_at: firebase.firestore.FieldValue;
}

export type EventEvaluationCollectionReturnValue = [
    firebase.firestore.QuerySnapshot<IEventEvaluationDocumentData> | undefined,
    boolean,
    Error | undefined,
    firebase.firestore.CollectionReference<IEventEvaluationDocumentData> | undefined
];

const useEventEvaluationCollection = (eventId: string): EventEvaluationCollectionReturnValue => {
    const { app, user } = useFirebaseApp();

    const callBackFunc = useCallback(
        (eventId, user): EventEvaluationCollectionReturnValue => {
            const collectionRef = useRef(
                user ? app.firestore().collection(`/events/${eventId}/evaluations`) : null
            );
            const collectionQuery = useRef(
                user ? collectionRef.current?.where('user_id', '==', user.uid) : null
            );

            const [snapshot, loading, error] = useCollection(collectionQuery.current);

            return [
                snapshot,
                loading,
                error,
                collectionRef.current
            ] as EventEvaluationCollectionReturnValue;
        },
        [eventId, user]
    );

    return callBackFunc(eventId, user);
};

const useLivestreamSource = (eventId: string) => {
    const { app, user } = useFirebaseApp();

    return useDocumentData<{ url: string; provider: string }>(
        user ? app.firestore().doc(`/events/${eventId}/livestream/source`) : null
    );
};

const useLivestreamProgram = (eventId: string): DocResultType<EventScheduleFieldsFragment> => {
    const { app, user } = useFirebaseApp();
    return useDocumentData<Event_Schedules>(
        user ? app.firestore().doc(`/events/${eventId}/livestream/program`) : null
    );
};

export interface IEventAccessLink {
    description: string;
    access_type: 'default' | 'livestream' | 'ondemand';
    event_id: string;
    access: { event_schedule_id: string }[];
}

const useEventAccessLink = (
    eventId: string,
    accessLinkId: string
): DocResultType<IEventAccessLink> => {
    const { app } = useFirebaseApp();

    return useDocumentData<IEventAccessLink>(
        eventId && accessLinkId
            ? app.firestore().doc(`/events/${eventId}/access-links/${accessLinkId}`)
            : null
    );
};

export type OnAirType = {
    stream: boolean;
    autoProgram?: boolean;
    varianceMin?: number;
    ted?: {
        ted_ref: string;
        mode: 'ask' | 'results';
    };
    additionalClicks?: { [key: string]: firebase.firestore.FieldValue };
};
const useLivestreamOnAir = (eventId: string): DocResultType<OnAirType> => {
    const { app, user } = useFirebaseApp();

    return useDocumentData<OnAirType>(
        eventId && user ? app.firestore().doc(`/events/${eventId}/livestream/onair`) : null
    );
};

export const useViewerQualification = (eventId: string, viewer_type: 'livestream' | 'ondemand') => {
    const { app } = useFirebaseApp();
    const collectionName = `viewers_${viewer_type}`;

    function getDocument() {
        return app
            .firestore()
            .doc(`/events/${eventId}/${collectionName}/${app.auth().currentUser?.uid}`)
            .get();
    }

    function createDocument() {
        return app
            .firestore()
            .doc(`/events/${eventId}/${collectionName}/${app.auth().currentUser?.uid}`)
            .set({
                user_id: app.auth().currentUser?.uid,
                event_id: eventId,
                created_at: serverTimestamp()
            });
    }

    async function qualifyUserAsViewer() {
        try {
            const doc = await getDocument();

            if (!doc.exists) {
                await createDocument();
            }
        } catch (err) {
            console.error(err);
        }
    }

    return [qualifyUserAsViewer];
};

export type LivestreamMessage = {
    content: string;
    full_cover?: boolean;
};
const useLivestreamMessage = (eventId: string): DocResultType<LivestreamMessage> => {
    const { app, user } = useFirebaseApp();
    return useDocumentData<LivestreamMessage>(
        user ? app.firestore().doc(`events/${eventId}/livestream/message`) : null
    );
};

/**
 * TEDs for the Live Event Schedule
 * **/

export interface IEventTedAnswer {
    title: string;
    isCorrect: boolean;
}
export interface IEventTedQuestion {
    title: string;
    points: number;
    multiplechoice: boolean;
    answers: IEventTedAnswer[];
}
export interface IEventTedDocument {
    id: string;
    access_type: 'live' | 'ondemand' | 'live_ondemand';
    category: 'fomf-event' | 'webup' | 'flm-event';
    created_at: firebase.firestore.Timestamp;
    created_by: firebase.User;
    event_schedule_id: number;
    name: string;
    type: 'quiz' | 'questionaire' | 'knowledgecheck';
    success_percentage: number;
    updated_at: firebase.firestore.Timestamp;
    updated_by: firebase.User;
    questions: IEventTedQuestion[];
}

export type TedAnswerValueType = { [key: string]: string | string[] };
export interface IEventTedAnswers {
    ted: firebase.firestore.DocumentReference;
    user_id: string;
    updated_at: firebase.firestore.Timestamp;
    values: TedAnswerValueType;
    submits: {
        values: TedAnswerValueType[];
        created_at: firebase.firestore.Timestamp;
    };
}

type TedLiveModes = 'questions' | 'results';
export type TedLiveType = {
    ted_id: string;
    mode: TedLiveModes;
    started_at: firebase.firestore.Timestamp;
};

/**
 * The useLiveTed hook listens if a TED should be shown. This is mostly directed by the
 * livestream direction. A LiveTed is completly controlled by the livestream director.
 *
 */
const useLiveTed = (eventId: string) => {
    const { app, user } = useFirebaseApp();

    const liveTedRef = app.firestore().doc(`events/${eventId}/livestream/ted`);

    const [livestreamTed] = useDocumentData<TedLiveType>(liveTedRef);

    const tedRef =
        livestreamTed && livestreamTed.ted_id
            ? app.firestore().doc(`events/${eventId}/teds/${livestreamTed.ted_id}`)
            : undefined;

    const tedAnswersRef = tedRef && user ? tedRef.collection('anwsers').doc(user.uid) : undefined;

    const [currentUserAnswers, loadingAnswers, errorAnswers] = useDocumentDataOnce<
        IEventTedAnswers
    >(tedAnswersRef);

    const [liveTed, loadingLiveTed, errorLiveTed] = useDocumentDataOnce<IEventTedDocument>(tedRef, {
        idField: 'id'
    });

    const submitUserAnswer = async (values: { [key: string]: string | string[] }) => {
        if (tedAnswersRef && user) {
            await tedAnswersRef.set(
                {
                    ted: tedRef,
                    user_id: user.uid,
                    values: values,
                    updated_at: serverTimestamp(),
                    submits: firebase.firestore.FieldValue.arrayUnion({
                        values,
                        created_at: new Date()
                    })
                },
                { merge: true }
            );
        } else {
            throw Error('TED is not live anymore');
        }
    };

    return {
        ted: liveTed,
        mode: livestreamTed?.mode,
        currentUserAnswers: currentUserAnswers,
        results: null, //TODO: we need to save the aggregated results in an extra document
        loading: loadingAnswers || loadingLiveTed,
        error: errorLiveTed || errorAnswers,
        submitUserAnswer
    };
};

export default FirebaseAppProvider;
export {
    firebase,
    serverTimestamp,
    useLivestreamMessage,
    useFirebaseApp,
    useEventAccess,
    useLivestreamSource,
    useLivestreamProgram,
    useStreamPresenceCollection,
    useLivestreamQuestionsCollection,
    useLivestreamOnAir,
    useLiveTed,
    useEventAccessLink,
    useEventEvaluationCollection
};
