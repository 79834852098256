import { Scalars } from '@graphql/hasura';
import { format, formatDistanceToNow } from 'date-fns';
import locale_default from 'date-fns/locale/de';
import locale_en_GB from 'date-fns/locale/en-GB';
import locale_fr_CH from 'date-fns/locale/fr-CH';

export const DATE_FNS_LOCALES: any = {
    default: locale_default,
    'fr-CH': locale_fr_CH,
    'en-GB': locale_en_GB
};

//TODO: import this from a localized date formats module
const DATE_SHORT = 'P';
export type DateType = Date | Scalars['timestamptz'];

function toDate(date: DateType): Date {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return date;
}

export function getDateFnsLocale(locale = 'de'): Locale {
    if (typeof DATE_FNS_LOCALES[locale] !== 'undefined') {
        return DATE_FNS_LOCALES[locale];
    }

    return DATE_FNS_LOCALES['default'];
}

export function formatDateDistanceToNow(date: DateType, locale = 'de'): string {
    return formatDistanceToNow(toDate(date), {
        locale: getDateFnsLocale(locale),
        addSuffix: true,
        includeSeconds: false
    });
}

/**
 *
 * @param date
 * @param formatTokens https://bit.ly/3mq6Xc8
 * @param locale
 */
export function formatDate(date: DateType, formatTokens: string, locale = 'de'): string {
    return format(toDate(date), formatTokens, {
        locale: getDateFnsLocale(locale)
    });
}

export function shortDate(date: DateType, locale = 'de'): string {
    return formatDate(toDate(date), DATE_SHORT, locale);
}

export function localizedTime(date: DateType, locale = 'de'): string {
    return formatDate(toDate(date), 'p', locale);
}
