import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  json: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "accreditation_types" */
export type Accreditation_Types = {
  __typename?: 'accreditation_types';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "accreditation_types" */
export type Accreditation_Types_Aggregate = {
  __typename?: 'accreditation_types_aggregate';
  aggregate?: Maybe<Accreditation_Types_Aggregate_Fields>;
  nodes: Array<Accreditation_Types>;
};

/** aggregate fields of "accreditation_types" */
export type Accreditation_Types_Aggregate_Fields = {
  __typename?: 'accreditation_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Accreditation_Types_Max_Fields>;
  min?: Maybe<Accreditation_Types_Min_Fields>;
};


/** aggregate fields of "accreditation_types" */
export type Accreditation_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accreditation_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "accreditation_types" */
export type Accreditation_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Accreditation_Types_Max_Order_By>;
  min?: Maybe<Accreditation_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "accreditation_types" */
export type Accreditation_Types_Arr_Rel_Insert_Input = {
  data: Array<Accreditation_Types_Insert_Input>;
  on_conflict?: Maybe<Accreditation_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "accreditation_types". All fields are combined with a logical 'AND'. */
export type Accreditation_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Accreditation_Types_Bool_Exp>>>;
  _not?: Maybe<Accreditation_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Accreditation_Types_Bool_Exp>>>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "accreditation_types" */
export enum Accreditation_Types_Constraint {
  /** unique or primary key constraint */
  AccreditationTypesPkey = 'accreditation_types_pkey'
}

export enum Accreditation_Types_Enum {
  /** Block */
  Block = 'block',
  /** Default */
  Def = 'def'
}

/** expression to compare columns of type accreditation_types_enum. All fields are combined with logical 'AND'. */
export type Accreditation_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Accreditation_Types_Enum>;
  _in?: Maybe<Array<Accreditation_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Accreditation_Types_Enum>;
  _nin?: Maybe<Array<Accreditation_Types_Enum>>;
};

/** input type for inserting data into table "accreditation_types" */
export type Accreditation_Types_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Accreditation_Types_Max_Fields = {
  __typename?: 'accreditation_types_max_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "accreditation_types" */
export type Accreditation_Types_Max_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Accreditation_Types_Min_Fields = {
  __typename?: 'accreditation_types_min_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "accreditation_types" */
export type Accreditation_Types_Min_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "accreditation_types" */
export type Accreditation_Types_Mutation_Response = {
  __typename?: 'accreditation_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Accreditation_Types>;
};

/** input type for inserting object relation for remote table "accreditation_types" */
export type Accreditation_Types_Obj_Rel_Insert_Input = {
  data: Accreditation_Types_Insert_Input;
  on_conflict?: Maybe<Accreditation_Types_On_Conflict>;
};

/** on conflict condition type for table "accreditation_types" */
export type Accreditation_Types_On_Conflict = {
  constraint: Accreditation_Types_Constraint;
  update_columns: Array<Accreditation_Types_Update_Column>;
  where?: Maybe<Accreditation_Types_Bool_Exp>;
};

/** ordering options when selecting data from "accreditation_types" */
export type Accreditation_Types_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "accreditation_types" */
export type Accreditation_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "accreditation_types" */
export enum Accreditation_Types_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "accreditation_types" */
export type Accreditation_Types_Set_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "accreditation_types" */
export enum Accreditation_Types_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "companies" */
export type Companies = {
  __typename?: 'companies';
  /** An array relationship */
  company_departments: Array<Company_Departments>;
  /** An aggregated array relationship */
  company_departments_aggregate: Company_Departments_Aggregate;
  id: Scalars['uuid'];
  logo_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


/** columns and relationships of "companies" */
export type CompaniesCompany_DepartmentsArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesCompany_Departments_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};

/** aggregated selection of "companies" */
export type Companies_Aggregate = {
  __typename?: 'companies_aggregate';
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

/** aggregate fields of "companies" */
export type Companies_Aggregate_Fields = {
  __typename?: 'companies_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
};


/** aggregate fields of "companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Companies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companies" */
export type Companies_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Companies_Max_Order_By>;
  min?: Maybe<Companies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "companies" */
export type Companies_Arr_Rel_Insert_Input = {
  data: Array<Companies_Insert_Input>;
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Companies_Bool_Exp>>>;
  _not?: Maybe<Companies_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Companies_Bool_Exp>>>;
  company_departments?: Maybe<Company_Departments_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo_url?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint */
  CompaniesPkey = 'companies_pkey'
}

/** input type for inserting data into table "companies" */
export type Companies_Insert_Input = {
  company_departments?: Maybe<Company_Departments_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  __typename?: 'companies_max_fields';
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "companies" */
export type Companies_Max_Order_By = {
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  __typename?: 'companies_min_fields';
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "companies" */
export type Companies_Min_Order_By = {
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "companies" */
export type Companies_Mutation_Response = {
  __typename?: 'companies_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** on conflict condition type for table "companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns: Array<Companies_Update_Column>;
  where?: Maybe<Companies_Bool_Exp>;
};

/** ordering options when selecting data from "companies" */
export type Companies_Order_By = {
  company_departments_aggregate?: Maybe<Company_Departments_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "companies" */
export type Companies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "companies" */
export enum Companies_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "companies" */
export type Companies_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "companies" */
export enum Companies_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "company_departments" */
export type Company_Departments = {
  __typename?: 'company_departments';
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  users: Array<Company_Departments_Users>;
  /** An aggregated array relationship */
  users_aggregate: Company_Departments_Users_Aggregate;
};


/** columns and relationships of "company_departments" */
export type Company_DepartmentsUsersArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** columns and relationships of "company_departments" */
export type Company_DepartmentsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};

/** aggregated selection of "company_departments" */
export type Company_Departments_Aggregate = {
  __typename?: 'company_departments_aggregate';
  aggregate?: Maybe<Company_Departments_Aggregate_Fields>;
  nodes: Array<Company_Departments>;
};

/** aggregate fields of "company_departments" */
export type Company_Departments_Aggregate_Fields = {
  __typename?: 'company_departments_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Departments_Max_Fields>;
  min?: Maybe<Company_Departments_Min_Fields>;
};


/** aggregate fields of "company_departments" */
export type Company_Departments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Departments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_departments" */
export type Company_Departments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Company_Departments_Max_Order_By>;
  min?: Maybe<Company_Departments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_departments" */
export type Company_Departments_Arr_Rel_Insert_Input = {
  data: Array<Company_Departments_Insert_Input>;
  on_conflict?: Maybe<Company_Departments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_departments". All fields are combined with a logical 'AND'. */
export type Company_Departments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Company_Departments_Bool_Exp>>>;
  _not?: Maybe<Company_Departments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Company_Departments_Bool_Exp>>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  users?: Maybe<Company_Departments_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "company_departments" */
export enum Company_Departments_Constraint {
  /** unique or primary key constraint */
  CompanyDepartmentsPkey = 'company_departments_pkey'
}

/** input type for inserting data into table "company_departments" */
export type Company_Departments_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Company_Departments_Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Company_Departments_Max_Fields = {
  __typename?: 'company_departments_max_fields';
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "company_departments" */
export type Company_Departments_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Departments_Min_Fields = {
  __typename?: 'company_departments_min_fields';
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "company_departments" */
export type Company_Departments_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "company_departments" */
export type Company_Departments_Mutation_Response = {
  __typename?: 'company_departments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Company_Departments>;
};

/** input type for inserting object relation for remote table "company_departments" */
export type Company_Departments_Obj_Rel_Insert_Input = {
  data: Company_Departments_Insert_Input;
  on_conflict?: Maybe<Company_Departments_On_Conflict>;
};

/** on conflict condition type for table "company_departments" */
export type Company_Departments_On_Conflict = {
  constraint: Company_Departments_Constraint;
  update_columns: Array<Company_Departments_Update_Column>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};

/** ordering options when selecting data from "company_departments" */
export type Company_Departments_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  users_aggregate?: Maybe<Company_Departments_Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "company_departments" */
export type Company_Departments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "company_departments" */
export enum Company_Departments_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "company_departments" */
export type Company_Departments_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "company_departments" */
export enum Company_Departments_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "company_departments_users" */
export type Company_Departments_Users = {
  __typename?: 'company_departments_users';
  department_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** aggregated selection of "company_departments_users" */
export type Company_Departments_Users_Aggregate = {
  __typename?: 'company_departments_users_aggregate';
  aggregate?: Maybe<Company_Departments_Users_Aggregate_Fields>;
  nodes: Array<Company_Departments_Users>;
};

/** aggregate fields of "company_departments_users" */
export type Company_Departments_Users_Aggregate_Fields = {
  __typename?: 'company_departments_users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Company_Departments_Users_Max_Fields>;
  min?: Maybe<Company_Departments_Users_Min_Fields>;
};


/** aggregate fields of "company_departments_users" */
export type Company_Departments_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "company_departments_users" */
export type Company_Departments_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Company_Departments_Users_Max_Order_By>;
  min?: Maybe<Company_Departments_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "company_departments_users" */
export type Company_Departments_Users_Arr_Rel_Insert_Input = {
  data: Array<Company_Departments_Users_Insert_Input>;
  on_conflict?: Maybe<Company_Departments_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "company_departments_users". All fields are combined with a logical 'AND'. */
export type Company_Departments_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Company_Departments_Users_Bool_Exp>>>;
  _not?: Maybe<Company_Departments_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Company_Departments_Users_Bool_Exp>>>;
  department_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "company_departments_users" */
export enum Company_Departments_Users_Constraint {
  /** unique or primary key constraint */
  CompanyDepartmentsUsersIdKey = 'company_departments_users_id_key',
  /** unique or primary key constraint */
  CompanyDepartmentsUsersPkey = 'company_departments_users_pkey'
}

/** input type for inserting data into table "company_departments_users" */
export type Company_Departments_Users_Insert_Input = {
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Company_Departments_Users_Max_Fields = {
  __typename?: 'company_departments_users_max_fields';
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "company_departments_users" */
export type Company_Departments_Users_Max_Order_By = {
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Company_Departments_Users_Min_Fields = {
  __typename?: 'company_departments_users_min_fields';
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "company_departments_users" */
export type Company_Departments_Users_Min_Order_By = {
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "company_departments_users" */
export type Company_Departments_Users_Mutation_Response = {
  __typename?: 'company_departments_users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Company_Departments_Users>;
};

/** input type for inserting object relation for remote table "company_departments_users" */
export type Company_Departments_Users_Obj_Rel_Insert_Input = {
  data: Company_Departments_Users_Insert_Input;
  on_conflict?: Maybe<Company_Departments_Users_On_Conflict>;
};

/** on conflict condition type for table "company_departments_users" */
export type Company_Departments_Users_On_Conflict = {
  constraint: Company_Departments_Users_Constraint;
  update_columns: Array<Company_Departments_Users_Update_Column>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};

/** ordering options when selecting data from "company_departments_users" */
export type Company_Departments_Users_Order_By = {
  department_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "company_departments_users" */
export type Company_Departments_Users_Pk_Columns_Input = {
  department_id: Scalars['uuid'];
  user_id: Scalars['String'];
};

/** select columns of table "company_departments_users" */
export enum Company_Departments_Users_Select_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "company_departments_users" */
export type Company_Departments_Users_Set_Input = {
  department_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "company_departments_users" */
export enum Company_Departments_Users_Update_Column {
  /** column name */
  DepartmentId = 'department_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "content_types" */
export type Content_Types = {
  __typename?: 'content_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "content_types" */
export type Content_Types_Aggregate = {
  __typename?: 'content_types_aggregate';
  aggregate?: Maybe<Content_Types_Aggregate_Fields>;
  nodes: Array<Content_Types>;
};

/** aggregate fields of "content_types" */
export type Content_Types_Aggregate_Fields = {
  __typename?: 'content_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Content_Types_Max_Fields>;
  min?: Maybe<Content_Types_Min_Fields>;
};


/** aggregate fields of "content_types" */
export type Content_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Content_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "content_types" */
export type Content_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Content_Types_Max_Order_By>;
  min?: Maybe<Content_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "content_types" */
export type Content_Types_Arr_Rel_Insert_Input = {
  data: Array<Content_Types_Insert_Input>;
  on_conflict?: Maybe<Content_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "content_types". All fields are combined with a logical 'AND'. */
export type Content_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Content_Types_Bool_Exp>>>;
  _not?: Maybe<Content_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Content_Types_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "content_types" */
export enum Content_Types_Constraint {
  /** unique or primary key constraint */
  ContentTypesPkey = 'content_types_pkey'
}

export enum Content_Types_Enum {
  /** On-demand Intro */
  OndemandIntro = 'ondemand_intro',
  /** Pre Livestream Intro */
  PreLivestreamIntro = 'pre_livestream_intro'
}

/** expression to compare columns of type content_types_enum. All fields are combined with logical 'AND'. */
export type Content_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Content_Types_Enum>;
  _in?: Maybe<Array<Content_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Content_Types_Enum>;
  _nin?: Maybe<Array<Content_Types_Enum>>;
};

/** input type for inserting data into table "content_types" */
export type Content_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Content_Types_Max_Fields = {
  __typename?: 'content_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "content_types" */
export type Content_Types_Max_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Content_Types_Min_Fields = {
  __typename?: 'content_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "content_types" */
export type Content_Types_Min_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "content_types" */
export type Content_Types_Mutation_Response = {
  __typename?: 'content_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Content_Types>;
};

/** input type for inserting object relation for remote table "content_types" */
export type Content_Types_Obj_Rel_Insert_Input = {
  data: Content_Types_Insert_Input;
  on_conflict?: Maybe<Content_Types_On_Conflict>;
};

/** on conflict condition type for table "content_types" */
export type Content_Types_On_Conflict = {
  constraint: Content_Types_Constraint;
  update_columns: Array<Content_Types_Update_Column>;
  where?: Maybe<Content_Types_Bool_Exp>;
};

/** ordering options when selecting data from "content_types" */
export type Content_Types_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "content_types" */
export type Content_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "content_types" */
export enum Content_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "content_types" */
export type Content_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "content_types" */
export enum Content_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "event_accreditation_points" */
export type Event_Accreditation_Points = {
  __typename?: 'event_accreditation_points';
  accreditation_type?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  event?: Maybe<Events>;
  event_id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_accreditation_points" */
export type Event_Accreditation_Points_Aggregate = {
  __typename?: 'event_accreditation_points_aggregate';
  aggregate?: Maybe<Event_Accreditation_Points_Aggregate_Fields>;
  nodes: Array<Event_Accreditation_Points>;
};

/** aggregate fields of "event_accreditation_points" */
export type Event_Accreditation_Points_Aggregate_Fields = {
  __typename?: 'event_accreditation_points_aggregate_fields';
  avg?: Maybe<Event_Accreditation_Points_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Accreditation_Points_Max_Fields>;
  min?: Maybe<Event_Accreditation_Points_Min_Fields>;
  stddev?: Maybe<Event_Accreditation_Points_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Accreditation_Points_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Accreditation_Points_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Accreditation_Points_Sum_Fields>;
  var_pop?: Maybe<Event_Accreditation_Points_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Accreditation_Points_Var_Samp_Fields>;
  variance?: Maybe<Event_Accreditation_Points_Variance_Fields>;
};


/** aggregate fields of "event_accreditation_points" */
export type Event_Accreditation_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_accreditation_points" */
export type Event_Accreditation_Points_Aggregate_Order_By = {
  avg?: Maybe<Event_Accreditation_Points_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Accreditation_Points_Max_Order_By>;
  min?: Maybe<Event_Accreditation_Points_Min_Order_By>;
  stddev?: Maybe<Event_Accreditation_Points_Stddev_Order_By>;
  stddev_pop?: Maybe<Event_Accreditation_Points_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Event_Accreditation_Points_Stddev_Samp_Order_By>;
  sum?: Maybe<Event_Accreditation_Points_Sum_Order_By>;
  var_pop?: Maybe<Event_Accreditation_Points_Var_Pop_Order_By>;
  var_samp?: Maybe<Event_Accreditation_Points_Var_Samp_Order_By>;
  variance?: Maybe<Event_Accreditation_Points_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Event_Accreditation_Points_Avg_Fields = {
  __typename?: 'event_accreditation_points_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Avg_Order_By = {
  points?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_accreditation_points". All fields are combined with a logical 'AND'. */
export type Event_Accreditation_Points_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Accreditation_Points_Bool_Exp>>>;
  _not?: Maybe<Event_Accreditation_Points_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Accreditation_Points_Bool_Exp>>>;
  accreditation_type?: Maybe<String_Comparison_Exp>;
  certified?: Maybe<Boolean_Comparison_Exp>;
  event?: Maybe<Events_Bool_Exp>;
  event_id?: Maybe<Uuid_Comparison_Exp>;
  points?: Maybe<Bigint_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Event_Accreditation_Points_Max_Fields = {
  __typename?: 'event_accreditation_points_max_fields';
  accreditation_type?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Max_Order_By = {
  accreditation_type?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Accreditation_Points_Min_Fields = {
  __typename?: 'event_accreditation_points_min_fields';
  accreditation_type?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Min_Order_By = {
  accreditation_type?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** ordering options when selecting data from "event_accreditation_points" */
export type Event_Accreditation_Points_Order_By = {
  accreditation_type?: Maybe<Order_By>;
  certified?: Maybe<Order_By>;
  event?: Maybe<Events_Order_By>;
  event_id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** select columns of table "event_accreditation_points" */
export enum Event_Accreditation_Points_Select_Column {
  /** column name */
  AccreditationType = 'accreditation_type',
  /** column name */
  Certified = 'certified',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Points = 'points',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Event_Accreditation_Points_Stddev_Fields = {
  __typename?: 'event_accreditation_points_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Stddev_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Accreditation_Points_Stddev_Pop_Fields = {
  __typename?: 'event_accreditation_points_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Stddev_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Accreditation_Points_Stddev_Samp_Fields = {
  __typename?: 'event_accreditation_points_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Stddev_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Event_Accreditation_Points_Sum_Fields = {
  __typename?: 'event_accreditation_points_sum_fields';
  points?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Sum_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Event_Accreditation_Points_Var_Pop_Fields = {
  __typename?: 'event_accreditation_points_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Var_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Accreditation_Points_Var_Samp_Fields = {
  __typename?: 'event_accreditation_points_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Var_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Accreditation_Points_Variance_Fields = {
  __typename?: 'event_accreditation_points_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_accreditation_points" */
export type Event_Accreditation_Points_Variance_Order_By = {
  points?: Maybe<Order_By>;
};

/** columns and relationships of "event_content" */
export type Event_Content = {
  __typename?: 'event_content';
  content?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  text?: Maybe<Scalars['String']>;
  /** An object relationship */
  type: Content_Types;
  type_value: Content_Types_Enum;
};


/** columns and relationships of "event_content" */
export type Event_ContentContentArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_content" */
export type Event_Content_Aggregate = {
  __typename?: 'event_content_aggregate';
  aggregate?: Maybe<Event_Content_Aggregate_Fields>;
  nodes: Array<Event_Content>;
};

/** aggregate fields of "event_content" */
export type Event_Content_Aggregate_Fields = {
  __typename?: 'event_content_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Content_Max_Fields>;
  min?: Maybe<Event_Content_Min_Fields>;
};


/** aggregate fields of "event_content" */
export type Event_Content_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Content_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_content" */
export type Event_Content_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Content_Max_Order_By>;
  min?: Maybe<Event_Content_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Content_Append_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event_content" */
export type Event_Content_Arr_Rel_Insert_Input = {
  data: Array<Event_Content_Insert_Input>;
  on_conflict?: Maybe<Event_Content_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_content". All fields are combined with a logical 'AND'. */
export type Event_Content_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Content_Bool_Exp>>>;
  _not?: Maybe<Event_Content_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Content_Bool_Exp>>>;
  content?: Maybe<Jsonb_Comparison_Exp>;
  event?: Maybe<Events_Bool_Exp>;
  event_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Content_Types_Bool_Exp>;
  type_value?: Maybe<Content_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_content" */
export enum Event_Content_Constraint {
  /** unique or primary key constraint */
  EventContentPkey = 'event_content_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Content_Delete_At_Path_Input = {
  content?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Content_Delete_Elem_Input = {
  content?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Content_Delete_Key_Input = {
  content?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_content" */
export type Event_Content_Insert_Input = {
  content?: Maybe<Scalars['jsonb']>;
  event?: Maybe<Events_Obj_Rel_Insert_Input>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Content_Types_Obj_Rel_Insert_Input>;
  type_value?: Maybe<Content_Types_Enum>;
};

/** aggregate max on columns */
export type Event_Content_Max_Fields = {
  __typename?: 'event_content_max_fields';
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_content" */
export type Event_Content_Max_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Content_Min_Fields = {
  __typename?: 'event_content_min_fields';
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_content" */
export type Event_Content_Min_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_content" */
export type Event_Content_Mutation_Response = {
  __typename?: 'event_content_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Content>;
};

/** input type for inserting object relation for remote table "event_content" */
export type Event_Content_Obj_Rel_Insert_Input = {
  data: Event_Content_Insert_Input;
  on_conflict?: Maybe<Event_Content_On_Conflict>;
};

/** on conflict condition type for table "event_content" */
export type Event_Content_On_Conflict = {
  constraint: Event_Content_Constraint;
  update_columns: Array<Event_Content_Update_Column>;
  where?: Maybe<Event_Content_Bool_Exp>;
};

/** ordering options when selecting data from "event_content" */
export type Event_Content_Order_By = {
  content?: Maybe<Order_By>;
  event?: Maybe<Events_Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
  type?: Maybe<Content_Types_Order_By>;
  type_value?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_content" */
export type Event_Content_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Content_Prepend_Input = {
  content?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_content" */
export enum Event_Content_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  TypeValue = 'type_value'
}

/** input type for updating data in table "event_content" */
export type Event_Content_Set_Input = {
  content?: Maybe<Scalars['jsonb']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  text?: Maybe<Scalars['String']>;
  type_value?: Maybe<Content_Types_Enum>;
};

/** update columns of table "event_content" */
export enum Event_Content_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  TypeValue = 'type_value'
}

/** columns and relationships of "event_locations" */
export type Event_Locations = {
  __typename?: 'event_locations';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  lat_lng?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_locations" */
export type Event_Locations_Aggregate = {
  __typename?: 'event_locations_aggregate';
  aggregate?: Maybe<Event_Locations_Aggregate_Fields>;
  nodes: Array<Event_Locations>;
};

/** aggregate fields of "event_locations" */
export type Event_Locations_Aggregate_Fields = {
  __typename?: 'event_locations_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Locations_Max_Fields>;
  min?: Maybe<Event_Locations_Min_Fields>;
};


/** aggregate fields of "event_locations" */
export type Event_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_locations" */
export type Event_Locations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Locations_Max_Order_By>;
  min?: Maybe<Event_Locations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_locations" */
export type Event_Locations_Arr_Rel_Insert_Input = {
  data: Array<Event_Locations_Insert_Input>;
  on_conflict?: Maybe<Event_Locations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_locations". All fields are combined with a logical 'AND'. */
export type Event_Locations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Locations_Bool_Exp>>>;
  _not?: Maybe<Event_Locations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Locations_Bool_Exp>>>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lat_lng?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  postal_code?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  street_number?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_locations" */
export enum Event_Locations_Constraint {
  /** unique or primary key constraint */
  EventLocationsPkey = 'event_locations_pkey'
}

/** input type for inserting data into table "event_locations" */
export type Event_Locations_Insert_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lat_lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Locations_Max_Fields = {
  __typename?: 'event_locations_max_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lat_lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_locations" */
export type Event_Locations_Max_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat_lng?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street_number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Locations_Min_Fields = {
  __typename?: 'event_locations_min_fields';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lat_lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_locations" */
export type Event_Locations_Min_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat_lng?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street_number?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_locations" */
export type Event_Locations_Mutation_Response = {
  __typename?: 'event_locations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Locations>;
};

/** input type for inserting object relation for remote table "event_locations" */
export type Event_Locations_Obj_Rel_Insert_Input = {
  data: Event_Locations_Insert_Input;
  on_conflict?: Maybe<Event_Locations_On_Conflict>;
};

/** on conflict condition type for table "event_locations" */
export type Event_Locations_On_Conflict = {
  constraint: Event_Locations_Constraint;
  update_columns: Array<Event_Locations_Update_Column>;
  where?: Maybe<Event_Locations_Bool_Exp>;
};

/** ordering options when selecting data from "event_locations" */
export type Event_Locations_Order_By = {
  city?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lat_lng?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  postal_code?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street_number?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_locations" */
export type Event_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "event_locations" */
export enum Event_Locations_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatLng = 'lat_lng',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number'
}

/** input type for updating data in table "event_locations" */
export type Event_Locations_Set_Input = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lat_lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street_number?: Maybe<Scalars['String']>;
};

/** update columns of table "event_locations" */
export enum Event_Locations_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LatLng = 'lat_lng',
  /** column name */
  Name = 'name',
  /** column name */
  PostalCode = 'postal_code',
  /** column name */
  Street = 'street',
  /** column name */
  StreetNumber = 'street_number'
}

/** columns and relationships of "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points = {
  __typename?: 'event_schedule_accreditation_points';
  accreditation_number?: Maybe<Scalars['String']>;
  certified: Scalars['Boolean'];
  /** An object relationship */
  event_schedule: Event_Schedules;
  event_schedule_id: Scalars['uuid'];
  id: Scalars['uuid'];
  points: Scalars['Int'];
  type: Scalars['String'];
};

/** aggregated selection of "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Aggregate = {
  __typename?: 'event_schedule_accreditation_points_aggregate';
  aggregate?: Maybe<Event_Schedule_Accreditation_Points_Aggregate_Fields>;
  nodes: Array<Event_Schedule_Accreditation_Points>;
};

/** aggregate fields of "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Aggregate_Fields = {
  __typename?: 'event_schedule_accreditation_points_aggregate_fields';
  avg?: Maybe<Event_Schedule_Accreditation_Points_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Schedule_Accreditation_Points_Max_Fields>;
  min?: Maybe<Event_Schedule_Accreditation_Points_Min_Fields>;
  stddev?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Schedule_Accreditation_Points_Sum_Fields>;
  var_pop?: Maybe<Event_Schedule_Accreditation_Points_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Schedule_Accreditation_Points_Var_Samp_Fields>;
  variance?: Maybe<Event_Schedule_Accreditation_Points_Variance_Fields>;
};


/** aggregate fields of "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Aggregate_Order_By = {
  avg?: Maybe<Event_Schedule_Accreditation_Points_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Schedule_Accreditation_Points_Max_Order_By>;
  min?: Maybe<Event_Schedule_Accreditation_Points_Min_Order_By>;
  stddev?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Order_By>;
  stddev_pop?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Event_Schedule_Accreditation_Points_Stddev_Samp_Order_By>;
  sum?: Maybe<Event_Schedule_Accreditation_Points_Sum_Order_By>;
  var_pop?: Maybe<Event_Schedule_Accreditation_Points_Var_Pop_Order_By>;
  var_samp?: Maybe<Event_Schedule_Accreditation_Points_Var_Samp_Order_By>;
  variance?: Maybe<Event_Schedule_Accreditation_Points_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Arr_Rel_Insert_Input = {
  data: Array<Event_Schedule_Accreditation_Points_Insert_Input>;
  on_conflict?: Maybe<Event_Schedule_Accreditation_Points_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Schedule_Accreditation_Points_Avg_Fields = {
  __typename?: 'event_schedule_accreditation_points_avg_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Avg_Order_By = {
  points?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_schedule_accreditation_points". All fields are combined with a logical 'AND'. */
export type Event_Schedule_Accreditation_Points_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>>>;
  _not?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>>>;
  accreditation_number?: Maybe<String_Comparison_Exp>;
  certified?: Maybe<Boolean_Comparison_Exp>;
  event_schedule?: Maybe<Event_Schedules_Bool_Exp>;
  event_schedule_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  points?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_schedule_accreditation_points" */
export enum Event_Schedule_Accreditation_Points_Constraint {
  /** unique or primary key constraint */
  EventScheduleAccreditationPointsEventScheduleIdTypeKey = 'event_schedule_accreditation_points_event_schedule_id_type_key',
  /** unique or primary key constraint */
  EventScheduleAccreditationPointsPkey = 'event_schedule_accreditation_points_pkey'
}

/** input type for incrementing integer column in table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Inc_Input = {
  points?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Insert_Input = {
  accreditation_number?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['Boolean']>;
  event_schedule?: Maybe<Event_Schedules_Obj_Rel_Insert_Input>;
  event_schedule_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Schedule_Accreditation_Points_Max_Fields = {
  __typename?: 'event_schedule_accreditation_points_max_fields';
  accreditation_number?: Maybe<Scalars['String']>;
  event_schedule_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Max_Order_By = {
  accreditation_number?: Maybe<Order_By>;
  event_schedule_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Schedule_Accreditation_Points_Min_Fields = {
  __typename?: 'event_schedule_accreditation_points_min_fields';
  accreditation_number?: Maybe<Scalars['String']>;
  event_schedule_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Min_Order_By = {
  accreditation_number?: Maybe<Order_By>;
  event_schedule_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Mutation_Response = {
  __typename?: 'event_schedule_accreditation_points_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Schedule_Accreditation_Points>;
};

/** input type for inserting object relation for remote table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Obj_Rel_Insert_Input = {
  data: Event_Schedule_Accreditation_Points_Insert_Input;
  on_conflict?: Maybe<Event_Schedule_Accreditation_Points_On_Conflict>;
};

/** on conflict condition type for table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_On_Conflict = {
  constraint: Event_Schedule_Accreditation_Points_Constraint;
  update_columns: Array<Event_Schedule_Accreditation_Points_Update_Column>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};

/** ordering options when selecting data from "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Order_By = {
  accreditation_number?: Maybe<Order_By>;
  certified?: Maybe<Order_By>;
  event_schedule?: Maybe<Event_Schedules_Order_By>;
  event_schedule_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  points?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "event_schedule_accreditation_points" */
export enum Event_Schedule_Accreditation_Points_Select_Column {
  /** column name */
  AccreditationNumber = 'accreditation_number',
  /** column name */
  Certified = 'certified',
  /** column name */
  EventScheduleId = 'event_schedule_id',
  /** column name */
  Id = 'id',
  /** column name */
  Points = 'points',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Set_Input = {
  accreditation_number?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['Boolean']>;
  event_schedule_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Event_Schedule_Accreditation_Points_Stddev_Fields = {
  __typename?: 'event_schedule_accreditation_points_stddev_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Stddev_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Schedule_Accreditation_Points_Stddev_Pop_Fields = {
  __typename?: 'event_schedule_accreditation_points_stddev_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Stddev_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Schedule_Accreditation_Points_Stddev_Samp_Fields = {
  __typename?: 'event_schedule_accreditation_points_stddev_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Stddev_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Event_Schedule_Accreditation_Points_Sum_Fields = {
  __typename?: 'event_schedule_accreditation_points_sum_fields';
  points?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Sum_Order_By = {
  points?: Maybe<Order_By>;
};

/** update columns of table "event_schedule_accreditation_points" */
export enum Event_Schedule_Accreditation_Points_Update_Column {
  /** column name */
  AccreditationNumber = 'accreditation_number',
  /** column name */
  Certified = 'certified',
  /** column name */
  EventScheduleId = 'event_schedule_id',
  /** column name */
  Id = 'id',
  /** column name */
  Points = 'points',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Event_Schedule_Accreditation_Points_Var_Pop_Fields = {
  __typename?: 'event_schedule_accreditation_points_var_pop_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Var_Pop_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Schedule_Accreditation_Points_Var_Samp_Fields = {
  __typename?: 'event_schedule_accreditation_points_var_samp_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Var_Samp_Order_By = {
  points?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Schedule_Accreditation_Points_Variance_Fields = {
  __typename?: 'event_schedule_accreditation_points_variance_fields';
  points?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_schedule_accreditation_points" */
export type Event_Schedule_Accreditation_Points_Variance_Order_By = {
  points?: Maybe<Order_By>;
};

/** columns and relationships of "event_schedule_types" */
export type Event_Schedule_Types = {
  __typename?: 'event_schedule_types';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "event_schedule_types" */
export type Event_Schedule_Types_Aggregate = {
  __typename?: 'event_schedule_types_aggregate';
  aggregate?: Maybe<Event_Schedule_Types_Aggregate_Fields>;
  nodes: Array<Event_Schedule_Types>;
};

/** aggregate fields of "event_schedule_types" */
export type Event_Schedule_Types_Aggregate_Fields = {
  __typename?: 'event_schedule_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Schedule_Types_Max_Fields>;
  min?: Maybe<Event_Schedule_Types_Min_Fields>;
};


/** aggregate fields of "event_schedule_types" */
export type Event_Schedule_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Schedule_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_schedule_types" */
export type Event_Schedule_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Schedule_Types_Max_Order_By>;
  min?: Maybe<Event_Schedule_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_schedule_types" */
export type Event_Schedule_Types_Arr_Rel_Insert_Input = {
  data: Array<Event_Schedule_Types_Insert_Input>;
  on_conflict?: Maybe<Event_Schedule_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_schedule_types". All fields are combined with a logical 'AND'. */
export type Event_Schedule_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Schedule_Types_Bool_Exp>>>;
  _not?: Maybe<Event_Schedule_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Schedule_Types_Bool_Exp>>>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_schedule_types" */
export enum Event_Schedule_Types_Constraint {
  /** unique or primary key constraint */
  EventScheduleTypePkey = 'event_schedule_type_pkey'
}

export enum Event_Schedule_Types_Enum {
  /** 200 Block */
  Block = 'block',
  /** 350 Presence Click */
  Click = 'click',
  /** 100 Tag */
  Day = 'day',
  /** 320 Vortrag */
  Lecture = 'lecture',
  /** 360 Organisation */
  Org = 'org',
  /** 310 Registrierung */
  Registration = 'registration',
  /** 390 Anfang oder Ende des Blocks/Tages */
  StartOrEnd = 'start_or_end',
  /** 240 Stream Einheit */
  Stream = 'stream',
  /** 330 Symposium */
  Symposium = 'symposium',
  /** 220 Themenblock */
  TopicBlock = 'topic_block',
  /** 340 Workshop */
  Workshop = 'workshop'
}

/** expression to compare columns of type event_schedule_types_enum. All fields are combined with logical 'AND'. */
export type Event_Schedule_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Event_Schedule_Types_Enum>;
  _in?: Maybe<Array<Event_Schedule_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Event_Schedule_Types_Enum>;
  _nin?: Maybe<Array<Event_Schedule_Types_Enum>>;
};

/** input type for inserting data into table "event_schedule_types" */
export type Event_Schedule_Types_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Schedule_Types_Max_Fields = {
  __typename?: 'event_schedule_types_max_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_schedule_types" */
export type Event_Schedule_Types_Max_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Schedule_Types_Min_Fields = {
  __typename?: 'event_schedule_types_min_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_schedule_types" */
export type Event_Schedule_Types_Min_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_schedule_types" */
export type Event_Schedule_Types_Mutation_Response = {
  __typename?: 'event_schedule_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Schedule_Types>;
};

/** input type for inserting object relation for remote table "event_schedule_types" */
export type Event_Schedule_Types_Obj_Rel_Insert_Input = {
  data: Event_Schedule_Types_Insert_Input;
  on_conflict?: Maybe<Event_Schedule_Types_On_Conflict>;
};

/** on conflict condition type for table "event_schedule_types" */
export type Event_Schedule_Types_On_Conflict = {
  constraint: Event_Schedule_Types_Constraint;
  update_columns: Array<Event_Schedule_Types_Update_Column>;
  where?: Maybe<Event_Schedule_Types_Bool_Exp>;
};

/** ordering options when selecting data from "event_schedule_types" */
export type Event_Schedule_Types_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_schedule_types" */
export type Event_Schedule_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "event_schedule_types" */
export enum Event_Schedule_Types_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "event_schedule_types" */
export type Event_Schedule_Types_Set_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "event_schedule_types" */
export enum Event_Schedule_Types_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "event_schedules" */
export type Event_Schedules = {
  __typename?: 'event_schedules';
  /** An array relationship */
  accreditation_points: Array<Event_Schedule_Accreditation_Points>;
  /** An aggregated array relationship */
  accreditation_points_aggregate: Event_Schedule_Accreditation_Points_Aggregate;
  /** An array relationship */
  children: Array<Event_Schedules>;
  /** An aggregated array relationship */
  children_aggregate: Event_Schedules_Aggregate;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time: Scalars['timestamptz'];
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid'];
  ext_link_button_timeline?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  ondemand_id?: Maybe<Scalars['uuid']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  /** An object relationship */
  ondemand_source?: Maybe<Ondemand_Sources>;
  /** An object relationship */
  parent?: Maybe<Event_Schedules>;
  parent_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  speakers: Array<Event_Schedules_Speakers>;
  /** An aggregated array relationship */
  speakers_aggregate: Event_Schedules_Speakers_Aggregate;
  start_time: Scalars['timestamptz'];
  title: Scalars['String'];
  /** An object relationship */
  type?: Maybe<Event_Schedule_Types>;
  type_id?: Maybe<Event_Schedule_Types_Enum>;
  ub_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesAccreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesAccreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesChildrenArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesExt_Link_Button_TimelineArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesSpeakersArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** columns and relationships of "event_schedules" */
export type Event_SchedulesSpeakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};

/** aggregated selection of "event_schedules" */
export type Event_Schedules_Aggregate = {
  __typename?: 'event_schedules_aggregate';
  aggregate?: Maybe<Event_Schedules_Aggregate_Fields>;
  nodes: Array<Event_Schedules>;
};

/** aggregate fields of "event_schedules" */
export type Event_Schedules_Aggregate_Fields = {
  __typename?: 'event_schedules_aggregate_fields';
  avg?: Maybe<Event_Schedules_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Schedules_Max_Fields>;
  min?: Maybe<Event_Schedules_Min_Fields>;
  stddev?: Maybe<Event_Schedules_Stddev_Fields>;
  stddev_pop?: Maybe<Event_Schedules_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Event_Schedules_Stddev_Samp_Fields>;
  sum?: Maybe<Event_Schedules_Sum_Fields>;
  var_pop?: Maybe<Event_Schedules_Var_Pop_Fields>;
  var_samp?: Maybe<Event_Schedules_Var_Samp_Fields>;
  variance?: Maybe<Event_Schedules_Variance_Fields>;
};


/** aggregate fields of "event_schedules" */
export type Event_Schedules_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Schedules_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_schedules" */
export type Event_Schedules_Aggregate_Order_By = {
  avg?: Maybe<Event_Schedules_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Schedules_Max_Order_By>;
  min?: Maybe<Event_Schedules_Min_Order_By>;
  stddev?: Maybe<Event_Schedules_Stddev_Order_By>;
  stddev_pop?: Maybe<Event_Schedules_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Event_Schedules_Stddev_Samp_Order_By>;
  sum?: Maybe<Event_Schedules_Sum_Order_By>;
  var_pop?: Maybe<Event_Schedules_Var_Pop_Order_By>;
  var_samp?: Maybe<Event_Schedules_Var_Samp_Order_By>;
  variance?: Maybe<Event_Schedules_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "event_schedules" */
export type Event_Schedules_Arr_Rel_Insert_Input = {
  data: Array<Event_Schedules_Insert_Input>;
  on_conflict?: Maybe<Event_Schedules_On_Conflict>;
};

/** aggregate avg on columns */
export type Event_Schedules_Avg_Fields = {
  __typename?: 'event_schedules_avg_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event_schedules" */
export type Event_Schedules_Avg_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "event_schedules". All fields are combined with a logical 'AND'. */
export type Event_Schedules_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Schedules_Bool_Exp>>>;
  _not?: Maybe<Event_Schedules_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Schedules_Bool_Exp>>>;
  accreditation_points?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
  children?: Maybe<Event_Schedules_Bool_Exp>;
  drupal_id?: Maybe<Int_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  event?: Maybe<Events_Bool_Exp>;
  event_id?: Maybe<Uuid_Comparison_Exp>;
  ext_link_button_timeline?: Maybe<Json_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ondemand_id?: Maybe<Uuid_Comparison_Exp>;
  ondemand_seconds?: Maybe<Int_Comparison_Exp>;
  ondemand_source?: Maybe<Ondemand_Sources_Bool_Exp>;
  parent?: Maybe<Event_Schedules_Bool_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  speakers?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Event_Schedule_Types_Bool_Exp>;
  type_id?: Maybe<Event_Schedule_Types_Enum_Comparison_Exp>;
  ub_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_schedules" */
export enum Event_Schedules_Constraint {
  /** unique or primary key constraint */
  EventSchedulesDrupalIdKey = 'event_schedules_drupal_id_key',
  /** unique or primary key constraint */
  EventSchedulesPkey = 'event_schedules_pkey'
}

/** input type for incrementing integer column in table "event_schedules" */
export type Event_Schedules_Inc_Input = {
  drupal_id?: Maybe<Scalars['Int']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "event_schedules" */
export type Event_Schedules_Insert_Input = {
  accreditation_points?: Maybe<Event_Schedule_Accreditation_Points_Arr_Rel_Insert_Input>;
  children?: Maybe<Event_Schedules_Arr_Rel_Insert_Input>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event?: Maybe<Events_Obj_Rel_Insert_Input>;
  event_id?: Maybe<Scalars['uuid']>;
  ext_link_button_timeline?: Maybe<Scalars['json']>;
  id?: Maybe<Scalars['uuid']>;
  ondemand_id?: Maybe<Scalars['uuid']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  ondemand_source?: Maybe<Ondemand_Sources_Obj_Rel_Insert_Input>;
  parent?: Maybe<Event_Schedules_Obj_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['uuid']>;
  speakers?: Maybe<Event_Schedules_Speakers_Arr_Rel_Insert_Input>;
  start_time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Event_Schedule_Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Event_Schedule_Types_Enum>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Event_Schedules_Max_Fields = {
  __typename?: 'event_schedules_max_fields';
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ondemand_id?: Maybe<Scalars['uuid']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "event_schedules" */
export type Event_Schedules_Max_Order_By = {
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ondemand_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Schedules_Min_Fields = {
  __typename?: 'event_schedules_min_fields';
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  ondemand_id?: Maybe<Scalars['uuid']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "event_schedules" */
export type Event_Schedules_Min_Order_By = {
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ondemand_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_schedules" */
export type Event_Schedules_Mutation_Response = {
  __typename?: 'event_schedules_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Schedules>;
};

/** input type for inserting object relation for remote table "event_schedules" */
export type Event_Schedules_Obj_Rel_Insert_Input = {
  data: Event_Schedules_Insert_Input;
  on_conflict?: Maybe<Event_Schedules_On_Conflict>;
};

/** on conflict condition type for table "event_schedules" */
export type Event_Schedules_On_Conflict = {
  constraint: Event_Schedules_Constraint;
  update_columns: Array<Event_Schedules_Update_Column>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};

/** ordering options when selecting data from "event_schedules" */
export type Event_Schedules_Order_By = {
  accreditation_points_aggregate?: Maybe<Event_Schedule_Accreditation_Points_Aggregate_Order_By>;
  children_aggregate?: Maybe<Event_Schedules_Aggregate_Order_By>;
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event?: Maybe<Events_Order_By>;
  event_id?: Maybe<Order_By>;
  ext_link_button_timeline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ondemand_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ondemand_source?: Maybe<Ondemand_Sources_Order_By>;
  parent?: Maybe<Event_Schedules_Order_By>;
  parent_id?: Maybe<Order_By>;
  speakers_aggregate?: Maybe<Event_Schedules_Speakers_Aggregate_Order_By>;
  start_time?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  type?: Maybe<Event_Schedule_Types_Order_By>;
  type_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_schedules" */
export type Event_Schedules_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "event_schedules" */
export enum Event_Schedules_Select_Column {
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  ExtLinkButtonTimeline = 'ext_link_button_timeline',
  /** column name */
  Id = 'id',
  /** column name */
  OndemandId = 'ondemand_id',
  /** column name */
  OndemandSeconds = 'ondemand_seconds',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Title = 'title',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UbId = 'ub_id'
}

/** input type for updating data in table "event_schedules" */
export type Event_Schedules_Set_Input = {
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  ext_link_button_timeline?: Maybe<Scalars['json']>;
  id?: Maybe<Scalars['uuid']>;
  ondemand_id?: Maybe<Scalars['uuid']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  parent_id?: Maybe<Scalars['uuid']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type_id?: Maybe<Event_Schedule_Types_Enum>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "event_schedules_speakers" */
export type Event_Schedules_Speakers = {
  __typename?: 'event_schedules_speakers';
  /** An object relationship */
  event_schedule: Event_Schedules;
  event_schedule_id: Scalars['uuid'];
  /** An object relationship */
  speaker: Speakers;
  speaker_id: Scalars['uuid'];
};

/** aggregated selection of "event_schedules_speakers" */
export type Event_Schedules_Speakers_Aggregate = {
  __typename?: 'event_schedules_speakers_aggregate';
  aggregate?: Maybe<Event_Schedules_Speakers_Aggregate_Fields>;
  nodes: Array<Event_Schedules_Speakers>;
};

/** aggregate fields of "event_schedules_speakers" */
export type Event_Schedules_Speakers_Aggregate_Fields = {
  __typename?: 'event_schedules_speakers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Schedules_Speakers_Max_Fields>;
  min?: Maybe<Event_Schedules_Speakers_Min_Fields>;
};


/** aggregate fields of "event_schedules_speakers" */
export type Event_Schedules_Speakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Schedules_Speakers_Max_Order_By>;
  min?: Maybe<Event_Schedules_Speakers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Arr_Rel_Insert_Input = {
  data: Array<Event_Schedules_Speakers_Insert_Input>;
  on_conflict?: Maybe<Event_Schedules_Speakers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_schedules_speakers". All fields are combined with a logical 'AND'. */
export type Event_Schedules_Speakers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Schedules_Speakers_Bool_Exp>>>;
  _not?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Schedules_Speakers_Bool_Exp>>>;
  event_schedule?: Maybe<Event_Schedules_Bool_Exp>;
  event_schedule_id?: Maybe<Uuid_Comparison_Exp>;
  speaker?: Maybe<Speakers_Bool_Exp>;
  speaker_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_schedules_speakers" */
export enum Event_Schedules_Speakers_Constraint {
  /** unique or primary key constraint */
  EventSchedulesSpeakersPkey = 'event_schedules_speakers_pkey'
}

/** input type for inserting data into table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Insert_Input = {
  event_schedule?: Maybe<Event_Schedules_Obj_Rel_Insert_Input>;
  event_schedule_id?: Maybe<Scalars['uuid']>;
  speaker?: Maybe<Speakers_Obj_Rel_Insert_Input>;
  speaker_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Event_Schedules_Speakers_Max_Fields = {
  __typename?: 'event_schedules_speakers_max_fields';
  event_schedule_id?: Maybe<Scalars['uuid']>;
  speaker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Max_Order_By = {
  event_schedule_id?: Maybe<Order_By>;
  speaker_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Schedules_Speakers_Min_Fields = {
  __typename?: 'event_schedules_speakers_min_fields';
  event_schedule_id?: Maybe<Scalars['uuid']>;
  speaker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Min_Order_By = {
  event_schedule_id?: Maybe<Order_By>;
  speaker_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Mutation_Response = {
  __typename?: 'event_schedules_speakers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Schedules_Speakers>;
};

/** input type for inserting object relation for remote table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Obj_Rel_Insert_Input = {
  data: Event_Schedules_Speakers_Insert_Input;
  on_conflict?: Maybe<Event_Schedules_Speakers_On_Conflict>;
};

/** on conflict condition type for table "event_schedules_speakers" */
export type Event_Schedules_Speakers_On_Conflict = {
  constraint: Event_Schedules_Speakers_Constraint;
  update_columns: Array<Event_Schedules_Speakers_Update_Column>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};

/** ordering options when selecting data from "event_schedules_speakers" */
export type Event_Schedules_Speakers_Order_By = {
  event_schedule?: Maybe<Event_Schedules_Order_By>;
  event_schedule_id?: Maybe<Order_By>;
  speaker?: Maybe<Speakers_Order_By>;
  speaker_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_schedules_speakers" */
export type Event_Schedules_Speakers_Pk_Columns_Input = {
  event_schedule_id: Scalars['uuid'];
  speaker_id: Scalars['uuid'];
};

/** select columns of table "event_schedules_speakers" */
export enum Event_Schedules_Speakers_Select_Column {
  /** column name */
  EventScheduleId = 'event_schedule_id',
  /** column name */
  SpeakerId = 'speaker_id'
}

/** input type for updating data in table "event_schedules_speakers" */
export type Event_Schedules_Speakers_Set_Input = {
  event_schedule_id?: Maybe<Scalars['uuid']>;
  speaker_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "event_schedules_speakers" */
export enum Event_Schedules_Speakers_Update_Column {
  /** column name */
  EventScheduleId = 'event_schedule_id',
  /** column name */
  SpeakerId = 'speaker_id'
}

/** aggregate stddev on columns */
export type Event_Schedules_Stddev_Fields = {
  __typename?: 'event_schedules_stddev_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event_schedules" */
export type Event_Schedules_Stddev_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Event_Schedules_Stddev_Pop_Fields = {
  __typename?: 'event_schedules_stddev_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event_schedules" */
export type Event_Schedules_Stddev_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Event_Schedules_Stddev_Samp_Fields = {
  __typename?: 'event_schedules_stddev_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event_schedules" */
export type Event_Schedules_Stddev_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Event_Schedules_Sum_Fields = {
  __typename?: 'event_schedules_sum_fields';
  drupal_id?: Maybe<Scalars['Int']>;
  ondemand_seconds?: Maybe<Scalars['Int']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "event_schedules" */
export type Event_Schedules_Sum_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** update columns of table "event_schedules" */
export enum Event_Schedules_Update_Column {
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  ExtLinkButtonTimeline = 'ext_link_button_timeline',
  /** column name */
  Id = 'id',
  /** column name */
  OndemandId = 'ondemand_id',
  /** column name */
  OndemandSeconds = 'ondemand_seconds',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Title = 'title',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UbId = 'ub_id'
}

/** aggregate var_pop on columns */
export type Event_Schedules_Var_Pop_Fields = {
  __typename?: 'event_schedules_var_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event_schedules" */
export type Event_Schedules_Var_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Event_Schedules_Var_Samp_Fields = {
  __typename?: 'event_schedules_var_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event_schedules" */
export type Event_Schedules_Var_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Event_Schedules_Variance_Fields = {
  __typename?: 'event_schedules_variance_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ondemand_seconds?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event_schedules" */
export type Event_Schedules_Variance_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ondemand_seconds?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** columns and relationships of "event_sponsoring_types" */
export type Event_Sponsoring_Types = {
  __typename?: 'event_sponsoring_types';
  name: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "event_sponsoring_types" */
export type Event_Sponsoring_Types_Aggregate = {
  __typename?: 'event_sponsoring_types_aggregate';
  aggregate?: Maybe<Event_Sponsoring_Types_Aggregate_Fields>;
  nodes: Array<Event_Sponsoring_Types>;
};

/** aggregate fields of "event_sponsoring_types" */
export type Event_Sponsoring_Types_Aggregate_Fields = {
  __typename?: 'event_sponsoring_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Sponsoring_Types_Max_Fields>;
  min?: Maybe<Event_Sponsoring_Types_Min_Fields>;
};


/** aggregate fields of "event_sponsoring_types" */
export type Event_Sponsoring_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Sponsoring_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Sponsoring_Types_Max_Order_By>;
  min?: Maybe<Event_Sponsoring_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Arr_Rel_Insert_Input = {
  data: Array<Event_Sponsoring_Types_Insert_Input>;
  on_conflict?: Maybe<Event_Sponsoring_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_sponsoring_types". All fields are combined with a logical 'AND'. */
export type Event_Sponsoring_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Sponsoring_Types_Bool_Exp>>>;
  _not?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Sponsoring_Types_Bool_Exp>>>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_sponsoring_types" */
export enum Event_Sponsoring_Types_Constraint {
  /** unique or primary key constraint */
  EventSponsoringTypesPkey = 'event_sponsoring_types_pkey'
}

export enum Event_Sponsoring_Types_Enum {
  /** Exhibition sponsor */
  Expo = 'expo',
  /** Sponsoring the full event */
  FullEventSponsor = 'full_event_sponsor',
  /** Symposium sponsor */
  Symposium = 'symposium'
}

/** expression to compare columns of type event_sponsoring_types_enum. All fields are combined with logical 'AND'. */
export type Event_Sponsoring_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Event_Sponsoring_Types_Enum>;
  _in?: Maybe<Array<Event_Sponsoring_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Event_Sponsoring_Types_Enum>;
  _nin?: Maybe<Array<Event_Sponsoring_Types_Enum>>;
};

/** input type for inserting data into table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Event_Sponsoring_Types_Max_Fields = {
  __typename?: 'event_sponsoring_types_max_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Max_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Sponsoring_Types_Min_Fields = {
  __typename?: 'event_sponsoring_types_min_fields';
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Min_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Mutation_Response = {
  __typename?: 'event_sponsoring_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Sponsoring_Types>;
};

/** input type for inserting object relation for remote table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Obj_Rel_Insert_Input = {
  data: Event_Sponsoring_Types_Insert_Input;
  on_conflict?: Maybe<Event_Sponsoring_Types_On_Conflict>;
};

/** on conflict condition type for table "event_sponsoring_types" */
export type Event_Sponsoring_Types_On_Conflict = {
  constraint: Event_Sponsoring_Types_Constraint;
  update_columns: Array<Event_Sponsoring_Types_Update_Column>;
  where?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
};

/** ordering options when selecting data from "event_sponsoring_types" */
export type Event_Sponsoring_Types_Order_By = {
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_sponsoring_types" */
export type Event_Sponsoring_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "event_sponsoring_types" */
export enum Event_Sponsoring_Types_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "event_sponsoring_types" */
export type Event_Sponsoring_Types_Set_Input = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "event_sponsoring_types" */
export enum Event_Sponsoring_Types_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "event_sponsorings" */
export type Event_Sponsorings = {
  __typename?: 'event_sponsorings';
  /** An object relationship */
  company_department: Company_Departments;
  company_department_id: Scalars['uuid'];
  config?: Maybe<Scalars['jsonb']>;
  event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  sponsoring_type: Event_Sponsoring_Types_Enum;
};


/** columns and relationships of "event_sponsorings" */
export type Event_SponsoringsConfigArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "event_sponsorings" */
export type Event_Sponsorings_Aggregate = {
  __typename?: 'event_sponsorings_aggregate';
  aggregate?: Maybe<Event_Sponsorings_Aggregate_Fields>;
  nodes: Array<Event_Sponsorings>;
};

/** aggregate fields of "event_sponsorings" */
export type Event_Sponsorings_Aggregate_Fields = {
  __typename?: 'event_sponsorings_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Event_Sponsorings_Max_Fields>;
  min?: Maybe<Event_Sponsorings_Min_Fields>;
};


/** aggregate fields of "event_sponsorings" */
export type Event_Sponsorings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event_sponsorings" */
export type Event_Sponsorings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Sponsorings_Max_Order_By>;
  min?: Maybe<Event_Sponsorings_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Event_Sponsorings_Append_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "event_sponsorings" */
export type Event_Sponsorings_Arr_Rel_Insert_Input = {
  data: Array<Event_Sponsorings_Insert_Input>;
  on_conflict?: Maybe<Event_Sponsorings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "event_sponsorings". All fields are combined with a logical 'AND'. */
export type Event_Sponsorings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Event_Sponsorings_Bool_Exp>>>;
  _not?: Maybe<Event_Sponsorings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Event_Sponsorings_Bool_Exp>>>;
  company_department?: Maybe<Company_Departments_Bool_Exp>;
  company_department_id?: Maybe<Uuid_Comparison_Exp>;
  config?: Maybe<Jsonb_Comparison_Exp>;
  event_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  sponsoring_type?: Maybe<Event_Sponsoring_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_sponsorings" */
export enum Event_Sponsorings_Constraint {
  /** unique or primary key constraint */
  EventSponsoringsPkey = 'event_sponsorings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Event_Sponsorings_Delete_At_Path_Input = {
  config?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Event_Sponsorings_Delete_Elem_Input = {
  config?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Event_Sponsorings_Delete_Key_Input = {
  config?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "event_sponsorings" */
export type Event_Sponsorings_Insert_Input = {
  company_department?: Maybe<Company_Departments_Obj_Rel_Insert_Input>;
  company_department_id?: Maybe<Scalars['uuid']>;
  config?: Maybe<Scalars['jsonb']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sponsoring_type?: Maybe<Event_Sponsoring_Types_Enum>;
};

/** aggregate max on columns */
export type Event_Sponsorings_Max_Fields = {
  __typename?: 'event_sponsorings_max_fields';
  company_department_id?: Maybe<Scalars['uuid']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "event_sponsorings" */
export type Event_Sponsorings_Max_Order_By = {
  company_department_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Event_Sponsorings_Min_Fields = {
  __typename?: 'event_sponsorings_min_fields';
  company_department_id?: Maybe<Scalars['uuid']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "event_sponsorings" */
export type Event_Sponsorings_Min_Order_By = {
  company_department_id?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "event_sponsorings" */
export type Event_Sponsorings_Mutation_Response = {
  __typename?: 'event_sponsorings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Event_Sponsorings>;
};

/** input type for inserting object relation for remote table "event_sponsorings" */
export type Event_Sponsorings_Obj_Rel_Insert_Input = {
  data: Event_Sponsorings_Insert_Input;
  on_conflict?: Maybe<Event_Sponsorings_On_Conflict>;
};

/** on conflict condition type for table "event_sponsorings" */
export type Event_Sponsorings_On_Conflict = {
  constraint: Event_Sponsorings_Constraint;
  update_columns: Array<Event_Sponsorings_Update_Column>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};

/** ordering options when selecting data from "event_sponsorings" */
export type Event_Sponsorings_Order_By = {
  company_department?: Maybe<Company_Departments_Order_By>;
  company_department_id?: Maybe<Order_By>;
  config?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sponsoring_type?: Maybe<Order_By>;
};

/** primary key columns input for table: "event_sponsorings" */
export type Event_Sponsorings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Event_Sponsorings_Prepend_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "event_sponsorings" */
export enum Event_Sponsorings_Select_Column {
  /** column name */
  CompanyDepartmentId = 'company_department_id',
  /** column name */
  Config = 'config',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  SponsoringType = 'sponsoring_type'
}

/** input type for updating data in table "event_sponsorings" */
export type Event_Sponsorings_Set_Input = {
  company_department_id?: Maybe<Scalars['uuid']>;
  config?: Maybe<Scalars['jsonb']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  sponsoring_type?: Maybe<Event_Sponsoring_Types_Enum>;
};

/** update columns of table "event_sponsorings" */
export enum Event_Sponsorings_Update_Column {
  /** column name */
  CompanyDepartmentId = 'company_department_id',
  /** column name */
  Config = 'config',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  SponsoringType = 'sponsoring_type'
}

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  /** An array relationship */
  accreditation_points: Array<Event_Accreditation_Points>;
  /** An aggregated array relationship */
  accreditation_points_aggregate: Event_Accreditation_Points_Aggregate;
  accreditation_points_info?: Maybe<Scalars['String']>;
  accreditation_points_short?: Maybe<Scalars['String']>;
  /** An object relationship */
  accreditation_type?: Maybe<Accreditation_Types>;
  accreditation_type_id: Accreditation_Types_Enum;
  country?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time: Scalars['timestamptz'];
  /** An array relationship */
  event_content: Array<Event_Content>;
  /** An aggregated array relationship */
  event_content_aggregate: Event_Content_Aggregate;
  /** An object relationship */
  event_location?: Maybe<Event_Locations>;
  /** An array relationship */
  event_schedules: Array<Event_Schedules>;
  /** An aggregated array relationship */
  event_schedules_aggregate: Event_Schedules_Aggregate;
  event_sequence?: Maybe<Scalars['String']>;
  /** An array relationship */
  event_sponsorings: Array<Event_Sponsorings>;
  /** An aggregated array relationship */
  event_sponsorings_aggregate: Event_Sponsorings_Aggregate;
  ext_downloads_url?: Maybe<Scalars['String']>;
  ext_expo_url?: Maybe<Scalars['String']>;
  ext_login_url?: Maybe<Scalars['String']>;
  ext_url?: Maybe<Scalars['String']>;
  feedback_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  locale: Locale;
  locale_code: Scalars['String'];
  location_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  microsites: Array<Events_Microsites>;
  /** An aggregated array relationship */
  microsites_aggregate: Events_Microsites_Aggregate;
  ondemand_player?: Maybe<Scalars['String']>;
  ondemand_redirect?: Maybe<Scalars['Boolean']>;
  reg_bcc_email?: Maybe<Scalars['String']>;
  reg_from_email?: Maybe<Scalars['String']>;
  require_efn_number: Scalars['Boolean'];
  slides_com_url?: Maybe<Scalars['String']>;
  start_time: Scalars['timestamptz'];
  support_email?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  ub_id?: Maybe<Scalars['Int']>;
  wonder_room_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "events" */
export type EventsAccreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsAccreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_ContentArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_Content_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_SchedulesArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_SponsoringsArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsEvent_Sponsorings_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsMicrositesArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** columns and relationships of "events" */
export type EventsMicrosites_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "events" */
export type Events_Aggregate_Order_By = {
  avg?: Maybe<Events_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Events_Max_Order_By>;
  min?: Maybe<Events_Min_Order_By>;
  stddev?: Maybe<Events_Stddev_Order_By>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Order_By>;
  sum?: Maybe<Events_Sum_Order_By>;
  var_pop?: Maybe<Events_Var_Pop_Order_By>;
  var_samp?: Maybe<Events_Var_Samp_Order_By>;
  variance?: Maybe<Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "events" */
export type Events_Arr_Rel_Insert_Input = {
  data: Array<Events_Insert_Input>;
  on_conflict?: Maybe<Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Events_Avg_Fields = {
  __typename?: 'events_avg_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "events" */
export type Events_Avg_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Events_Bool_Exp>>>;
  _not?: Maybe<Events_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Events_Bool_Exp>>>;
  accreditation_points?: Maybe<Event_Accreditation_Points_Bool_Exp>;
  accreditation_points_info?: Maybe<String_Comparison_Exp>;
  accreditation_points_short?: Maybe<String_Comparison_Exp>;
  accreditation_type?: Maybe<Accreditation_Types_Bool_Exp>;
  accreditation_type_id?: Maybe<Accreditation_Types_Enum_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  cover_image?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  drupal_id?: Maybe<Int_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  event_content?: Maybe<Event_Content_Bool_Exp>;
  event_location?: Maybe<Event_Locations_Bool_Exp>;
  event_schedules?: Maybe<Event_Schedules_Bool_Exp>;
  event_sequence?: Maybe<String_Comparison_Exp>;
  event_sponsorings?: Maybe<Event_Sponsorings_Bool_Exp>;
  ext_downloads_url?: Maybe<String_Comparison_Exp>;
  ext_expo_url?: Maybe<String_Comparison_Exp>;
  ext_login_url?: Maybe<String_Comparison_Exp>;
  ext_url?: Maybe<String_Comparison_Exp>;
  feedback_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  locale?: Maybe<Locale_Bool_Exp>;
  locale_code?: Maybe<String_Comparison_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  microsites?: Maybe<Events_Microsites_Bool_Exp>;
  ondemand_player?: Maybe<String_Comparison_Exp>;
  ondemand_redirect?: Maybe<Boolean_Comparison_Exp>;
  reg_bcc_email?: Maybe<String_Comparison_Exp>;
  reg_from_email?: Maybe<String_Comparison_Exp>;
  require_efn_number?: Maybe<Boolean_Comparison_Exp>;
  slides_com_url?: Maybe<String_Comparison_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  support_email?: Maybe<String_Comparison_Exp>;
  timezone?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  ub_id?: Maybe<Int_Comparison_Exp>;
  wonder_room_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint */
  EventsDrupalIdKey = 'events_drupal_id_key',
  /** unique or primary key constraint */
  EventsPkey = 'events_pkey',
  /** unique or primary key constraint */
  EventsUbIdKey = 'events_ub_id_key'
}

/** input type for incrementing integer column in table "events" */
export type Events_Inc_Input = {
  drupal_id?: Maybe<Scalars['Int']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  accreditation_points_info?: Maybe<Scalars['String']>;
  accreditation_points_short?: Maybe<Scalars['String']>;
  accreditation_type?: Maybe<Accreditation_Types_Obj_Rel_Insert_Input>;
  accreditation_type_id?: Maybe<Accreditation_Types_Enum>;
  country?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_content?: Maybe<Event_Content_Arr_Rel_Insert_Input>;
  event_location?: Maybe<Event_Locations_Obj_Rel_Insert_Input>;
  event_schedules?: Maybe<Event_Schedules_Arr_Rel_Insert_Input>;
  event_sequence?: Maybe<Scalars['String']>;
  event_sponsorings?: Maybe<Event_Sponsorings_Arr_Rel_Insert_Input>;
  ext_downloads_url?: Maybe<Scalars['String']>;
  ext_expo_url?: Maybe<Scalars['String']>;
  ext_login_url?: Maybe<Scalars['String']>;
  ext_url?: Maybe<Scalars['String']>;
  feedback_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Locale_Obj_Rel_Insert_Input>;
  locale_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  microsites?: Maybe<Events_Microsites_Arr_Rel_Insert_Input>;
  ondemand_player?: Maybe<Scalars['String']>;
  ondemand_redirect?: Maybe<Scalars['Boolean']>;
  reg_bcc_email?: Maybe<Scalars['String']>;
  reg_from_email?: Maybe<Scalars['String']>;
  require_efn_number?: Maybe<Scalars['Boolean']>;
  slides_com_url?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  support_email?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
  wonder_room_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  accreditation_points_info?: Maybe<Scalars['String']>;
  accreditation_points_short?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_sequence?: Maybe<Scalars['String']>;
  ext_downloads_url?: Maybe<Scalars['String']>;
  ext_expo_url?: Maybe<Scalars['String']>;
  ext_login_url?: Maybe<Scalars['String']>;
  ext_url?: Maybe<Scalars['String']>;
  feedback_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locale_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  ondemand_player?: Maybe<Scalars['String']>;
  reg_bcc_email?: Maybe<Scalars['String']>;
  reg_from_email?: Maybe<Scalars['String']>;
  slides_com_url?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  support_email?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
  wonder_room_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "events" */
export type Events_Max_Order_By = {
  accreditation_points_info?: Maybe<Order_By>;
  accreditation_points_short?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  cover_image?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_sequence?: Maybe<Order_By>;
  ext_downloads_url?: Maybe<Order_By>;
  ext_expo_url?: Maybe<Order_By>;
  ext_login_url?: Maybe<Order_By>;
  ext_url?: Maybe<Order_By>;
  feedback_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  ondemand_player?: Maybe<Order_By>;
  reg_bcc_email?: Maybe<Order_By>;
  reg_from_email?: Maybe<Order_By>;
  slides_com_url?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  support_email?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
  wonder_room_url?: Maybe<Order_By>;
};

/** columns and relationships of "events_microsites" */
export type Events_Microsites = {
  __typename?: 'events_microsites';
  /** An object relationship */
  event: Events;
  event_id: Scalars['uuid'];
  id: Scalars['Int'];
  /** An object relationship */
  microsite: Microsites;
  microsite_id: Scalars['uuid'];
};

/** aggregated selection of "events_microsites" */
export type Events_Microsites_Aggregate = {
  __typename?: 'events_microsites_aggregate';
  aggregate?: Maybe<Events_Microsites_Aggregate_Fields>;
  nodes: Array<Events_Microsites>;
};

/** aggregate fields of "events_microsites" */
export type Events_Microsites_Aggregate_Fields = {
  __typename?: 'events_microsites_aggregate_fields';
  avg?: Maybe<Events_Microsites_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Events_Microsites_Max_Fields>;
  min?: Maybe<Events_Microsites_Min_Fields>;
  stddev?: Maybe<Events_Microsites_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Microsites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Microsites_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Microsites_Sum_Fields>;
  var_pop?: Maybe<Events_Microsites_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Microsites_Var_Samp_Fields>;
  variance?: Maybe<Events_Microsites_Variance_Fields>;
};


/** aggregate fields of "events_microsites" */
export type Events_Microsites_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Events_Microsites_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "events_microsites" */
export type Events_Microsites_Aggregate_Order_By = {
  avg?: Maybe<Events_Microsites_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Events_Microsites_Max_Order_By>;
  min?: Maybe<Events_Microsites_Min_Order_By>;
  stddev?: Maybe<Events_Microsites_Stddev_Order_By>;
  stddev_pop?: Maybe<Events_Microsites_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Events_Microsites_Stddev_Samp_Order_By>;
  sum?: Maybe<Events_Microsites_Sum_Order_By>;
  var_pop?: Maybe<Events_Microsites_Var_Pop_Order_By>;
  var_samp?: Maybe<Events_Microsites_Var_Samp_Order_By>;
  variance?: Maybe<Events_Microsites_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "events_microsites" */
export type Events_Microsites_Arr_Rel_Insert_Input = {
  data: Array<Events_Microsites_Insert_Input>;
  on_conflict?: Maybe<Events_Microsites_On_Conflict>;
};

/** aggregate avg on columns */
export type Events_Microsites_Avg_Fields = {
  __typename?: 'events_microsites_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "events_microsites" */
export type Events_Microsites_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "events_microsites". All fields are combined with a logical 'AND'. */
export type Events_Microsites_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Events_Microsites_Bool_Exp>>>;
  _not?: Maybe<Events_Microsites_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Events_Microsites_Bool_Exp>>>;
  event?: Maybe<Events_Bool_Exp>;
  event_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  microsite?: Maybe<Microsites_Bool_Exp>;
  microsite_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "events_microsites" */
export enum Events_Microsites_Constraint {
  /** unique or primary key constraint */
  EventsMicrositesEventIdMicrositeIdKey = 'events_microsites_event_id_microsite_id_key',
  /** unique or primary key constraint */
  EventsMicrositesIdKey = 'events_microsites_id_key',
  /** unique or primary key constraint */
  EventsMicrositesPkey = 'events_microsites_pkey'
}

/** input type for incrementing integer column in table "events_microsites" */
export type Events_Microsites_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "events_microsites" */
export type Events_Microsites_Insert_Input = {
  event?: Maybe<Events_Obj_Rel_Insert_Input>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  microsite?: Maybe<Microsites_Obj_Rel_Insert_Input>;
  microsite_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Events_Microsites_Max_Fields = {
  __typename?: 'events_microsites_max_fields';
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  microsite_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "events_microsites" */
export type Events_Microsites_Max_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsite_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Microsites_Min_Fields = {
  __typename?: 'events_microsites_min_fields';
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  microsite_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "events_microsites" */
export type Events_Microsites_Min_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsite_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "events_microsites" */
export type Events_Microsites_Mutation_Response = {
  __typename?: 'events_microsites_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Events_Microsites>;
};

/** input type for inserting object relation for remote table "events_microsites" */
export type Events_Microsites_Obj_Rel_Insert_Input = {
  data: Events_Microsites_Insert_Input;
  on_conflict?: Maybe<Events_Microsites_On_Conflict>;
};

/** on conflict condition type for table "events_microsites" */
export type Events_Microsites_On_Conflict = {
  constraint: Events_Microsites_Constraint;
  update_columns: Array<Events_Microsites_Update_Column>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};

/** ordering options when selecting data from "events_microsites" */
export type Events_Microsites_Order_By = {
  event?: Maybe<Events_Order_By>;
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsite?: Maybe<Microsites_Order_By>;
  microsite_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "events_microsites" */
export type Events_Microsites_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "events_microsites" */
export enum Events_Microsites_Select_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  MicrositeId = 'microsite_id'
}

/** input type for updating data in table "events_microsites" */
export type Events_Microsites_Set_Input = {
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  microsite_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Events_Microsites_Stddev_Fields = {
  __typename?: 'events_microsites_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "events_microsites" */
export type Events_Microsites_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Events_Microsites_Stddev_Pop_Fields = {
  __typename?: 'events_microsites_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "events_microsites" */
export type Events_Microsites_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Events_Microsites_Stddev_Samp_Fields = {
  __typename?: 'events_microsites_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "events_microsites" */
export type Events_Microsites_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Events_Microsites_Sum_Fields = {
  __typename?: 'events_microsites_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "events_microsites" */
export type Events_Microsites_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "events_microsites" */
export enum Events_Microsites_Update_Column {
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  MicrositeId = 'microsite_id'
}

/** aggregate var_pop on columns */
export type Events_Microsites_Var_Pop_Fields = {
  __typename?: 'events_microsites_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "events_microsites" */
export type Events_Microsites_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Events_Microsites_Var_Samp_Fields = {
  __typename?: 'events_microsites_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "events_microsites" */
export type Events_Microsites_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Events_Microsites_Variance_Fields = {
  __typename?: 'events_microsites_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "events_microsites" */
export type Events_Microsites_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  accreditation_points_info?: Maybe<Scalars['String']>;
  accreditation_points_short?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_sequence?: Maybe<Scalars['String']>;
  ext_downloads_url?: Maybe<Scalars['String']>;
  ext_expo_url?: Maybe<Scalars['String']>;
  ext_login_url?: Maybe<Scalars['String']>;
  ext_url?: Maybe<Scalars['String']>;
  feedback_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locale_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  ondemand_player?: Maybe<Scalars['String']>;
  reg_bcc_email?: Maybe<Scalars['String']>;
  reg_from_email?: Maybe<Scalars['String']>;
  slides_com_url?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  support_email?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
  wonder_room_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "events" */
export type Events_Min_Order_By = {
  accreditation_points_info?: Maybe<Order_By>;
  accreditation_points_short?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  cover_image?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_sequence?: Maybe<Order_By>;
  ext_downloads_url?: Maybe<Order_By>;
  ext_expo_url?: Maybe<Order_By>;
  ext_login_url?: Maybe<Order_By>;
  ext_url?: Maybe<Order_By>;
  feedback_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  ondemand_player?: Maybe<Order_By>;
  reg_bcc_email?: Maybe<Order_By>;
  reg_from_email?: Maybe<Order_By>;
  slides_com_url?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  support_email?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
  wonder_room_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Events>;
};

/** input type for inserting object relation for remote table "events" */
export type Events_Obj_Rel_Insert_Input = {
  data: Events_Insert_Input;
  on_conflict?: Maybe<Events_On_Conflict>;
};

/** on conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns: Array<Events_Update_Column>;
  where?: Maybe<Events_Bool_Exp>;
};

/** ordering options when selecting data from "events" */
export type Events_Order_By = {
  accreditation_points_aggregate?: Maybe<Event_Accreditation_Points_Aggregate_Order_By>;
  accreditation_points_info?: Maybe<Order_By>;
  accreditation_points_short?: Maybe<Order_By>;
  accreditation_type?: Maybe<Accreditation_Types_Order_By>;
  accreditation_type_id?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  cover_image?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_content_aggregate?: Maybe<Event_Content_Aggregate_Order_By>;
  event_location?: Maybe<Event_Locations_Order_By>;
  event_schedules_aggregate?: Maybe<Event_Schedules_Aggregate_Order_By>;
  event_sequence?: Maybe<Order_By>;
  event_sponsorings_aggregate?: Maybe<Event_Sponsorings_Aggregate_Order_By>;
  ext_downloads_url?: Maybe<Order_By>;
  ext_expo_url?: Maybe<Order_By>;
  ext_login_url?: Maybe<Order_By>;
  ext_url?: Maybe<Order_By>;
  feedback_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  locale?: Maybe<Locale_Order_By>;
  locale_code?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  microsites_aggregate?: Maybe<Events_Microsites_Aggregate_Order_By>;
  ondemand_player?: Maybe<Order_By>;
  ondemand_redirect?: Maybe<Order_By>;
  reg_bcc_email?: Maybe<Order_By>;
  reg_from_email?: Maybe<Order_By>;
  require_efn_number?: Maybe<Order_By>;
  slides_com_url?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  support_email?: Maybe<Order_By>;
  timezone?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
  wonder_room_url?: Maybe<Order_By>;
};

/** primary key columns input for table: "events" */
export type Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  AccreditationPointsInfo = 'accreditation_points_info',
  /** column name */
  AccreditationPointsShort = 'accreditation_points_short',
  /** column name */
  AccreditationTypeId = 'accreditation_type_id',
  /** column name */
  Country = 'country',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  Description = 'description',
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventSequence = 'event_sequence',
  /** column name */
  ExtDownloadsUrl = 'ext_downloads_url',
  /** column name */
  ExtExpoUrl = 'ext_expo_url',
  /** column name */
  ExtLoginUrl = 'ext_login_url',
  /** column name */
  ExtUrl = 'ext_url',
  /** column name */
  FeedbackUrl = 'feedback_url',
  /** column name */
  Id = 'id',
  /** column name */
  LocaleCode = 'locale_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OndemandPlayer = 'ondemand_player',
  /** column name */
  OndemandRedirect = 'ondemand_redirect',
  /** column name */
  RegBccEmail = 'reg_bcc_email',
  /** column name */
  RegFromEmail = 'reg_from_email',
  /** column name */
  RequireEfnNumber = 'require_efn_number',
  /** column name */
  SlidesComUrl = 'slides_com_url',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  SupportEmail = 'support_email',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UbId = 'ub_id',
  /** column name */
  WonderRoomUrl = 'wonder_room_url'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  accreditation_points_info?: Maybe<Scalars['String']>;
  accreditation_points_short?: Maybe<Scalars['String']>;
  accreditation_type_id?: Maybe<Accreditation_Types_Enum>;
  country?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_sequence?: Maybe<Scalars['String']>;
  ext_downloads_url?: Maybe<Scalars['String']>;
  ext_expo_url?: Maybe<Scalars['String']>;
  ext_login_url?: Maybe<Scalars['String']>;
  ext_url?: Maybe<Scalars['String']>;
  feedback_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  locale_code?: Maybe<Scalars['String']>;
  location_id?: Maybe<Scalars['uuid']>;
  ondemand_player?: Maybe<Scalars['String']>;
  ondemand_redirect?: Maybe<Scalars['Boolean']>;
  reg_bcc_email?: Maybe<Scalars['String']>;
  reg_from_email?: Maybe<Scalars['String']>;
  require_efn_number?: Maybe<Scalars['Boolean']>;
  slides_com_url?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  support_email?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['Int']>;
  wonder_room_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Events_Stddev_Fields = {
  __typename?: 'events_stddev_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "events" */
export type Events_Stddev_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Events_Stddev_Pop_Fields = {
  __typename?: 'events_stddev_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "events" */
export type Events_Stddev_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Events_Stddev_Samp_Fields = {
  __typename?: 'events_stddev_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "events" */
export type Events_Stddev_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Events_Sum_Fields = {
  __typename?: 'events_sum_fields';
  drupal_id?: Maybe<Scalars['Int']>;
  ub_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "events" */
export type Events_Sum_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  AccreditationPointsInfo = 'accreditation_points_info',
  /** column name */
  AccreditationPointsShort = 'accreditation_points_short',
  /** column name */
  AccreditationTypeId = 'accreditation_type_id',
  /** column name */
  Country = 'country',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  Description = 'description',
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventSequence = 'event_sequence',
  /** column name */
  ExtDownloadsUrl = 'ext_downloads_url',
  /** column name */
  ExtExpoUrl = 'ext_expo_url',
  /** column name */
  ExtLoginUrl = 'ext_login_url',
  /** column name */
  ExtUrl = 'ext_url',
  /** column name */
  FeedbackUrl = 'feedback_url',
  /** column name */
  Id = 'id',
  /** column name */
  LocaleCode = 'locale_code',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  OndemandPlayer = 'ondemand_player',
  /** column name */
  OndemandRedirect = 'ondemand_redirect',
  /** column name */
  RegBccEmail = 'reg_bcc_email',
  /** column name */
  RegFromEmail = 'reg_from_email',
  /** column name */
  RequireEfnNumber = 'require_efn_number',
  /** column name */
  SlidesComUrl = 'slides_com_url',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  SupportEmail = 'support_email',
  /** column name */
  Timezone = 'timezone',
  /** column name */
  Title = 'title',
  /** column name */
  UbId = 'ub_id',
  /** column name */
  WonderRoomUrl = 'wonder_room_url'
}

/** aggregate var_pop on columns */
export type Events_Var_Pop_Fields = {
  __typename?: 'events_var_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "events" */
export type Events_Var_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Events_Var_Samp_Fields = {
  __typename?: 'events_var_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "events" */
export type Events_Var_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Events_Variance_Fields = {
  __typename?: 'events_variance_fields';
  drupal_id?: Maybe<Scalars['Float']>;
  ub_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "events" */
export type Events_Variance_Order_By = {
  drupal_id?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** columns and relationships of "firebase_projects" */
export type Firebase_Projects = {
  __typename?: 'firebase_projects';
  firebaseConfig: Scalars['jsonb'];
  id: Scalars['String'];
  /** An object relationship */
  microsite?: Maybe<Microsites>;
  number: Scalars['String'];
  serviceAccount?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "firebase_projects" */
export type Firebase_ProjectsFirebaseConfigArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "firebase_projects" */
export type Firebase_ProjectsServiceAccountArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "firebase_projects" */
export type Firebase_Projects_Aggregate = {
  __typename?: 'firebase_projects_aggregate';
  aggregate?: Maybe<Firebase_Projects_Aggregate_Fields>;
  nodes: Array<Firebase_Projects>;
};

/** aggregate fields of "firebase_projects" */
export type Firebase_Projects_Aggregate_Fields = {
  __typename?: 'firebase_projects_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Firebase_Projects_Max_Fields>;
  min?: Maybe<Firebase_Projects_Min_Fields>;
};


/** aggregate fields of "firebase_projects" */
export type Firebase_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Firebase_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "firebase_projects" */
export type Firebase_Projects_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Firebase_Projects_Max_Order_By>;
  min?: Maybe<Firebase_Projects_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Firebase_Projects_Append_Input = {
  firebaseConfig?: Maybe<Scalars['jsonb']>;
  serviceAccount?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "firebase_projects" */
export type Firebase_Projects_Arr_Rel_Insert_Input = {
  data: Array<Firebase_Projects_Insert_Input>;
  on_conflict?: Maybe<Firebase_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "firebase_projects". All fields are combined with a logical 'AND'. */
export type Firebase_Projects_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Firebase_Projects_Bool_Exp>>>;
  _not?: Maybe<Firebase_Projects_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Firebase_Projects_Bool_Exp>>>;
  firebaseConfig?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  microsite?: Maybe<Microsites_Bool_Exp>;
  number?: Maybe<String_Comparison_Exp>;
  serviceAccount?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "firebase_projects" */
export enum Firebase_Projects_Constraint {
  /** unique or primary key constraint */
  FirebaseProjectsNumberKey = 'firebase_projects_number_key',
  /** unique or primary key constraint */
  FirebaseProjectsPkey = 'firebase_projects_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Firebase_Projects_Delete_At_Path_Input = {
  firebaseConfig?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceAccount?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Firebase_Projects_Delete_Elem_Input = {
  firebaseConfig?: Maybe<Scalars['Int']>;
  serviceAccount?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Firebase_Projects_Delete_Key_Input = {
  firebaseConfig?: Maybe<Scalars['String']>;
  serviceAccount?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "firebase_projects" */
export type Firebase_Projects_Insert_Input = {
  firebaseConfig?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  microsite?: Maybe<Microsites_Obj_Rel_Insert_Input>;
  number?: Maybe<Scalars['String']>;
  serviceAccount?: Maybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Firebase_Projects_Max_Fields = {
  __typename?: 'firebase_projects_max_fields';
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "firebase_projects" */
export type Firebase_Projects_Max_Order_By = {
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Firebase_Projects_Min_Fields = {
  __typename?: 'firebase_projects_min_fields';
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "firebase_projects" */
export type Firebase_Projects_Min_Order_By = {
  id?: Maybe<Order_By>;
  number?: Maybe<Order_By>;
};

/** response of any mutation on the table "firebase_projects" */
export type Firebase_Projects_Mutation_Response = {
  __typename?: 'firebase_projects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Firebase_Projects>;
};

/** input type for inserting object relation for remote table "firebase_projects" */
export type Firebase_Projects_Obj_Rel_Insert_Input = {
  data: Firebase_Projects_Insert_Input;
  on_conflict?: Maybe<Firebase_Projects_On_Conflict>;
};

/** on conflict condition type for table "firebase_projects" */
export type Firebase_Projects_On_Conflict = {
  constraint: Firebase_Projects_Constraint;
  update_columns: Array<Firebase_Projects_Update_Column>;
  where?: Maybe<Firebase_Projects_Bool_Exp>;
};

/** ordering options when selecting data from "firebase_projects" */
export type Firebase_Projects_Order_By = {
  firebaseConfig?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  microsite?: Maybe<Microsites_Order_By>;
  number?: Maybe<Order_By>;
  serviceAccount?: Maybe<Order_By>;
};

/** primary key columns input for table: "firebase_projects" */
export type Firebase_Projects_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Firebase_Projects_Prepend_Input = {
  firebaseConfig?: Maybe<Scalars['jsonb']>;
  serviceAccount?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "firebase_projects" */
export enum Firebase_Projects_Select_Column {
  /** column name */
  FirebaseConfig = 'firebaseConfig',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  ServiceAccount = 'serviceAccount'
}

/** input type for updating data in table "firebase_projects" */
export type Firebase_Projects_Set_Input = {
  firebaseConfig?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  serviceAccount?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "firebase_projects" */
export enum Firebase_Projects_Update_Column {
  /** column name */
  FirebaseConfig = 'firebaseConfig',
  /** column name */
  Id = 'id',
  /** column name */
  Number = 'number',
  /** column name */
  ServiceAccount = 'serviceAccount'
}


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "livestream_sources" */
export type Livestream_Sources = {
  __typename?: 'livestream_sources';
  id: Scalars['uuid'];
  name: Scalars['String'];
  provider: Scalars['String'];
  src: Scalars['String'];
};

/** aggregated selection of "livestream_sources" */
export type Livestream_Sources_Aggregate = {
  __typename?: 'livestream_sources_aggregate';
  aggregate?: Maybe<Livestream_Sources_Aggregate_Fields>;
  nodes: Array<Livestream_Sources>;
};

/** aggregate fields of "livestream_sources" */
export type Livestream_Sources_Aggregate_Fields = {
  __typename?: 'livestream_sources_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Livestream_Sources_Max_Fields>;
  min?: Maybe<Livestream_Sources_Min_Fields>;
};


/** aggregate fields of "livestream_sources" */
export type Livestream_Sources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Livestream_Sources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "livestream_sources" */
export type Livestream_Sources_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Livestream_Sources_Max_Order_By>;
  min?: Maybe<Livestream_Sources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "livestream_sources" */
export type Livestream_Sources_Arr_Rel_Insert_Input = {
  data: Array<Livestream_Sources_Insert_Input>;
  on_conflict?: Maybe<Livestream_Sources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "livestream_sources". All fields are combined with a logical 'AND'. */
export type Livestream_Sources_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Livestream_Sources_Bool_Exp>>>;
  _not?: Maybe<Livestream_Sources_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Livestream_Sources_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
  src?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "livestream_sources" */
export enum Livestream_Sources_Constraint {
  /** unique or primary key constraint */
  LivestreamSourcesPkey = 'livestream_sources_pkey'
}

/** input type for inserting data into table "livestream_sources" */
export type Livestream_Sources_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Livestream_Sources_Max_Fields = {
  __typename?: 'livestream_sources_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "livestream_sources" */
export type Livestream_Sources_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Livestream_Sources_Min_Fields = {
  __typename?: 'livestream_sources_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "livestream_sources" */
export type Livestream_Sources_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** response of any mutation on the table "livestream_sources" */
export type Livestream_Sources_Mutation_Response = {
  __typename?: 'livestream_sources_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Livestream_Sources>;
};

/** input type for inserting object relation for remote table "livestream_sources" */
export type Livestream_Sources_Obj_Rel_Insert_Input = {
  data: Livestream_Sources_Insert_Input;
  on_conflict?: Maybe<Livestream_Sources_On_Conflict>;
};

/** on conflict condition type for table "livestream_sources" */
export type Livestream_Sources_On_Conflict = {
  constraint: Livestream_Sources_Constraint;
  update_columns: Array<Livestream_Sources_Update_Column>;
  where?: Maybe<Livestream_Sources_Bool_Exp>;
};

/** ordering options when selecting data from "livestream_sources" */
export type Livestream_Sources_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** primary key columns input for table: "livestream_sources" */
export type Livestream_Sources_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "livestream_sources" */
export enum Livestream_Sources_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Src = 'src'
}

/** input type for updating data in table "livestream_sources" */
export type Livestream_Sources_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** update columns of table "livestream_sources" */
export enum Livestream_Sources_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Src = 'src'
}

/** columns and relationships of "locale" */
export type Locale = {
  __typename?: 'locale';
  code: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "locale" */
export type Locale_Aggregate = {
  __typename?: 'locale_aggregate';
  aggregate?: Maybe<Locale_Aggregate_Fields>;
  nodes: Array<Locale>;
};

/** aggregate fields of "locale" */
export type Locale_Aggregate_Fields = {
  __typename?: 'locale_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Locale_Max_Fields>;
  min?: Maybe<Locale_Min_Fields>;
};


/** aggregate fields of "locale" */
export type Locale_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "locale" */
export type Locale_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Locale_Max_Order_By>;
  min?: Maybe<Locale_Min_Order_By>;
};

/** input type for inserting array relation for remote table "locale" */
export type Locale_Arr_Rel_Insert_Input = {
  data: Array<Locale_Insert_Input>;
  on_conflict?: Maybe<Locale_On_Conflict>;
};

/** Boolean expression to filter rows from the table "locale". All fields are combined with a logical 'AND'. */
export type Locale_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Locale_Bool_Exp>>>;
  _not?: Maybe<Locale_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Locale_Bool_Exp>>>;
  code?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locale" */
export enum Locale_Constraint {
  /** unique or primary key constraint */
  LocaleNameKey = 'locale_name_key',
  /** unique or primary key constraint */
  LocalePkey = 'locale_pkey'
}

/** input type for inserting data into table "locale" */
export type Locale_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locale_Max_Fields = {
  __typename?: 'locale_max_fields';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "locale" */
export type Locale_Max_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Locale_Min_Fields = {
  __typename?: 'locale_min_fields';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "locale" */
export type Locale_Min_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "locale" */
export type Locale_Mutation_Response = {
  __typename?: 'locale_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Locale>;
};

/** input type for inserting object relation for remote table "locale" */
export type Locale_Obj_Rel_Insert_Input = {
  data: Locale_Insert_Input;
  on_conflict?: Maybe<Locale_On_Conflict>;
};

/** on conflict condition type for table "locale" */
export type Locale_On_Conflict = {
  constraint: Locale_Constraint;
  update_columns: Array<Locale_Update_Column>;
  where?: Maybe<Locale_Bool_Exp>;
};

/** ordering options when selecting data from "locale" */
export type Locale_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "locale" */
export type Locale_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "locale" */
export enum Locale_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "locale" */
export type Locale_Set_Input = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "locale" */
export enum Locale_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "localized_files" */
export type Localized_Files = {
  __typename?: 'localized_files';
  admin_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  /** An object relationship */
  firebase_project?: Maybe<Firebase_Projects>;
  firebase_project_id?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
};

/** aggregated selection of "localized_files" */
export type Localized_Files_Aggregate = {
  __typename?: 'localized_files_aggregate';
  aggregate?: Maybe<Localized_Files_Aggregate_Fields>;
  nodes: Array<Localized_Files>;
};

/** aggregate fields of "localized_files" */
export type Localized_Files_Aggregate_Fields = {
  __typename?: 'localized_files_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Localized_Files_Max_Fields>;
  min?: Maybe<Localized_Files_Min_Fields>;
};


/** aggregate fields of "localized_files" */
export type Localized_Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Localized_Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "localized_files" */
export type Localized_Files_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Localized_Files_Max_Order_By>;
  min?: Maybe<Localized_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "localized_files" */
export type Localized_Files_Arr_Rel_Insert_Input = {
  data: Array<Localized_Files_Insert_Input>;
  on_conflict?: Maybe<Localized_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "localized_files". All fields are combined with a logical 'AND'. */
export type Localized_Files_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Localized_Files_Bool_Exp>>>;
  _not?: Maybe<Localized_Files_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Localized_Files_Bool_Exp>>>;
  admin_description?: Maybe<String_Comparison_Exp>;
  de?: Maybe<String_Comparison_Exp>;
  de_AT?: Maybe<String_Comparison_Exp>;
  de_CH?: Maybe<String_Comparison_Exp>;
  default?: Maybe<String_Comparison_Exp>;
  en_GB?: Maybe<String_Comparison_Exp>;
  firebase_project?: Maybe<Firebase_Projects_Bool_Exp>;
  firebase_project_id?: Maybe<String_Comparison_Exp>;
  fr_CH?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "localized_files" */
export enum Localized_Files_Constraint {
  /** unique or primary key constraint */
  LocalizedFilesPkey = 'localized_files_pkey'
}

/** input type for inserting data into table "localized_files" */
export type Localized_Files_Insert_Input = {
  admin_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  firebase_project?: Maybe<Firebase_Projects_Obj_Rel_Insert_Input>;
  firebase_project_id?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Localized_Files_Max_Fields = {
  __typename?: 'localized_files_max_fields';
  admin_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "localized_files" */
export type Localized_Files_Max_Order_By = {
  admin_description?: Maybe<Order_By>;
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  default?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Localized_Files_Min_Fields = {
  __typename?: 'localized_files_min_fields';
  admin_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "localized_files" */
export type Localized_Files_Min_Order_By = {
  admin_description?: Maybe<Order_By>;
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  default?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "localized_files" */
export type Localized_Files_Mutation_Response = {
  __typename?: 'localized_files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Localized_Files>;
};

/** input type for inserting object relation for remote table "localized_files" */
export type Localized_Files_Obj_Rel_Insert_Input = {
  data: Localized_Files_Insert_Input;
  on_conflict?: Maybe<Localized_Files_On_Conflict>;
};

/** on conflict condition type for table "localized_files" */
export type Localized_Files_On_Conflict = {
  constraint: Localized_Files_Constraint;
  update_columns: Array<Localized_Files_Update_Column>;
  where?: Maybe<Localized_Files_Bool_Exp>;
};

/** ordering options when selecting data from "localized_files" */
export type Localized_Files_Order_By = {
  admin_description?: Maybe<Order_By>;
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  default?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  firebase_project?: Maybe<Firebase_Projects_Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "localized_files" */
export type Localized_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "localized_files" */
export enum Localized_Files_Select_Column {
  /** column name */
  AdminDescription = 'admin_description',
  /** column name */
  De = 'de',
  /** column name */
  DeAt = 'de_AT',
  /** column name */
  DeCh = 'de_CH',
  /** column name */
  Default = 'default',
  /** column name */
  EnGb = 'en_GB',
  /** column name */
  FirebaseProjectId = 'firebase_project_id',
  /** column name */
  FrCh = 'fr_CH',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "localized_files" */
export type Localized_Files_Set_Input = {
  admin_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "localized_files" */
export enum Localized_Files_Update_Column {
  /** column name */
  AdminDescription = 'admin_description',
  /** column name */
  De = 'de',
  /** column name */
  DeAt = 'de_AT',
  /** column name */
  DeCh = 'de_CH',
  /** column name */
  Default = 'default',
  /** column name */
  EnGb = 'en_GB',
  /** column name */
  FirebaseProjectId = 'firebase_project_id',
  /** column name */
  FrCh = 'fr_CH',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "microsites" */
export type Microsites = {
  __typename?: 'microsites';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  events: Array<Events_Microsites>;
  /** An aggregated array relationship */
  events_aggregate: Events_Microsites_Aggregate;
  /** An object relationship */
  firebase_project?: Maybe<Firebase_Projects>;
  firebase_project_id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  logo?: Maybe<Scalars['String']>;
  /** An object relationship */
  logo_localized?: Maybe<Localized_Files>;
  logo_localized_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An object relationship */
  ui_theme?: Maybe<Ui_Themes>;
  ui_theme_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  vercel_project?: Maybe<Vercel_Projects>;
  vercel_project_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "microsites" */
export type MicrositesEventsArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** columns and relationships of "microsites" */
export type MicrositesEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};

/** aggregated selection of "microsites" */
export type Microsites_Aggregate = {
  __typename?: 'microsites_aggregate';
  aggregate?: Maybe<Microsites_Aggregate_Fields>;
  nodes: Array<Microsites>;
};

/** aggregate fields of "microsites" */
export type Microsites_Aggregate_Fields = {
  __typename?: 'microsites_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Microsites_Max_Fields>;
  min?: Maybe<Microsites_Min_Fields>;
};


/** aggregate fields of "microsites" */
export type Microsites_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Microsites_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "microsites" */
export type Microsites_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Microsites_Max_Order_By>;
  min?: Maybe<Microsites_Min_Order_By>;
};

/** input type for inserting array relation for remote table "microsites" */
export type Microsites_Arr_Rel_Insert_Input = {
  data: Array<Microsites_Insert_Input>;
  on_conflict?: Maybe<Microsites_On_Conflict>;
};

/** Boolean expression to filter rows from the table "microsites". All fields are combined with a logical 'AND'. */
export type Microsites_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Microsites_Bool_Exp>>>;
  _not?: Maybe<Microsites_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Microsites_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  events?: Maybe<Events_Microsites_Bool_Exp>;
  firebase_project?: Maybe<Firebase_Projects_Bool_Exp>;
  firebase_project_id?: Maybe<String_Comparison_Exp>;
  hostname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo?: Maybe<String_Comparison_Exp>;
  logo_localized?: Maybe<Localized_Files_Bool_Exp>;
  logo_localized_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  ui_theme?: Maybe<Ui_Themes_Bool_Exp>;
  ui_theme_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  vercel_project?: Maybe<Vercel_Projects_Bool_Exp>;
  vercel_project_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "microsites" */
export enum Microsites_Constraint {
  /** unique or primary key constraint */
  MicrositesNameKey = 'microsites_name_key',
  /** unique or primary key constraint */
  MicrositesPkey = 'microsites_pkey',
  /** unique or primary key constraint */
  MicrositesVercelProjectIdKey = 'microsites_vercel_project_id_key'
}

/** input type for inserting data into table "microsites" */
export type Microsites_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  events?: Maybe<Events_Microsites_Arr_Rel_Insert_Input>;
  firebase_project?: Maybe<Firebase_Projects_Obj_Rel_Insert_Input>;
  firebase_project_id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_localized?: Maybe<Localized_Files_Obj_Rel_Insert_Input>;
  logo_localized_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ui_theme?: Maybe<Ui_Themes_Obj_Rel_Insert_Input>;
  ui_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vercel_project?: Maybe<Vercel_Projects_Obj_Rel_Insert_Input>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Microsites_Max_Fields = {
  __typename?: 'microsites_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_localized_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ui_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "microsites" */
export type Microsites_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_localized_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ui_theme_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vercel_project_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Microsites_Min_Fields = {
  __typename?: 'microsites_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_localized_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ui_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "microsites" */
export type Microsites_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_localized_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ui_theme_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vercel_project_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "microsites" */
export type Microsites_Mutation_Response = {
  __typename?: 'microsites_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Microsites>;
};

/** input type for inserting object relation for remote table "microsites" */
export type Microsites_Obj_Rel_Insert_Input = {
  data: Microsites_Insert_Input;
  on_conflict?: Maybe<Microsites_On_Conflict>;
};

/** on conflict condition type for table "microsites" */
export type Microsites_On_Conflict = {
  constraint: Microsites_Constraint;
  update_columns: Array<Microsites_Update_Column>;
  where?: Maybe<Microsites_Bool_Exp>;
};

/** ordering options when selecting data from "microsites" */
export type Microsites_Order_By = {
  created_at?: Maybe<Order_By>;
  events_aggregate?: Maybe<Events_Microsites_Aggregate_Order_By>;
  firebase_project?: Maybe<Firebase_Projects_Order_By>;
  firebase_project_id?: Maybe<Order_By>;
  hostname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo?: Maybe<Order_By>;
  logo_localized?: Maybe<Localized_Files_Order_By>;
  logo_localized_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  ui_theme?: Maybe<Ui_Themes_Order_By>;
  ui_theme_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  vercel_project?: Maybe<Vercel_Projects_Order_By>;
  vercel_project_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "microsites" */
export type Microsites_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "microsites" */
export enum Microsites_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirebaseProjectId = 'firebase_project_id',
  /** column name */
  Hostname = 'hostname',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoLocalizedId = 'logo_localized_id',
  /** column name */
  Name = 'name',
  /** column name */
  UiThemeId = 'ui_theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VercelProjectId = 'vercel_project_id'
}

/** input type for updating data in table "microsites" */
export type Microsites_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  firebase_project_id?: Maybe<Scalars['String']>;
  hostname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo?: Maybe<Scalars['String']>;
  logo_localized_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ui_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** update columns of table "microsites" */
export enum Microsites_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirebaseProjectId = 'firebase_project_id',
  /** column name */
  Hostname = 'hostname',
  /** column name */
  Id = 'id',
  /** column name */
  Logo = 'logo',
  /** column name */
  LogoLocalizedId = 'logo_localized_id',
  /** column name */
  Name = 'name',
  /** column name */
  UiThemeId = 'ui_theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VercelProjectId = 'vercel_project_id'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "accreditation_types" */
  delete_accreditation_types?: Maybe<Accreditation_Types_Mutation_Response>;
  /** delete single row from the table: "accreditation_types" */
  delete_accreditation_types_by_pk?: Maybe<Accreditation_Types>;
  /** delete data from the table: "companies" */
  delete_companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "companies" */
  delete_companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "company_departments" */
  delete_company_departments?: Maybe<Company_Departments_Mutation_Response>;
  /** delete single row from the table: "company_departments" */
  delete_company_departments_by_pk?: Maybe<Company_Departments>;
  /** delete data from the table: "company_departments_users" */
  delete_company_departments_users?: Maybe<Company_Departments_Users_Mutation_Response>;
  /** delete single row from the table: "company_departments_users" */
  delete_company_departments_users_by_pk?: Maybe<Company_Departments_Users>;
  /** delete data from the table: "content_types" */
  delete_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** delete single row from the table: "content_types" */
  delete_content_types_by_pk?: Maybe<Content_Types>;
  /** delete data from the table: "event_content" */
  delete_event_content?: Maybe<Event_Content_Mutation_Response>;
  /** delete single row from the table: "event_content" */
  delete_event_content_by_pk?: Maybe<Event_Content>;
  /** delete data from the table: "event_locations" */
  delete_event_locations?: Maybe<Event_Locations_Mutation_Response>;
  /** delete single row from the table: "event_locations" */
  delete_event_locations_by_pk?: Maybe<Event_Locations>;
  /** delete data from the table: "event_schedule_accreditation_points" */
  delete_event_schedule_accreditation_points?: Maybe<Event_Schedule_Accreditation_Points_Mutation_Response>;
  /** delete single row from the table: "event_schedule_accreditation_points" */
  delete_event_schedule_accreditation_points_by_pk?: Maybe<Event_Schedule_Accreditation_Points>;
  /** delete data from the table: "event_schedule_types" */
  delete_event_schedule_types?: Maybe<Event_Schedule_Types_Mutation_Response>;
  /** delete single row from the table: "event_schedule_types" */
  delete_event_schedule_types_by_pk?: Maybe<Event_Schedule_Types>;
  /** delete data from the table: "event_schedules" */
  delete_event_schedules?: Maybe<Event_Schedules_Mutation_Response>;
  /** delete single row from the table: "event_schedules" */
  delete_event_schedules_by_pk?: Maybe<Event_Schedules>;
  /** delete data from the table: "event_schedules_speakers" */
  delete_event_schedules_speakers?: Maybe<Event_Schedules_Speakers_Mutation_Response>;
  /** delete single row from the table: "event_schedules_speakers" */
  delete_event_schedules_speakers_by_pk?: Maybe<Event_Schedules_Speakers>;
  /** delete data from the table: "event_sponsoring_types" */
  delete_event_sponsoring_types?: Maybe<Event_Sponsoring_Types_Mutation_Response>;
  /** delete single row from the table: "event_sponsoring_types" */
  delete_event_sponsoring_types_by_pk?: Maybe<Event_Sponsoring_Types>;
  /** delete data from the table: "event_sponsorings" */
  delete_event_sponsorings?: Maybe<Event_Sponsorings_Mutation_Response>;
  /** delete single row from the table: "event_sponsorings" */
  delete_event_sponsorings_by_pk?: Maybe<Event_Sponsorings>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "events_microsites" */
  delete_events_microsites?: Maybe<Events_Microsites_Mutation_Response>;
  /** delete single row from the table: "events_microsites" */
  delete_events_microsites_by_pk?: Maybe<Events_Microsites>;
  /** delete data from the table: "firebase_projects" */
  delete_firebase_projects?: Maybe<Firebase_Projects_Mutation_Response>;
  /** delete single row from the table: "firebase_projects" */
  delete_firebase_projects_by_pk?: Maybe<Firebase_Projects>;
  /** delete data from the table: "livestream_sources" */
  delete_livestream_sources?: Maybe<Livestream_Sources_Mutation_Response>;
  /** delete single row from the table: "livestream_sources" */
  delete_livestream_sources_by_pk?: Maybe<Livestream_Sources>;
  /** delete data from the table: "locale" */
  delete_locale?: Maybe<Locale_Mutation_Response>;
  /** delete single row from the table: "locale" */
  delete_locale_by_pk?: Maybe<Locale>;
  /** delete data from the table: "localized_files" */
  delete_localized_files?: Maybe<Localized_Files_Mutation_Response>;
  /** delete single row from the table: "localized_files" */
  delete_localized_files_by_pk?: Maybe<Localized_Files>;
  /** delete data from the table: "microsites" */
  delete_microsites?: Maybe<Microsites_Mutation_Response>;
  /** delete single row from the table: "microsites" */
  delete_microsites_by_pk?: Maybe<Microsites>;
  /** delete data from the table: "ondemand_sources" */
  delete_ondemand_sources?: Maybe<Ondemand_Sources_Mutation_Response>;
  /** delete single row from the table: "ondemand_sources" */
  delete_ondemand_sources_by_pk?: Maybe<Ondemand_Sources>;
  /** delete data from the table: "speakers" */
  delete_speakers?: Maybe<Speakers_Mutation_Response>;
  /** delete single row from the table: "speakers" */
  delete_speakers_by_pk?: Maybe<Speakers>;
  /** delete data from the table: "string_translations" */
  delete_string_translations?: Maybe<String_Translations_Mutation_Response>;
  /** delete single row from the table: "string_translations" */
  delete_string_translations_by_pk?: Maybe<String_Translations>;
  /** delete data from the table: "ui_themes" */
  delete_ui_themes?: Maybe<Ui_Themes_Mutation_Response>;
  /** delete single row from the table: "ui_themes" */
  delete_ui_themes_by_pk?: Maybe<Ui_Themes>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "vercel_projects" */
  delete_vercel_projects?: Maybe<Vercel_Projects_Mutation_Response>;
  /** delete single row from the table: "vercel_projects" */
  delete_vercel_projects_by_pk?: Maybe<Vercel_Projects>;
  /** insert data into the table: "accreditation_types" */
  insert_accreditation_types?: Maybe<Accreditation_Types_Mutation_Response>;
  /** insert a single row into the table: "accreditation_types" */
  insert_accreditation_types_one?: Maybe<Accreditation_Types>;
  /** insert data into the table: "companies" */
  insert_companies?: Maybe<Companies_Mutation_Response>;
  /** insert a single row into the table: "companies" */
  insert_companies_one?: Maybe<Companies>;
  /** insert data into the table: "company_departments" */
  insert_company_departments?: Maybe<Company_Departments_Mutation_Response>;
  /** insert a single row into the table: "company_departments" */
  insert_company_departments_one?: Maybe<Company_Departments>;
  /** insert data into the table: "company_departments_users" */
  insert_company_departments_users?: Maybe<Company_Departments_Users_Mutation_Response>;
  /** insert a single row into the table: "company_departments_users" */
  insert_company_departments_users_one?: Maybe<Company_Departments_Users>;
  /** insert data into the table: "content_types" */
  insert_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** insert a single row into the table: "content_types" */
  insert_content_types_one?: Maybe<Content_Types>;
  /** insert data into the table: "event_content" */
  insert_event_content?: Maybe<Event_Content_Mutation_Response>;
  /** insert a single row into the table: "event_content" */
  insert_event_content_one?: Maybe<Event_Content>;
  /** insert data into the table: "event_locations" */
  insert_event_locations?: Maybe<Event_Locations_Mutation_Response>;
  /** insert a single row into the table: "event_locations" */
  insert_event_locations_one?: Maybe<Event_Locations>;
  /** insert data into the table: "event_schedule_accreditation_points" */
  insert_event_schedule_accreditation_points?: Maybe<Event_Schedule_Accreditation_Points_Mutation_Response>;
  /** insert a single row into the table: "event_schedule_accreditation_points" */
  insert_event_schedule_accreditation_points_one?: Maybe<Event_Schedule_Accreditation_Points>;
  /** insert data into the table: "event_schedule_types" */
  insert_event_schedule_types?: Maybe<Event_Schedule_Types_Mutation_Response>;
  /** insert a single row into the table: "event_schedule_types" */
  insert_event_schedule_types_one?: Maybe<Event_Schedule_Types>;
  /** insert data into the table: "event_schedules" */
  insert_event_schedules?: Maybe<Event_Schedules_Mutation_Response>;
  /** insert a single row into the table: "event_schedules" */
  insert_event_schedules_one?: Maybe<Event_Schedules>;
  /** insert data into the table: "event_schedules_speakers" */
  insert_event_schedules_speakers?: Maybe<Event_Schedules_Speakers_Mutation_Response>;
  /** insert a single row into the table: "event_schedules_speakers" */
  insert_event_schedules_speakers_one?: Maybe<Event_Schedules_Speakers>;
  /** insert data into the table: "event_sponsoring_types" */
  insert_event_sponsoring_types?: Maybe<Event_Sponsoring_Types_Mutation_Response>;
  /** insert a single row into the table: "event_sponsoring_types" */
  insert_event_sponsoring_types_one?: Maybe<Event_Sponsoring_Types>;
  /** insert data into the table: "event_sponsorings" */
  insert_event_sponsorings?: Maybe<Event_Sponsorings_Mutation_Response>;
  /** insert a single row into the table: "event_sponsorings" */
  insert_event_sponsorings_one?: Maybe<Event_Sponsorings>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert data into the table: "events_microsites" */
  insert_events_microsites?: Maybe<Events_Microsites_Mutation_Response>;
  /** insert a single row into the table: "events_microsites" */
  insert_events_microsites_one?: Maybe<Events_Microsites>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "firebase_projects" */
  insert_firebase_projects?: Maybe<Firebase_Projects_Mutation_Response>;
  /** insert a single row into the table: "firebase_projects" */
  insert_firebase_projects_one?: Maybe<Firebase_Projects>;
  /** insert data into the table: "livestream_sources" */
  insert_livestream_sources?: Maybe<Livestream_Sources_Mutation_Response>;
  /** insert a single row into the table: "livestream_sources" */
  insert_livestream_sources_one?: Maybe<Livestream_Sources>;
  /** insert data into the table: "locale" */
  insert_locale?: Maybe<Locale_Mutation_Response>;
  /** insert a single row into the table: "locale" */
  insert_locale_one?: Maybe<Locale>;
  /** insert data into the table: "localized_files" */
  insert_localized_files?: Maybe<Localized_Files_Mutation_Response>;
  /** insert a single row into the table: "localized_files" */
  insert_localized_files_one?: Maybe<Localized_Files>;
  /** insert data into the table: "microsites" */
  insert_microsites?: Maybe<Microsites_Mutation_Response>;
  /** insert a single row into the table: "microsites" */
  insert_microsites_one?: Maybe<Microsites>;
  /** insert data into the table: "ondemand_sources" */
  insert_ondemand_sources?: Maybe<Ondemand_Sources_Mutation_Response>;
  /** insert a single row into the table: "ondemand_sources" */
  insert_ondemand_sources_one?: Maybe<Ondemand_Sources>;
  /** insert data into the table: "speakers" */
  insert_speakers?: Maybe<Speakers_Mutation_Response>;
  /** insert a single row into the table: "speakers" */
  insert_speakers_one?: Maybe<Speakers>;
  /** insert data into the table: "string_translations" */
  insert_string_translations?: Maybe<String_Translations_Mutation_Response>;
  /** insert a single row into the table: "string_translations" */
  insert_string_translations_one?: Maybe<String_Translations>;
  /** insert data into the table: "ui_themes" */
  insert_ui_themes?: Maybe<Ui_Themes_Mutation_Response>;
  /** insert a single row into the table: "ui_themes" */
  insert_ui_themes_one?: Maybe<Ui_Themes>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "vercel_projects" */
  insert_vercel_projects?: Maybe<Vercel_Projects_Mutation_Response>;
  /** insert a single row into the table: "vercel_projects" */
  insert_vercel_projects_one?: Maybe<Vercel_Projects>;
  /** update data of the table: "accreditation_types" */
  update_accreditation_types?: Maybe<Accreditation_Types_Mutation_Response>;
  /** update single row of the table: "accreditation_types" */
  update_accreditation_types_by_pk?: Maybe<Accreditation_Types>;
  /** update data of the table: "companies" */
  update_companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "companies" */
  update_companies_by_pk?: Maybe<Companies>;
  /** update data of the table: "company_departments" */
  update_company_departments?: Maybe<Company_Departments_Mutation_Response>;
  /** update single row of the table: "company_departments" */
  update_company_departments_by_pk?: Maybe<Company_Departments>;
  /** update data of the table: "company_departments_users" */
  update_company_departments_users?: Maybe<Company_Departments_Users_Mutation_Response>;
  /** update single row of the table: "company_departments_users" */
  update_company_departments_users_by_pk?: Maybe<Company_Departments_Users>;
  /** update data of the table: "content_types" */
  update_content_types?: Maybe<Content_Types_Mutation_Response>;
  /** update single row of the table: "content_types" */
  update_content_types_by_pk?: Maybe<Content_Types>;
  /** update data of the table: "event_content" */
  update_event_content?: Maybe<Event_Content_Mutation_Response>;
  /** update single row of the table: "event_content" */
  update_event_content_by_pk?: Maybe<Event_Content>;
  /** update data of the table: "event_locations" */
  update_event_locations?: Maybe<Event_Locations_Mutation_Response>;
  /** update single row of the table: "event_locations" */
  update_event_locations_by_pk?: Maybe<Event_Locations>;
  /** update data of the table: "event_schedule_accreditation_points" */
  update_event_schedule_accreditation_points?: Maybe<Event_Schedule_Accreditation_Points_Mutation_Response>;
  /** update single row of the table: "event_schedule_accreditation_points" */
  update_event_schedule_accreditation_points_by_pk?: Maybe<Event_Schedule_Accreditation_Points>;
  /** update data of the table: "event_schedule_types" */
  update_event_schedule_types?: Maybe<Event_Schedule_Types_Mutation_Response>;
  /** update single row of the table: "event_schedule_types" */
  update_event_schedule_types_by_pk?: Maybe<Event_Schedule_Types>;
  /** update data of the table: "event_schedules" */
  update_event_schedules?: Maybe<Event_Schedules_Mutation_Response>;
  /** update single row of the table: "event_schedules" */
  update_event_schedules_by_pk?: Maybe<Event_Schedules>;
  /** update data of the table: "event_schedules_speakers" */
  update_event_schedules_speakers?: Maybe<Event_Schedules_Speakers_Mutation_Response>;
  /** update single row of the table: "event_schedules_speakers" */
  update_event_schedules_speakers_by_pk?: Maybe<Event_Schedules_Speakers>;
  /** update data of the table: "event_sponsoring_types" */
  update_event_sponsoring_types?: Maybe<Event_Sponsoring_Types_Mutation_Response>;
  /** update single row of the table: "event_sponsoring_types" */
  update_event_sponsoring_types_by_pk?: Maybe<Event_Sponsoring_Types>;
  /** update data of the table: "event_sponsorings" */
  update_event_sponsorings?: Maybe<Event_Sponsorings_Mutation_Response>;
  /** update single row of the table: "event_sponsorings" */
  update_event_sponsorings_by_pk?: Maybe<Event_Sponsorings>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update data of the table: "events_microsites" */
  update_events_microsites?: Maybe<Events_Microsites_Mutation_Response>;
  /** update single row of the table: "events_microsites" */
  update_events_microsites_by_pk?: Maybe<Events_Microsites>;
  /** update data of the table: "firebase_projects" */
  update_firebase_projects?: Maybe<Firebase_Projects_Mutation_Response>;
  /** update single row of the table: "firebase_projects" */
  update_firebase_projects_by_pk?: Maybe<Firebase_Projects>;
  /** update data of the table: "livestream_sources" */
  update_livestream_sources?: Maybe<Livestream_Sources_Mutation_Response>;
  /** update single row of the table: "livestream_sources" */
  update_livestream_sources_by_pk?: Maybe<Livestream_Sources>;
  /** update data of the table: "locale" */
  update_locale?: Maybe<Locale_Mutation_Response>;
  /** update single row of the table: "locale" */
  update_locale_by_pk?: Maybe<Locale>;
  /** update data of the table: "localized_files" */
  update_localized_files?: Maybe<Localized_Files_Mutation_Response>;
  /** update single row of the table: "localized_files" */
  update_localized_files_by_pk?: Maybe<Localized_Files>;
  /** update data of the table: "microsites" */
  update_microsites?: Maybe<Microsites_Mutation_Response>;
  /** update single row of the table: "microsites" */
  update_microsites_by_pk?: Maybe<Microsites>;
  /** update data of the table: "ondemand_sources" */
  update_ondemand_sources?: Maybe<Ondemand_Sources_Mutation_Response>;
  /** update single row of the table: "ondemand_sources" */
  update_ondemand_sources_by_pk?: Maybe<Ondemand_Sources>;
  /** update data of the table: "speakers" */
  update_speakers?: Maybe<Speakers_Mutation_Response>;
  /** update single row of the table: "speakers" */
  update_speakers_by_pk?: Maybe<Speakers>;
  /** update data of the table: "string_translations" */
  update_string_translations?: Maybe<String_Translations_Mutation_Response>;
  /** update single row of the table: "string_translations" */
  update_string_translations_by_pk?: Maybe<String_Translations>;
  /** update data of the table: "ui_themes" */
  update_ui_themes?: Maybe<Ui_Themes_Mutation_Response>;
  /** update single row of the table: "ui_themes" */
  update_ui_themes_by_pk?: Maybe<Ui_Themes>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "vercel_projects" */
  update_vercel_projects?: Maybe<Vercel_Projects_Mutation_Response>;
  /** update single row of the table: "vercel_projects" */
  update_vercel_projects_by_pk?: Maybe<Vercel_Projects>;
};


/** mutation root */
export type Mutation_RootDelete_Accreditation_TypesArgs = {
  where: Accreditation_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accreditation_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_DepartmentsArgs = {
  where: Company_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Departments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Company_Departments_UsersArgs = {
  where: Company_Departments_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Company_Departments_Users_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Content_TypesArgs = {
  where: Content_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Content_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_ContentArgs = {
  where: Event_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Content_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_LocationsArgs = {
  where: Event_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedule_Accreditation_PointsArgs = {
  where: Event_Schedule_Accreditation_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedule_Accreditation_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedule_TypesArgs = {
  where: Event_Schedule_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedule_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_SchedulesArgs = {
  where: Event_Schedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedules_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedules_SpeakersArgs = {
  where: Event_Schedules_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Schedules_Speakers_By_PkArgs = {
  event_schedule_id: Scalars['uuid'];
  speaker_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Event_Sponsoring_TypesArgs = {
  where: Event_Sponsoring_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Sponsoring_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Event_SponsoringsArgs = {
  where: Event_Sponsorings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Event_Sponsorings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Events_MicrositesArgs = {
  where: Events_Microsites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_Microsites_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Firebase_ProjectsArgs = {
  where: Firebase_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Firebase_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Livestream_SourcesArgs = {
  where: Livestream_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Livestream_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LocaleArgs = {
  where: Locale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Locale_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Localized_FilesArgs = {
  where: Localized_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Localized_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MicrositesArgs = {
  where: Microsites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Microsites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Ondemand_SourcesArgs = {
  where: Ondemand_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ondemand_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SpeakersArgs = {
  where: Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Speakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_String_TranslationsArgs = {
  where: String_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_String_Translations_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Ui_ThemesArgs = {
  where: Ui_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ui_Themes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Vercel_ProjectsArgs = {
  where: Vercel_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Vercel_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Accreditation_TypesArgs = {
  objects: Array<Accreditation_Types_Insert_Input>;
  on_conflict?: Maybe<Accreditation_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accreditation_Types_OneArgs = {
  object: Accreditation_Types_Insert_Input;
  on_conflict?: Maybe<Accreditation_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_DepartmentsArgs = {
  objects: Array<Company_Departments_Insert_Input>;
  on_conflict?: Maybe<Company_Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Departments_OneArgs = {
  object: Company_Departments_Insert_Input;
  on_conflict?: Maybe<Company_Departments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Departments_UsersArgs = {
  objects: Array<Company_Departments_Users_Insert_Input>;
  on_conflict?: Maybe<Company_Departments_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Company_Departments_Users_OneArgs = {
  object: Company_Departments_Users_Insert_Input;
  on_conflict?: Maybe<Company_Departments_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_TypesArgs = {
  objects: Array<Content_Types_Insert_Input>;
  on_conflict?: Maybe<Content_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Content_Types_OneArgs = {
  object: Content_Types_Insert_Input;
  on_conflict?: Maybe<Content_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_ContentArgs = {
  objects: Array<Event_Content_Insert_Input>;
  on_conflict?: Maybe<Event_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Content_OneArgs = {
  object: Event_Content_Insert_Input;
  on_conflict?: Maybe<Event_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_LocationsArgs = {
  objects: Array<Event_Locations_Insert_Input>;
  on_conflict?: Maybe<Event_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Locations_OneArgs = {
  object: Event_Locations_Insert_Input;
  on_conflict?: Maybe<Event_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedule_Accreditation_PointsArgs = {
  objects: Array<Event_Schedule_Accreditation_Points_Insert_Input>;
  on_conflict?: Maybe<Event_Schedule_Accreditation_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedule_Accreditation_Points_OneArgs = {
  object: Event_Schedule_Accreditation_Points_Insert_Input;
  on_conflict?: Maybe<Event_Schedule_Accreditation_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedule_TypesArgs = {
  objects: Array<Event_Schedule_Types_Insert_Input>;
  on_conflict?: Maybe<Event_Schedule_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedule_Types_OneArgs = {
  object: Event_Schedule_Types_Insert_Input;
  on_conflict?: Maybe<Event_Schedule_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_SchedulesArgs = {
  objects: Array<Event_Schedules_Insert_Input>;
  on_conflict?: Maybe<Event_Schedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedules_OneArgs = {
  object: Event_Schedules_Insert_Input;
  on_conflict?: Maybe<Event_Schedules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedules_SpeakersArgs = {
  objects: Array<Event_Schedules_Speakers_Insert_Input>;
  on_conflict?: Maybe<Event_Schedules_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Schedules_Speakers_OneArgs = {
  object: Event_Schedules_Speakers_Insert_Input;
  on_conflict?: Maybe<Event_Schedules_Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Sponsoring_TypesArgs = {
  objects: Array<Event_Sponsoring_Types_Insert_Input>;
  on_conflict?: Maybe<Event_Sponsoring_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Sponsoring_Types_OneArgs = {
  object: Event_Sponsoring_Types_Insert_Input;
  on_conflict?: Maybe<Event_Sponsoring_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_SponsoringsArgs = {
  objects: Array<Event_Sponsorings_Insert_Input>;
  on_conflict?: Maybe<Event_Sponsorings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Event_Sponsorings_OneArgs = {
  object: Event_Sponsorings_Insert_Input;
  on_conflict?: Maybe<Event_Sponsorings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: Maybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_MicrositesArgs = {
  objects: Array<Events_Microsites_Insert_Input>;
  on_conflict?: Maybe<Events_Microsites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_Microsites_OneArgs = {
  object: Events_Microsites_Insert_Input;
  on_conflict?: Maybe<Events_Microsites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: Maybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Firebase_ProjectsArgs = {
  objects: Array<Firebase_Projects_Insert_Input>;
  on_conflict?: Maybe<Firebase_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Firebase_Projects_OneArgs = {
  object: Firebase_Projects_Insert_Input;
  on_conflict?: Maybe<Firebase_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Livestream_SourcesArgs = {
  objects: Array<Livestream_Sources_Insert_Input>;
  on_conflict?: Maybe<Livestream_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Livestream_Sources_OneArgs = {
  object: Livestream_Sources_Insert_Input;
  on_conflict?: Maybe<Livestream_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LocaleArgs = {
  objects: Array<Locale_Insert_Input>;
  on_conflict?: Maybe<Locale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Locale_OneArgs = {
  object: Locale_Insert_Input;
  on_conflict?: Maybe<Locale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Localized_FilesArgs = {
  objects: Array<Localized_Files_Insert_Input>;
  on_conflict?: Maybe<Localized_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Localized_Files_OneArgs = {
  object: Localized_Files_Insert_Input;
  on_conflict?: Maybe<Localized_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MicrositesArgs = {
  objects: Array<Microsites_Insert_Input>;
  on_conflict?: Maybe<Microsites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Microsites_OneArgs = {
  object: Microsites_Insert_Input;
  on_conflict?: Maybe<Microsites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ondemand_SourcesArgs = {
  objects: Array<Ondemand_Sources_Insert_Input>;
  on_conflict?: Maybe<Ondemand_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ondemand_Sources_OneArgs = {
  object: Ondemand_Sources_Insert_Input;
  on_conflict?: Maybe<Ondemand_Sources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SpeakersArgs = {
  objects: Array<Speakers_Insert_Input>;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Speakers_OneArgs = {
  object: Speakers_Insert_Input;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_String_TranslationsArgs = {
  objects: Array<String_Translations_Insert_Input>;
  on_conflict?: Maybe<String_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_String_Translations_OneArgs = {
  object: String_Translations_Insert_Input;
  on_conflict?: Maybe<String_Translations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ui_ThemesArgs = {
  objects: Array<Ui_Themes_Insert_Input>;
  on_conflict?: Maybe<Ui_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ui_Themes_OneArgs = {
  object: Ui_Themes_Insert_Input;
  on_conflict?: Maybe<Ui_Themes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vercel_ProjectsArgs = {
  objects: Array<Vercel_Projects_Insert_Input>;
  on_conflict?: Maybe<Vercel_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Vercel_Projects_OneArgs = {
  object: Vercel_Projects_Insert_Input;
  on_conflict?: Maybe<Vercel_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Accreditation_TypesArgs = {
  _set?: Maybe<Accreditation_Types_Set_Input>;
  where: Accreditation_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accreditation_Types_By_PkArgs = {
  _set?: Maybe<Accreditation_Types_Set_Input>;
  pk_columns: Accreditation_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _set?: Maybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _set?: Maybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_DepartmentsArgs = {
  _set?: Maybe<Company_Departments_Set_Input>;
  where: Company_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Departments_By_PkArgs = {
  _set?: Maybe<Company_Departments_Set_Input>;
  pk_columns: Company_Departments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Departments_UsersArgs = {
  _set?: Maybe<Company_Departments_Users_Set_Input>;
  where: Company_Departments_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Company_Departments_Users_By_PkArgs = {
  _set?: Maybe<Company_Departments_Users_Set_Input>;
  pk_columns: Company_Departments_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Content_TypesArgs = {
  _set?: Maybe<Content_Types_Set_Input>;
  where: Content_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Content_Types_By_PkArgs = {
  _set?: Maybe<Content_Types_Set_Input>;
  pk_columns: Content_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_ContentArgs = {
  _append?: Maybe<Event_Content_Append_Input>;
  _delete_at_path?: Maybe<Event_Content_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Content_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Content_Delete_Key_Input>;
  _prepend?: Maybe<Event_Content_Prepend_Input>;
  _set?: Maybe<Event_Content_Set_Input>;
  where: Event_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Content_By_PkArgs = {
  _append?: Maybe<Event_Content_Append_Input>;
  _delete_at_path?: Maybe<Event_Content_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Content_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Content_Delete_Key_Input>;
  _prepend?: Maybe<Event_Content_Prepend_Input>;
  _set?: Maybe<Event_Content_Set_Input>;
  pk_columns: Event_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_LocationsArgs = {
  _set?: Maybe<Event_Locations_Set_Input>;
  where: Event_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Locations_By_PkArgs = {
  _set?: Maybe<Event_Locations_Set_Input>;
  pk_columns: Event_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedule_Accreditation_PointsArgs = {
  _inc?: Maybe<Event_Schedule_Accreditation_Points_Inc_Input>;
  _set?: Maybe<Event_Schedule_Accreditation_Points_Set_Input>;
  where: Event_Schedule_Accreditation_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedule_Accreditation_Points_By_PkArgs = {
  _inc?: Maybe<Event_Schedule_Accreditation_Points_Inc_Input>;
  _set?: Maybe<Event_Schedule_Accreditation_Points_Set_Input>;
  pk_columns: Event_Schedule_Accreditation_Points_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedule_TypesArgs = {
  _set?: Maybe<Event_Schedule_Types_Set_Input>;
  where: Event_Schedule_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedule_Types_By_PkArgs = {
  _set?: Maybe<Event_Schedule_Types_Set_Input>;
  pk_columns: Event_Schedule_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_SchedulesArgs = {
  _inc?: Maybe<Event_Schedules_Inc_Input>;
  _set?: Maybe<Event_Schedules_Set_Input>;
  where: Event_Schedules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedules_By_PkArgs = {
  _inc?: Maybe<Event_Schedules_Inc_Input>;
  _set?: Maybe<Event_Schedules_Set_Input>;
  pk_columns: Event_Schedules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedules_SpeakersArgs = {
  _set?: Maybe<Event_Schedules_Speakers_Set_Input>;
  where: Event_Schedules_Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Schedules_Speakers_By_PkArgs = {
  _set?: Maybe<Event_Schedules_Speakers_Set_Input>;
  pk_columns: Event_Schedules_Speakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sponsoring_TypesArgs = {
  _set?: Maybe<Event_Sponsoring_Types_Set_Input>;
  where: Event_Sponsoring_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sponsoring_Types_By_PkArgs = {
  _set?: Maybe<Event_Sponsoring_Types_Set_Input>;
  pk_columns: Event_Sponsoring_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Event_SponsoringsArgs = {
  _append?: Maybe<Event_Sponsorings_Append_Input>;
  _delete_at_path?: Maybe<Event_Sponsorings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Sponsorings_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Sponsorings_Delete_Key_Input>;
  _prepend?: Maybe<Event_Sponsorings_Prepend_Input>;
  _set?: Maybe<Event_Sponsorings_Set_Input>;
  where: Event_Sponsorings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Event_Sponsorings_By_PkArgs = {
  _append?: Maybe<Event_Sponsorings_Append_Input>;
  _delete_at_path?: Maybe<Event_Sponsorings_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Event_Sponsorings_Delete_Elem_Input>;
  _delete_key?: Maybe<Event_Sponsorings_Delete_Key_Input>;
  _prepend?: Maybe<Event_Sponsorings_Prepend_Input>;
  _set?: Maybe<Event_Sponsorings_Set_Input>;
  pk_columns: Event_Sponsorings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _inc?: Maybe<Events_Inc_Input>;
  _set?: Maybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _inc?: Maybe<Events_Inc_Input>;
  _set?: Maybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_MicrositesArgs = {
  _inc?: Maybe<Events_Microsites_Inc_Input>;
  _set?: Maybe<Events_Microsites_Set_Input>;
  where: Events_Microsites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_Microsites_By_PkArgs = {
  _inc?: Maybe<Events_Microsites_Inc_Input>;
  _set?: Maybe<Events_Microsites_Set_Input>;
  pk_columns: Events_Microsites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Firebase_ProjectsArgs = {
  _append?: Maybe<Firebase_Projects_Append_Input>;
  _delete_at_path?: Maybe<Firebase_Projects_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Firebase_Projects_Delete_Elem_Input>;
  _delete_key?: Maybe<Firebase_Projects_Delete_Key_Input>;
  _prepend?: Maybe<Firebase_Projects_Prepend_Input>;
  _set?: Maybe<Firebase_Projects_Set_Input>;
  where: Firebase_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Firebase_Projects_By_PkArgs = {
  _append?: Maybe<Firebase_Projects_Append_Input>;
  _delete_at_path?: Maybe<Firebase_Projects_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Firebase_Projects_Delete_Elem_Input>;
  _delete_key?: Maybe<Firebase_Projects_Delete_Key_Input>;
  _prepend?: Maybe<Firebase_Projects_Prepend_Input>;
  _set?: Maybe<Firebase_Projects_Set_Input>;
  pk_columns: Firebase_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Livestream_SourcesArgs = {
  _set?: Maybe<Livestream_Sources_Set_Input>;
  where: Livestream_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Livestream_Sources_By_PkArgs = {
  _set?: Maybe<Livestream_Sources_Set_Input>;
  pk_columns: Livestream_Sources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LocaleArgs = {
  _set?: Maybe<Locale_Set_Input>;
  where: Locale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Locale_By_PkArgs = {
  _set?: Maybe<Locale_Set_Input>;
  pk_columns: Locale_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Localized_FilesArgs = {
  _set?: Maybe<Localized_Files_Set_Input>;
  where: Localized_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Localized_Files_By_PkArgs = {
  _set?: Maybe<Localized_Files_Set_Input>;
  pk_columns: Localized_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MicrositesArgs = {
  _set?: Maybe<Microsites_Set_Input>;
  where: Microsites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Microsites_By_PkArgs = {
  _set?: Maybe<Microsites_Set_Input>;
  pk_columns: Microsites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ondemand_SourcesArgs = {
  _set?: Maybe<Ondemand_Sources_Set_Input>;
  where: Ondemand_Sources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ondemand_Sources_By_PkArgs = {
  _set?: Maybe<Ondemand_Sources_Set_Input>;
  pk_columns: Ondemand_Sources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SpeakersArgs = {
  _inc?: Maybe<Speakers_Inc_Input>;
  _set?: Maybe<Speakers_Set_Input>;
  where: Speakers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Speakers_By_PkArgs = {
  _inc?: Maybe<Speakers_Inc_Input>;
  _set?: Maybe<Speakers_Set_Input>;
  pk_columns: Speakers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_String_TranslationsArgs = {
  _set?: Maybe<String_Translations_Set_Input>;
  where: String_Translations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_String_Translations_By_PkArgs = {
  _set?: Maybe<String_Translations_Set_Input>;
  pk_columns: String_Translations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ui_ThemesArgs = {
  _set?: Maybe<Ui_Themes_Set_Input>;
  where: Ui_Themes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ui_Themes_By_PkArgs = {
  _set?: Maybe<Ui_Themes_Set_Input>;
  pk_columns: Ui_Themes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Vercel_ProjectsArgs = {
  _set?: Maybe<Vercel_Projects_Set_Input>;
  where: Vercel_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Vercel_Projects_By_PkArgs = {
  _set?: Maybe<Vercel_Projects_Set_Input>;
  pk_columns: Vercel_Projects_Pk_Columns_Input;
};

/** columns and relationships of "ondemand_sources" */
export type Ondemand_Sources = {
  __typename?: 'ondemand_sources';
  config?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  provider: Scalars['String'];
  src: Scalars['String'];
};


/** columns and relationships of "ondemand_sources" */
export type Ondemand_SourcesConfigArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ondemand_sources" */
export type Ondemand_Sources_Aggregate = {
  __typename?: 'ondemand_sources_aggregate';
  aggregate?: Maybe<Ondemand_Sources_Aggregate_Fields>;
  nodes: Array<Ondemand_Sources>;
};

/** aggregate fields of "ondemand_sources" */
export type Ondemand_Sources_Aggregate_Fields = {
  __typename?: 'ondemand_sources_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Ondemand_Sources_Max_Fields>;
  min?: Maybe<Ondemand_Sources_Min_Fields>;
};


/** aggregate fields of "ondemand_sources" */
export type Ondemand_Sources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ondemand_Sources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ondemand_sources" */
export type Ondemand_Sources_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ondemand_Sources_Max_Order_By>;
  min?: Maybe<Ondemand_Sources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ondemand_sources" */
export type Ondemand_Sources_Arr_Rel_Insert_Input = {
  data: Array<Ondemand_Sources_Insert_Input>;
  on_conflict?: Maybe<Ondemand_Sources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ondemand_sources". All fields are combined with a logical 'AND'. */
export type Ondemand_Sources_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Ondemand_Sources_Bool_Exp>>>;
  _not?: Maybe<Ondemand_Sources_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ondemand_Sources_Bool_Exp>>>;
  config?: Maybe<Json_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
  src?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ondemand_sources" */
export enum Ondemand_Sources_Constraint {
  /** unique or primary key constraint */
  OndemandSourcesPkey = 'ondemand_sources_pkey'
}

/** input type for inserting data into table "ondemand_sources" */
export type Ondemand_Sources_Insert_Input = {
  config?: Maybe<Scalars['json']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ondemand_Sources_Max_Fields = {
  __typename?: 'ondemand_sources_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ondemand_sources" */
export type Ondemand_Sources_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ondemand_Sources_Min_Fields = {
  __typename?: 'ondemand_sources_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ondemand_sources" */
export type Ondemand_Sources_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** response of any mutation on the table "ondemand_sources" */
export type Ondemand_Sources_Mutation_Response = {
  __typename?: 'ondemand_sources_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Ondemand_Sources>;
};

/** input type for inserting object relation for remote table "ondemand_sources" */
export type Ondemand_Sources_Obj_Rel_Insert_Input = {
  data: Ondemand_Sources_Insert_Input;
  on_conflict?: Maybe<Ondemand_Sources_On_Conflict>;
};

/** on conflict condition type for table "ondemand_sources" */
export type Ondemand_Sources_On_Conflict = {
  constraint: Ondemand_Sources_Constraint;
  update_columns: Array<Ondemand_Sources_Update_Column>;
  where?: Maybe<Ondemand_Sources_Bool_Exp>;
};

/** ordering options when selecting data from "ondemand_sources" */
export type Ondemand_Sources_Order_By = {
  config?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  provider?: Maybe<Order_By>;
  src?: Maybe<Order_By>;
};

/** primary key columns input for table: "ondemand_sources" */
export type Ondemand_Sources_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ondemand_sources" */
export enum Ondemand_Sources_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Src = 'src'
}

/** input type for updating data in table "ondemand_sources" */
export type Ondemand_Sources_Set_Input = {
  config?: Maybe<Scalars['json']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

/** update columns of table "ondemand_sources" */
export enum Ondemand_Sources_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Provider = 'provider',
  /** column name */
  Src = 'src'
}

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "accreditation_types" */
  accreditation_types: Array<Accreditation_Types>;
  /** fetch aggregated fields from the table: "accreditation_types" */
  accreditation_types_aggregate: Accreditation_Types_Aggregate;
  /** fetch data from the table: "accreditation_types" using primary key columns */
  accreditation_types_by_pk?: Maybe<Accreditation_Types>;
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch aggregated fields from the table: "companies" */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "company_departments" */
  company_departments: Array<Company_Departments>;
  /** fetch aggregated fields from the table: "company_departments" */
  company_departments_aggregate: Company_Departments_Aggregate;
  /** fetch data from the table: "company_departments" using primary key columns */
  company_departments_by_pk?: Maybe<Company_Departments>;
  /** fetch data from the table: "company_departments_users" */
  company_departments_users: Array<Company_Departments_Users>;
  /** fetch aggregated fields from the table: "company_departments_users" */
  company_departments_users_aggregate: Company_Departments_Users_Aggregate;
  /** fetch data from the table: "company_departments_users" using primary key columns */
  company_departments_users_by_pk?: Maybe<Company_Departments_Users>;
  /** fetch data from the table: "content_types" */
  content_types: Array<Content_Types>;
  /** fetch aggregated fields from the table: "content_types" */
  content_types_aggregate: Content_Types_Aggregate;
  /** fetch data from the table: "content_types" using primary key columns */
  content_types_by_pk?: Maybe<Content_Types>;
  /** fetch data from the table: "event_accreditation_points" */
  event_accreditation_points: Array<Event_Accreditation_Points>;
  /** fetch aggregated fields from the table: "event_accreditation_points" */
  event_accreditation_points_aggregate: Event_Accreditation_Points_Aggregate;
  /** fetch data from the table: "event_content" */
  event_content: Array<Event_Content>;
  /** fetch aggregated fields from the table: "event_content" */
  event_content_aggregate: Event_Content_Aggregate;
  /** fetch data from the table: "event_content" using primary key columns */
  event_content_by_pk?: Maybe<Event_Content>;
  /** fetch data from the table: "event_locations" */
  event_locations: Array<Event_Locations>;
  /** fetch aggregated fields from the table: "event_locations" */
  event_locations_aggregate: Event_Locations_Aggregate;
  /** fetch data from the table: "event_locations" using primary key columns */
  event_locations_by_pk?: Maybe<Event_Locations>;
  /** fetch data from the table: "event_schedule_accreditation_points" */
  event_schedule_accreditation_points: Array<Event_Schedule_Accreditation_Points>;
  /** fetch aggregated fields from the table: "event_schedule_accreditation_points" */
  event_schedule_accreditation_points_aggregate: Event_Schedule_Accreditation_Points_Aggregate;
  /** fetch data from the table: "event_schedule_accreditation_points" using primary key columns */
  event_schedule_accreditation_points_by_pk?: Maybe<Event_Schedule_Accreditation_Points>;
  /** fetch data from the table: "event_schedule_types" */
  event_schedule_types: Array<Event_Schedule_Types>;
  /** fetch aggregated fields from the table: "event_schedule_types" */
  event_schedule_types_aggregate: Event_Schedule_Types_Aggregate;
  /** fetch data from the table: "event_schedule_types" using primary key columns */
  event_schedule_types_by_pk?: Maybe<Event_Schedule_Types>;
  /** fetch data from the table: "event_schedules" */
  event_schedules: Array<Event_Schedules>;
  /** fetch aggregated fields from the table: "event_schedules" */
  event_schedules_aggregate: Event_Schedules_Aggregate;
  /** fetch data from the table: "event_schedules" using primary key columns */
  event_schedules_by_pk?: Maybe<Event_Schedules>;
  /** fetch data from the table: "event_schedules_speakers" */
  event_schedules_speakers: Array<Event_Schedules_Speakers>;
  /** fetch aggregated fields from the table: "event_schedules_speakers" */
  event_schedules_speakers_aggregate: Event_Schedules_Speakers_Aggregate;
  /** fetch data from the table: "event_schedules_speakers" using primary key columns */
  event_schedules_speakers_by_pk?: Maybe<Event_Schedules_Speakers>;
  /** fetch data from the table: "event_sponsoring_types" */
  event_sponsoring_types: Array<Event_Sponsoring_Types>;
  /** fetch aggregated fields from the table: "event_sponsoring_types" */
  event_sponsoring_types_aggregate: Event_Sponsoring_Types_Aggregate;
  /** fetch data from the table: "event_sponsoring_types" using primary key columns */
  event_sponsoring_types_by_pk?: Maybe<Event_Sponsoring_Types>;
  /** fetch data from the table: "event_sponsorings" */
  event_sponsorings: Array<Event_Sponsorings>;
  /** fetch aggregated fields from the table: "event_sponsorings" */
  event_sponsorings_aggregate: Event_Sponsorings_Aggregate;
  /** fetch data from the table: "event_sponsorings" using primary key columns */
  event_sponsorings_by_pk?: Maybe<Event_Sponsorings>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "events_microsites" */
  events_microsites: Array<Events_Microsites>;
  /** fetch aggregated fields from the table: "events_microsites" */
  events_microsites_aggregate: Events_Microsites_Aggregate;
  /** fetch data from the table: "events_microsites" using primary key columns */
  events_microsites_by_pk?: Maybe<Events_Microsites>;
  /** fetch data from the table: "firebase_projects" */
  firebase_projects: Array<Firebase_Projects>;
  /** fetch aggregated fields from the table: "firebase_projects" */
  firebase_projects_aggregate: Firebase_Projects_Aggregate;
  /** fetch data from the table: "firebase_projects" using primary key columns */
  firebase_projects_by_pk?: Maybe<Firebase_Projects>;
  /** fetch data from the table: "livestream_sources" */
  livestream_sources: Array<Livestream_Sources>;
  /** fetch aggregated fields from the table: "livestream_sources" */
  livestream_sources_aggregate: Livestream_Sources_Aggregate;
  /** fetch data from the table: "livestream_sources" using primary key columns */
  livestream_sources_by_pk?: Maybe<Livestream_Sources>;
  /** fetch data from the table: "locale" */
  locale: Array<Locale>;
  /** fetch aggregated fields from the table: "locale" */
  locale_aggregate: Locale_Aggregate;
  /** fetch data from the table: "locale" using primary key columns */
  locale_by_pk?: Maybe<Locale>;
  /** fetch data from the table: "localized_files" */
  localized_files: Array<Localized_Files>;
  /** fetch aggregated fields from the table: "localized_files" */
  localized_files_aggregate: Localized_Files_Aggregate;
  /** fetch data from the table: "localized_files" using primary key columns */
  localized_files_by_pk?: Maybe<Localized_Files>;
  /** fetch data from the table: "microsites" */
  microsites: Array<Microsites>;
  /** fetch aggregated fields from the table: "microsites" */
  microsites_aggregate: Microsites_Aggregate;
  /** fetch data from the table: "microsites" using primary key columns */
  microsites_by_pk?: Maybe<Microsites>;
  /** fetch data from the table: "ondemand_sources" */
  ondemand_sources: Array<Ondemand_Sources>;
  /** fetch aggregated fields from the table: "ondemand_sources" */
  ondemand_sources_aggregate: Ondemand_Sources_Aggregate;
  /** fetch data from the table: "ondemand_sources" using primary key columns */
  ondemand_sources_by_pk?: Maybe<Ondemand_Sources>;
  /** fetch data from the table: "speakers" */
  speakers: Array<Speakers>;
  /** fetch aggregated fields from the table: "speakers" */
  speakers_aggregate: Speakers_Aggregate;
  /** fetch data from the table: "speakers" using primary key columns */
  speakers_by_pk?: Maybe<Speakers>;
  /** fetch data from the table: "string_translations" */
  string_translations: Array<String_Translations>;
  /** fetch aggregated fields from the table: "string_translations" */
  string_translations_aggregate: String_Translations_Aggregate;
  /** fetch data from the table: "string_translations" using primary key columns */
  string_translations_by_pk?: Maybe<String_Translations>;
  /** fetch data from the table: "ui_themes" */
  ui_themes: Array<Ui_Themes>;
  /** fetch aggregated fields from the table: "ui_themes" */
  ui_themes_aggregate: Ui_Themes_Aggregate;
  /** fetch data from the table: "ui_themes" using primary key columns */
  ui_themes_by_pk?: Maybe<Ui_Themes>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vercel_projects" */
  vercel_projects: Array<Vercel_Projects>;
  /** fetch aggregated fields from the table: "vercel_projects" */
  vercel_projects_aggregate: Vercel_Projects_Aggregate;
  /** fetch data from the table: "vercel_projects" using primary key columns */
  vercel_projects_by_pk?: Maybe<Vercel_Projects>;
};


/** query root */
export type Query_RootAccreditation_TypesArgs = {
  distinct_on?: Maybe<Array<Accreditation_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accreditation_Types_Order_By>>;
  where?: Maybe<Accreditation_Types_Bool_Exp>;
};


/** query root */
export type Query_RootAccreditation_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Accreditation_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accreditation_Types_Order_By>>;
  where?: Maybe<Accreditation_Types_Bool_Exp>;
};


/** query root */
export type Query_RootAccreditation_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** query root */
export type Query_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCompany_DepartmentsArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};


/** query root */
export type Query_RootCompany_Departments_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};


/** query root */
export type Query_RootCompany_Departments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCompany_Departments_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** query root */
export type Query_RootCompany_Departments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** query root */
export type Query_RootCompany_Departments_Users_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootContent_TypesArgs = {
  distinct_on?: Maybe<Array<Content_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Types_Order_By>>;
  where?: Maybe<Content_Types_Bool_Exp>;
};


/** query root */
export type Query_RootContent_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Types_Order_By>>;
  where?: Maybe<Content_Types_Bool_Exp>;
};


/** query root */
export type Query_RootContent_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootEvent_Accreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Accreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_ContentArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Content_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Content_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvent_LocationsArgs = {
  distinct_on?: Maybe<Array<Event_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Locations_Order_By>>;
  where?: Maybe<Event_Locations_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Locations_Order_By>>;
  where?: Maybe<Event_Locations_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvent_Schedule_Accreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedule_Accreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedule_Accreditation_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvent_Schedule_TypesArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Types_Order_By>>;
  where?: Maybe<Event_Schedule_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedule_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Types_Order_By>>;
  where?: Maybe<Event_Schedule_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedule_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** query root */
export type Query_RootEvent_SchedulesArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedules_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvent_Schedules_SpeakersArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedules_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Schedules_Speakers_By_PkArgs = {
  event_schedule_id: Scalars['uuid'];
  speaker_id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvent_Sponsoring_TypesArgs = {
  distinct_on?: Maybe<Array<Event_Sponsoring_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsoring_Types_Order_By>>;
  where?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Sponsoring_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sponsoring_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsoring_Types_Order_By>>;
  where?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Sponsoring_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** query root */
export type Query_RootEvent_SponsoringsArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Sponsorings_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** query root */
export type Query_RootEvent_Sponsorings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** query root */
export type Query_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** query root */
export type Query_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEvents_MicrositesArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** query root */
export type Query_RootEvents_Microsites_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** query root */
export type Query_RootEvents_Microsites_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootFirebase_ProjectsArgs = {
  distinct_on?: Maybe<Array<Firebase_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Projects_Order_By>>;
  where?: Maybe<Firebase_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootFirebase_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Firebase_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Projects_Order_By>>;
  where?: Maybe<Firebase_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootFirebase_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootLivestream_SourcesArgs = {
  distinct_on?: Maybe<Array<Livestream_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Livestream_Sources_Order_By>>;
  where?: Maybe<Livestream_Sources_Bool_Exp>;
};


/** query root */
export type Query_RootLivestream_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Livestream_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Livestream_Sources_Order_By>>;
  where?: Maybe<Livestream_Sources_Bool_Exp>;
};


/** query root */
export type Query_RootLivestream_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootLocaleArgs = {
  distinct_on?: Maybe<Array<Locale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locale_Order_By>>;
  where?: Maybe<Locale_Bool_Exp>;
};


/** query root */
export type Query_RootLocale_AggregateArgs = {
  distinct_on?: Maybe<Array<Locale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locale_Order_By>>;
  where?: Maybe<Locale_Bool_Exp>;
};


/** query root */
export type Query_RootLocale_By_PkArgs = {
  code: Scalars['String'];
};


/** query root */
export type Query_RootLocalized_FilesArgs = {
  distinct_on?: Maybe<Array<Localized_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Localized_Files_Order_By>>;
  where?: Maybe<Localized_Files_Bool_Exp>;
};


/** query root */
export type Query_RootLocalized_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Localized_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Localized_Files_Order_By>>;
  where?: Maybe<Localized_Files_Bool_Exp>;
};


/** query root */
export type Query_RootLocalized_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootMicrositesArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** query root */
export type Query_RootMicrosites_AggregateArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** query root */
export type Query_RootMicrosites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootOndemand_SourcesArgs = {
  distinct_on?: Maybe<Array<Ondemand_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ondemand_Sources_Order_By>>;
  where?: Maybe<Ondemand_Sources_Bool_Exp>;
};


/** query root */
export type Query_RootOndemand_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Ondemand_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ondemand_Sources_Order_By>>;
  where?: Maybe<Ondemand_Sources_Bool_Exp>;
};


/** query root */
export type Query_RootOndemand_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSpeakersArgs = {
  distinct_on?: Maybe<Array<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


/** query root */
export type Query_RootSpeakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


/** query root */
export type Query_RootSpeakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootString_TranslationsArgs = {
  distinct_on?: Maybe<Array<String_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<String_Translations_Order_By>>;
  where?: Maybe<String_Translations_Bool_Exp>;
};


/** query root */
export type Query_RootString_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<String_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<String_Translations_Order_By>>;
  where?: Maybe<String_Translations_Bool_Exp>;
};


/** query root */
export type Query_RootString_Translations_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootUi_ThemesArgs = {
  distinct_on?: Maybe<Array<Ui_Themes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ui_Themes_Order_By>>;
  where?: Maybe<Ui_Themes_Bool_Exp>;
};


/** query root */
export type Query_RootUi_Themes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ui_Themes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ui_Themes_Order_By>>;
  where?: Maybe<Ui_Themes_Bool_Exp>;
};


/** query root */
export type Query_RootUi_Themes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** query root */
export type Query_RootVercel_ProjectsArgs = {
  distinct_on?: Maybe<Array<Vercel_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vercel_Projects_Order_By>>;
  where?: Maybe<Vercel_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootVercel_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Vercel_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vercel_Projects_Order_By>>;
  where?: Maybe<Vercel_Projects_Bool_Exp>;
};


/** query root */
export type Query_RootVercel_Projects_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "speakers" */
export type Speakers = {
  __typename?: 'speakers';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** A computed field, executes function "speaker_display_name" */
  display_name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "speaker_display_name_academic" */
  display_name_academic?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  event_schedules: Array<Event_Schedules_Speakers>;
  /** An aggregated array relationship */
  event_schedules_aggregate: Event_Schedules_Speakers_Aggregate;
  firstname: Scalars['String'];
  id: Scalars['uuid'];
  lastname: Scalars['String'];
  post_title?: Maybe<Scalars['String']>;
  pre_title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "speakers" */
export type SpeakersEvent_SchedulesArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** columns and relationships of "speakers" */
export type SpeakersEvent_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};

/** aggregated selection of "speakers" */
export type Speakers_Aggregate = {
  __typename?: 'speakers_aggregate';
  aggregate?: Maybe<Speakers_Aggregate_Fields>;
  nodes: Array<Speakers>;
};

/** aggregate fields of "speakers" */
export type Speakers_Aggregate_Fields = {
  __typename?: 'speakers_aggregate_fields';
  avg?: Maybe<Speakers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Speakers_Max_Fields>;
  min?: Maybe<Speakers_Min_Fields>;
  stddev?: Maybe<Speakers_Stddev_Fields>;
  stddev_pop?: Maybe<Speakers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Speakers_Stddev_Samp_Fields>;
  sum?: Maybe<Speakers_Sum_Fields>;
  var_pop?: Maybe<Speakers_Var_Pop_Fields>;
  var_samp?: Maybe<Speakers_Var_Samp_Fields>;
  variance?: Maybe<Speakers_Variance_Fields>;
};


/** aggregate fields of "speakers" */
export type Speakers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Speakers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "speakers" */
export type Speakers_Aggregate_Order_By = {
  avg?: Maybe<Speakers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Speakers_Max_Order_By>;
  min?: Maybe<Speakers_Min_Order_By>;
  stddev?: Maybe<Speakers_Stddev_Order_By>;
  stddev_pop?: Maybe<Speakers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Speakers_Stddev_Samp_Order_By>;
  sum?: Maybe<Speakers_Sum_Order_By>;
  var_pop?: Maybe<Speakers_Var_Pop_Order_By>;
  var_samp?: Maybe<Speakers_Var_Samp_Order_By>;
  variance?: Maybe<Speakers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "speakers" */
export type Speakers_Arr_Rel_Insert_Input = {
  data: Array<Speakers_Insert_Input>;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};

/** aggregate avg on columns */
export type Speakers_Avg_Fields = {
  __typename?: 'speakers_avg_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "speakers" */
export type Speakers_Avg_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "speakers". All fields are combined with a logical 'AND'. */
export type Speakers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Speakers_Bool_Exp>>>;
  _not?: Maybe<Speakers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Speakers_Bool_Exp>>>;
  avatar?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  drupal_id?: Maybe<Int_Comparison_Exp>;
  event_schedules?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
  firstname?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lastname?: Maybe<String_Comparison_Exp>;
  post_title?: Maybe<String_Comparison_Exp>;
  pre_title?: Maybe<String_Comparison_Exp>;
  ub_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "speakers" */
export enum Speakers_Constraint {
  /** unique or primary key constraint */
  SpeakerPkey = 'speaker_pkey',
  /** unique or primary key constraint */
  SpeakersDrupalIdKey = 'speakers_drupal_id_key',
  /** unique or primary key constraint */
  SpeakersDrupalIdUbIdKey = 'speakers_drupal_id_ub_id_key'
}

/** input type for incrementing integer column in table "speakers" */
export type Speakers_Inc_Input = {
  drupal_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "speakers" */
export type Speakers_Insert_Input = {
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  event_schedules?: Maybe<Event_Schedules_Speakers_Arr_Rel_Insert_Input>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastname?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
  pre_title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Speakers_Max_Fields = {
  __typename?: 'speakers_max_fields';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastname?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
  pre_title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "speakers" */
export type Speakers_Max_Order_By = {
  avatar?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastname?: Maybe<Order_By>;
  post_title?: Maybe<Order_By>;
  pre_title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Speakers_Min_Fields = {
  __typename?: 'speakers_min_fields';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastname?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
  pre_title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "speakers" */
export type Speakers_Min_Order_By = {
  avatar?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastname?: Maybe<Order_By>;
  post_title?: Maybe<Order_By>;
  pre_title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "speakers" */
export type Speakers_Mutation_Response = {
  __typename?: 'speakers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Speakers>;
};

/** input type for inserting object relation for remote table "speakers" */
export type Speakers_Obj_Rel_Insert_Input = {
  data: Speakers_Insert_Input;
  on_conflict?: Maybe<Speakers_On_Conflict>;
};

/** on conflict condition type for table "speakers" */
export type Speakers_On_Conflict = {
  constraint: Speakers_Constraint;
  update_columns: Array<Speakers_Update_Column>;
  where?: Maybe<Speakers_Bool_Exp>;
};

/** ordering options when selecting data from "speakers" */
export type Speakers_Order_By = {
  avatar?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  drupal_id?: Maybe<Order_By>;
  event_schedules_aggregate?: Maybe<Event_Schedules_Speakers_Aggregate_Order_By>;
  firstname?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lastname?: Maybe<Order_By>;
  post_title?: Maybe<Order_By>;
  pre_title?: Maybe<Order_By>;
  ub_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "speakers" */
export type Speakers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "speakers" */
export enum Speakers_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Description = 'description',
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  PostTitle = 'post_title',
  /** column name */
  PreTitle = 'pre_title',
  /** column name */
  UbId = 'ub_id'
}

/** input type for updating data in table "speakers" */
export type Speakers_Set_Input = {
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drupal_id?: Maybe<Scalars['Int']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lastname?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
  pre_title?: Maybe<Scalars['String']>;
  ub_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Speakers_Stddev_Fields = {
  __typename?: 'speakers_stddev_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "speakers" */
export type Speakers_Stddev_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Speakers_Stddev_Pop_Fields = {
  __typename?: 'speakers_stddev_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "speakers" */
export type Speakers_Stddev_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Speakers_Stddev_Samp_Fields = {
  __typename?: 'speakers_stddev_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "speakers" */
export type Speakers_Stddev_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Speakers_Sum_Fields = {
  __typename?: 'speakers_sum_fields';
  drupal_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "speakers" */
export type Speakers_Sum_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** update columns of table "speakers" */
export enum Speakers_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Description = 'description',
  /** column name */
  DrupalId = 'drupal_id',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  PostTitle = 'post_title',
  /** column name */
  PreTitle = 'pre_title',
  /** column name */
  UbId = 'ub_id'
}

/** aggregate var_pop on columns */
export type Speakers_Var_Pop_Fields = {
  __typename?: 'speakers_var_pop_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "speakers" */
export type Speakers_Var_Pop_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Speakers_Var_Samp_Fields = {
  __typename?: 'speakers_var_samp_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "speakers" */
export type Speakers_Var_Samp_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Speakers_Variance_Fields = {
  __typename?: 'speakers_variance_fields';
  drupal_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "speakers" */
export type Speakers_Variance_Order_By = {
  drupal_id?: Maybe<Order_By>;
};

/** columns and relationships of "string_translations" */
export type String_Translations = {
  __typename?: 'string_translations';
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  source_string: Scalars['String'];
};

/** aggregated selection of "string_translations" */
export type String_Translations_Aggregate = {
  __typename?: 'string_translations_aggregate';
  aggregate?: Maybe<String_Translations_Aggregate_Fields>;
  nodes: Array<String_Translations>;
};

/** aggregate fields of "string_translations" */
export type String_Translations_Aggregate_Fields = {
  __typename?: 'string_translations_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<String_Translations_Max_Fields>;
  min?: Maybe<String_Translations_Min_Fields>;
};


/** aggregate fields of "string_translations" */
export type String_Translations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<String_Translations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "string_translations" */
export type String_Translations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<String_Translations_Max_Order_By>;
  min?: Maybe<String_Translations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "string_translations" */
export type String_Translations_Arr_Rel_Insert_Input = {
  data: Array<String_Translations_Insert_Input>;
  on_conflict?: Maybe<String_Translations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "string_translations". All fields are combined with a logical 'AND'. */
export type String_Translations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<String_Translations_Bool_Exp>>>;
  _not?: Maybe<String_Translations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<String_Translations_Bool_Exp>>>;
  de?: Maybe<String_Comparison_Exp>;
  de_AT?: Maybe<String_Comparison_Exp>;
  de_CH?: Maybe<String_Comparison_Exp>;
  en_GB?: Maybe<String_Comparison_Exp>;
  fr_CH?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  source_string?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "string_translations" */
export enum String_Translations_Constraint {
  /** unique or primary key constraint */
  StringTranslationsPkey = 'string_translations_pkey'
}

/** input type for inserting data into table "string_translations" */
export type String_Translations_Insert_Input = {
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source_string?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type String_Translations_Max_Fields = {
  __typename?: 'string_translations_max_fields';
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source_string?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "string_translations" */
export type String_Translations_Max_Order_By = {
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_string?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type String_Translations_Min_Fields = {
  __typename?: 'string_translations_min_fields';
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source_string?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "string_translations" */
export type String_Translations_Min_Order_By = {
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_string?: Maybe<Order_By>;
};

/** response of any mutation on the table "string_translations" */
export type String_Translations_Mutation_Response = {
  __typename?: 'string_translations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<String_Translations>;
};

/** input type for inserting object relation for remote table "string_translations" */
export type String_Translations_Obj_Rel_Insert_Input = {
  data: String_Translations_Insert_Input;
  on_conflict?: Maybe<String_Translations_On_Conflict>;
};

/** on conflict condition type for table "string_translations" */
export type String_Translations_On_Conflict = {
  constraint: String_Translations_Constraint;
  update_columns: Array<String_Translations_Update_Column>;
  where?: Maybe<String_Translations_Bool_Exp>;
};

/** ordering options when selecting data from "string_translations" */
export type String_Translations_Order_By = {
  de?: Maybe<Order_By>;
  de_AT?: Maybe<Order_By>;
  de_CH?: Maybe<Order_By>;
  en_GB?: Maybe<Order_By>;
  fr_CH?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_string?: Maybe<Order_By>;
};

/** primary key columns input for table: "string_translations" */
export type String_Translations_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "string_translations" */
export enum String_Translations_Select_Column {
  /** column name */
  De = 'de',
  /** column name */
  DeAt = 'de_AT',
  /** column name */
  DeCh = 'de_CH',
  /** column name */
  EnGb = 'en_GB',
  /** column name */
  FrCh = 'fr_CH',
  /** column name */
  Id = 'id',
  /** column name */
  SourceString = 'source_string'
}

/** input type for updating data in table "string_translations" */
export type String_Translations_Set_Input = {
  de?: Maybe<Scalars['String']>;
  de_AT?: Maybe<Scalars['String']>;
  de_CH?: Maybe<Scalars['String']>;
  en_GB?: Maybe<Scalars['String']>;
  fr_CH?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  source_string?: Maybe<Scalars['String']>;
};

/** update columns of table "string_translations" */
export enum String_Translations_Update_Column {
  /** column name */
  De = 'de',
  /** column name */
  DeAt = 'de_AT',
  /** column name */
  DeCh = 'de_CH',
  /** column name */
  EnGb = 'en_GB',
  /** column name */
  FrCh = 'fr_CH',
  /** column name */
  Id = 'id',
  /** column name */
  SourceString = 'source_string'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "accreditation_types" */
  accreditation_types: Array<Accreditation_Types>;
  /** fetch aggregated fields from the table: "accreditation_types" */
  accreditation_types_aggregate: Accreditation_Types_Aggregate;
  /** fetch data from the table: "accreditation_types" using primary key columns */
  accreditation_types_by_pk?: Maybe<Accreditation_Types>;
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch aggregated fields from the table: "companies" */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "company_departments" */
  company_departments: Array<Company_Departments>;
  /** fetch aggregated fields from the table: "company_departments" */
  company_departments_aggregate: Company_Departments_Aggregate;
  /** fetch data from the table: "company_departments" using primary key columns */
  company_departments_by_pk?: Maybe<Company_Departments>;
  /** fetch data from the table: "company_departments_users" */
  company_departments_users: Array<Company_Departments_Users>;
  /** fetch aggregated fields from the table: "company_departments_users" */
  company_departments_users_aggregate: Company_Departments_Users_Aggregate;
  /** fetch data from the table: "company_departments_users" using primary key columns */
  company_departments_users_by_pk?: Maybe<Company_Departments_Users>;
  /** fetch data from the table: "content_types" */
  content_types: Array<Content_Types>;
  /** fetch aggregated fields from the table: "content_types" */
  content_types_aggregate: Content_Types_Aggregate;
  /** fetch data from the table: "content_types" using primary key columns */
  content_types_by_pk?: Maybe<Content_Types>;
  /** fetch data from the table: "event_accreditation_points" */
  event_accreditation_points: Array<Event_Accreditation_Points>;
  /** fetch aggregated fields from the table: "event_accreditation_points" */
  event_accreditation_points_aggregate: Event_Accreditation_Points_Aggregate;
  /** fetch data from the table: "event_content" */
  event_content: Array<Event_Content>;
  /** fetch aggregated fields from the table: "event_content" */
  event_content_aggregate: Event_Content_Aggregate;
  /** fetch data from the table: "event_content" using primary key columns */
  event_content_by_pk?: Maybe<Event_Content>;
  /** fetch data from the table: "event_locations" */
  event_locations: Array<Event_Locations>;
  /** fetch aggregated fields from the table: "event_locations" */
  event_locations_aggregate: Event_Locations_Aggregate;
  /** fetch data from the table: "event_locations" using primary key columns */
  event_locations_by_pk?: Maybe<Event_Locations>;
  /** fetch data from the table: "event_schedule_accreditation_points" */
  event_schedule_accreditation_points: Array<Event_Schedule_Accreditation_Points>;
  /** fetch aggregated fields from the table: "event_schedule_accreditation_points" */
  event_schedule_accreditation_points_aggregate: Event_Schedule_Accreditation_Points_Aggregate;
  /** fetch data from the table: "event_schedule_accreditation_points" using primary key columns */
  event_schedule_accreditation_points_by_pk?: Maybe<Event_Schedule_Accreditation_Points>;
  /** fetch data from the table: "event_schedule_types" */
  event_schedule_types: Array<Event_Schedule_Types>;
  /** fetch aggregated fields from the table: "event_schedule_types" */
  event_schedule_types_aggregate: Event_Schedule_Types_Aggregate;
  /** fetch data from the table: "event_schedule_types" using primary key columns */
  event_schedule_types_by_pk?: Maybe<Event_Schedule_Types>;
  /** fetch data from the table: "event_schedules" */
  event_schedules: Array<Event_Schedules>;
  /** fetch aggregated fields from the table: "event_schedules" */
  event_schedules_aggregate: Event_Schedules_Aggregate;
  /** fetch data from the table: "event_schedules" using primary key columns */
  event_schedules_by_pk?: Maybe<Event_Schedules>;
  /** fetch data from the table: "event_schedules_speakers" */
  event_schedules_speakers: Array<Event_Schedules_Speakers>;
  /** fetch aggregated fields from the table: "event_schedules_speakers" */
  event_schedules_speakers_aggregate: Event_Schedules_Speakers_Aggregate;
  /** fetch data from the table: "event_schedules_speakers" using primary key columns */
  event_schedules_speakers_by_pk?: Maybe<Event_Schedules_Speakers>;
  /** fetch data from the table: "event_sponsoring_types" */
  event_sponsoring_types: Array<Event_Sponsoring_Types>;
  /** fetch aggregated fields from the table: "event_sponsoring_types" */
  event_sponsoring_types_aggregate: Event_Sponsoring_Types_Aggregate;
  /** fetch data from the table: "event_sponsoring_types" using primary key columns */
  event_sponsoring_types_by_pk?: Maybe<Event_Sponsoring_Types>;
  /** fetch data from the table: "event_sponsorings" */
  event_sponsorings: Array<Event_Sponsorings>;
  /** fetch aggregated fields from the table: "event_sponsorings" */
  event_sponsorings_aggregate: Event_Sponsorings_Aggregate;
  /** fetch data from the table: "event_sponsorings" using primary key columns */
  event_sponsorings_by_pk?: Maybe<Event_Sponsorings>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table: "events_microsites" */
  events_microsites: Array<Events_Microsites>;
  /** fetch aggregated fields from the table: "events_microsites" */
  events_microsites_aggregate: Events_Microsites_Aggregate;
  /** fetch data from the table: "events_microsites" using primary key columns */
  events_microsites_by_pk?: Maybe<Events_Microsites>;
  /** fetch data from the table: "firebase_projects" */
  firebase_projects: Array<Firebase_Projects>;
  /** fetch aggregated fields from the table: "firebase_projects" */
  firebase_projects_aggregate: Firebase_Projects_Aggregate;
  /** fetch data from the table: "firebase_projects" using primary key columns */
  firebase_projects_by_pk?: Maybe<Firebase_Projects>;
  /** fetch data from the table: "livestream_sources" */
  livestream_sources: Array<Livestream_Sources>;
  /** fetch aggregated fields from the table: "livestream_sources" */
  livestream_sources_aggregate: Livestream_Sources_Aggregate;
  /** fetch data from the table: "livestream_sources" using primary key columns */
  livestream_sources_by_pk?: Maybe<Livestream_Sources>;
  /** fetch data from the table: "locale" */
  locale: Array<Locale>;
  /** fetch aggregated fields from the table: "locale" */
  locale_aggregate: Locale_Aggregate;
  /** fetch data from the table: "locale" using primary key columns */
  locale_by_pk?: Maybe<Locale>;
  /** fetch data from the table: "localized_files" */
  localized_files: Array<Localized_Files>;
  /** fetch aggregated fields from the table: "localized_files" */
  localized_files_aggregate: Localized_Files_Aggregate;
  /** fetch data from the table: "localized_files" using primary key columns */
  localized_files_by_pk?: Maybe<Localized_Files>;
  /** fetch data from the table: "microsites" */
  microsites: Array<Microsites>;
  /** fetch aggregated fields from the table: "microsites" */
  microsites_aggregate: Microsites_Aggregate;
  /** fetch data from the table: "microsites" using primary key columns */
  microsites_by_pk?: Maybe<Microsites>;
  /** fetch data from the table: "ondemand_sources" */
  ondemand_sources: Array<Ondemand_Sources>;
  /** fetch aggregated fields from the table: "ondemand_sources" */
  ondemand_sources_aggregate: Ondemand_Sources_Aggregate;
  /** fetch data from the table: "ondemand_sources" using primary key columns */
  ondemand_sources_by_pk?: Maybe<Ondemand_Sources>;
  /** fetch data from the table: "speakers" */
  speakers: Array<Speakers>;
  /** fetch aggregated fields from the table: "speakers" */
  speakers_aggregate: Speakers_Aggregate;
  /** fetch data from the table: "speakers" using primary key columns */
  speakers_by_pk?: Maybe<Speakers>;
  /** fetch data from the table: "string_translations" */
  string_translations: Array<String_Translations>;
  /** fetch aggregated fields from the table: "string_translations" */
  string_translations_aggregate: String_Translations_Aggregate;
  /** fetch data from the table: "string_translations" using primary key columns */
  string_translations_by_pk?: Maybe<String_Translations>;
  /** fetch data from the table: "ui_themes" */
  ui_themes: Array<Ui_Themes>;
  /** fetch aggregated fields from the table: "ui_themes" */
  ui_themes_aggregate: Ui_Themes_Aggregate;
  /** fetch data from the table: "ui_themes" using primary key columns */
  ui_themes_by_pk?: Maybe<Ui_Themes>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "vercel_projects" */
  vercel_projects: Array<Vercel_Projects>;
  /** fetch aggregated fields from the table: "vercel_projects" */
  vercel_projects_aggregate: Vercel_Projects_Aggregate;
  /** fetch data from the table: "vercel_projects" using primary key columns */
  vercel_projects_by_pk?: Maybe<Vercel_Projects>;
};


/** subscription root */
export type Subscription_RootAccreditation_TypesArgs = {
  distinct_on?: Maybe<Array<Accreditation_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accreditation_Types_Order_By>>;
  where?: Maybe<Accreditation_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccreditation_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Accreditation_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Accreditation_Types_Order_By>>;
  where?: Maybe<Accreditation_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccreditation_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCompany_DepartmentsArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompany_Departments_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Order_By>>;
  where?: Maybe<Company_Departments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompany_Departments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCompany_Departments_UsersArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompany_Departments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompany_Departments_Users_By_PkArgs = {
  department_id: Scalars['uuid'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootContent_TypesArgs = {
  distinct_on?: Maybe<Array<Content_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Types_Order_By>>;
  where?: Maybe<Content_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContent_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Content_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Content_Types_Order_By>>;
  where?: Maybe<Content_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContent_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEvent_Accreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Accreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Accreditation_Points_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_ContentArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Content_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Content_Order_By>>;
  where?: Maybe<Event_Content_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Content_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvent_LocationsArgs = {
  distinct_on?: Maybe<Array<Event_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Locations_Order_By>>;
  where?: Maybe<Event_Locations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Locations_Order_By>>;
  where?: Maybe<Event_Locations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvent_Schedule_Accreditation_PointsArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedule_Accreditation_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Accreditation_Points_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Accreditation_Points_Order_By>>;
  where?: Maybe<Event_Schedule_Accreditation_Points_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedule_Accreditation_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvent_Schedule_TypesArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Types_Order_By>>;
  where?: Maybe<Event_Schedule_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedule_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedule_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedule_Types_Order_By>>;
  where?: Maybe<Event_Schedule_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedule_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEvent_SchedulesArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedules_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Order_By>>;
  where?: Maybe<Event_Schedules_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedules_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvent_Schedules_SpeakersArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedules_Speakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Schedules_Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Schedules_Speakers_Order_By>>;
  where?: Maybe<Event_Schedules_Speakers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Schedules_Speakers_By_PkArgs = {
  event_schedule_id: Scalars['uuid'];
  speaker_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvent_Sponsoring_TypesArgs = {
  distinct_on?: Maybe<Array<Event_Sponsoring_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsoring_Types_Order_By>>;
  where?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Sponsoring_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sponsoring_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsoring_Types_Order_By>>;
  where?: Maybe<Event_Sponsoring_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Sponsoring_Types_By_PkArgs = {
  type: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEvent_SponsoringsArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Sponsorings_AggregateArgs = {
  distinct_on?: Maybe<Array<Event_Sponsorings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Sponsorings_Order_By>>;
  where?: Maybe<Event_Sponsorings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvent_Sponsorings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEventsArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Order_By>>;
  where?: Maybe<Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEvents_MicrositesArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvents_Microsites_AggregateArgs = {
  distinct_on?: Maybe<Array<Events_Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Events_Microsites_Order_By>>;
  where?: Maybe<Events_Microsites_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEvents_Microsites_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootFirebase_ProjectsArgs = {
  distinct_on?: Maybe<Array<Firebase_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Projects_Order_By>>;
  where?: Maybe<Firebase_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFirebase_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Firebase_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Firebase_Projects_Order_By>>;
  where?: Maybe<Firebase_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFirebase_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLivestream_SourcesArgs = {
  distinct_on?: Maybe<Array<Livestream_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Livestream_Sources_Order_By>>;
  where?: Maybe<Livestream_Sources_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLivestream_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Livestream_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Livestream_Sources_Order_By>>;
  where?: Maybe<Livestream_Sources_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLivestream_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootLocaleArgs = {
  distinct_on?: Maybe<Array<Locale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locale_Order_By>>;
  where?: Maybe<Locale_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocale_AggregateArgs = {
  distinct_on?: Maybe<Array<Locale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Locale_Order_By>>;
  where?: Maybe<Locale_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocale_By_PkArgs = {
  code: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLocalized_FilesArgs = {
  distinct_on?: Maybe<Array<Localized_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Localized_Files_Order_By>>;
  where?: Maybe<Localized_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocalized_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Localized_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Localized_Files_Order_By>>;
  where?: Maybe<Localized_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLocalized_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootMicrositesArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMicrosites_AggregateArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMicrosites_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOndemand_SourcesArgs = {
  distinct_on?: Maybe<Array<Ondemand_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ondemand_Sources_Order_By>>;
  where?: Maybe<Ondemand_Sources_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOndemand_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Ondemand_Sources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ondemand_Sources_Order_By>>;
  where?: Maybe<Ondemand_Sources_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOndemand_Sources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSpeakersArgs = {
  distinct_on?: Maybe<Array<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSpeakers_AggregateArgs = {
  distinct_on?: Maybe<Array<Speakers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Speakers_Order_By>>;
  where?: Maybe<Speakers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSpeakers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootString_TranslationsArgs = {
  distinct_on?: Maybe<Array<String_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<String_Translations_Order_By>>;
  where?: Maybe<String_Translations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootString_Translations_AggregateArgs = {
  distinct_on?: Maybe<Array<String_Translations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<String_Translations_Order_By>>;
  where?: Maybe<String_Translations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootString_Translations_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUi_ThemesArgs = {
  distinct_on?: Maybe<Array<Ui_Themes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ui_Themes_Order_By>>;
  where?: Maybe<Ui_Themes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUi_Themes_AggregateArgs = {
  distinct_on?: Maybe<Array<Ui_Themes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Ui_Themes_Order_By>>;
  where?: Maybe<Ui_Themes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUi_Themes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootVercel_ProjectsArgs = {
  distinct_on?: Maybe<Array<Vercel_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vercel_Projects_Order_By>>;
  where?: Maybe<Vercel_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVercel_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Vercel_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Vercel_Projects_Order_By>>;
  where?: Maybe<Vercel_Projects_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVercel_Projects_By_PkArgs = {
  id: Scalars['String'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "ui_themes" */
export type Ui_Themes = {
  __typename?: 'ui_themes';
  id: Scalars['uuid'];
  /** An array relationship */
  microsites: Array<Microsites>;
  /** An aggregated array relationship */
  microsites_aggregate: Microsites_Aggregate;
  name: Scalars['String'];
  theme: Scalars['json'];
};


/** columns and relationships of "ui_themes" */
export type Ui_ThemesMicrositesArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** columns and relationships of "ui_themes" */
export type Ui_ThemesMicrosites_AggregateArgs = {
  distinct_on?: Maybe<Array<Microsites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Microsites_Order_By>>;
  where?: Maybe<Microsites_Bool_Exp>;
};


/** columns and relationships of "ui_themes" */
export type Ui_ThemesThemeArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "ui_themes" */
export type Ui_Themes_Aggregate = {
  __typename?: 'ui_themes_aggregate';
  aggregate?: Maybe<Ui_Themes_Aggregate_Fields>;
  nodes: Array<Ui_Themes>;
};

/** aggregate fields of "ui_themes" */
export type Ui_Themes_Aggregate_Fields = {
  __typename?: 'ui_themes_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Ui_Themes_Max_Fields>;
  min?: Maybe<Ui_Themes_Min_Fields>;
};


/** aggregate fields of "ui_themes" */
export type Ui_Themes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ui_Themes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ui_themes" */
export type Ui_Themes_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ui_Themes_Max_Order_By>;
  min?: Maybe<Ui_Themes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ui_themes" */
export type Ui_Themes_Arr_Rel_Insert_Input = {
  data: Array<Ui_Themes_Insert_Input>;
  on_conflict?: Maybe<Ui_Themes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ui_themes". All fields are combined with a logical 'AND'. */
export type Ui_Themes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Ui_Themes_Bool_Exp>>>;
  _not?: Maybe<Ui_Themes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Ui_Themes_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  microsites?: Maybe<Microsites_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  theme?: Maybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "ui_themes" */
export enum Ui_Themes_Constraint {
  /** unique or primary key constraint */
  UiThemesNameKey = 'ui_themes_name_key',
  /** unique or primary key constraint */
  UiThemesPkey = 'ui_themes_pkey'
}

/** input type for inserting data into table "ui_themes" */
export type Ui_Themes_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  microsites?: Maybe<Microsites_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Ui_Themes_Max_Fields = {
  __typename?: 'ui_themes_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ui_themes" */
export type Ui_Themes_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ui_Themes_Min_Fields = {
  __typename?: 'ui_themes_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ui_themes" */
export type Ui_Themes_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "ui_themes" */
export type Ui_Themes_Mutation_Response = {
  __typename?: 'ui_themes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Ui_Themes>;
};

/** input type for inserting object relation for remote table "ui_themes" */
export type Ui_Themes_Obj_Rel_Insert_Input = {
  data: Ui_Themes_Insert_Input;
  on_conflict?: Maybe<Ui_Themes_On_Conflict>;
};

/** on conflict condition type for table "ui_themes" */
export type Ui_Themes_On_Conflict = {
  constraint: Ui_Themes_Constraint;
  update_columns: Array<Ui_Themes_Update_Column>;
  where?: Maybe<Ui_Themes_Bool_Exp>;
};

/** ordering options when selecting data from "ui_themes" */
export type Ui_Themes_Order_By = {
  id?: Maybe<Order_By>;
  microsites_aggregate?: Maybe<Microsites_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  theme?: Maybe<Order_By>;
};

/** primary key columns input for table: "ui_themes" */
export type Ui_Themes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ui_themes" */
export enum Ui_Themes_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Theme = 'theme'
}

/** input type for updating data in table "ui_themes" */
export type Ui_Themes_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['json']>;
};

/** update columns of table "ui_themes" */
export enum Ui_Themes_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Theme = 'theme'
}

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  name: Scalars['String'];
  role: Scalars['String'];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Roles_Max_Order_By>;
  min?: Maybe<User_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_roles" */
export type User_Roles_Arr_Rel_Insert_Input = {
  data: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<User_Roles_Bool_Exp>>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Roles_Bool_Exp>>>;
  name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint */
  UserRolesNameKey = 'user_roles_name_key',
  /** unique or primary key constraint */
  UserRolesPkey = 'user_roles_pkey'
}

export enum User_Roles_Enum {
  /** Admin */
  Admin = 'admin',
  /** Agent */
  Agent = 'agent',
  /** Director */
  Director = 'director',
  /** Event Manager */
  EventManager = 'event_manager',
  /** Sales */
  Sales = 'sales',
  /** Unauthorized */
  Unauthorized = 'unauthorized',
  /** User */
  User = 'user'
}

/** expression to compare columns of type user_roles_enum. All fields are combined with logical 'AND'. */
export type User_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Roles_Enum>;
  _in?: Maybe<Array<User_Roles_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<User_Roles_Enum>;
  _nin?: Maybe<Array<User_Roles_Enum>>;
};

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
  name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
  name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Roles>;
};

/** input type for inserting object relation for remote table "user_roles" */
export type User_Roles_Obj_Rel_Insert_Input = {
  data: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** on conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns: Array<User_Roles_Update_Column>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "user_roles" */
export type User_Roles_Order_By = {
  name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: "user_roles" */
export type User_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  company_department: Array<Company_Departments_Users>;
  /** An aggregated array relationship */
  company_department_aggregate: Company_Departments_Users_Aggregate;
  created_at: Scalars['timestamptz'];
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "users" */
export type UsersCompany_DepartmentArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompany_Department_AggregateArgs = {
  distinct_on?: Maybe<Array<Company_Departments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Company_Departments_Users_Order_By>>;
  where?: Maybe<Company_Departments_Users_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  company_department?: Maybe<Company_Departments_Users_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<User_Roles_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  company_department?: Maybe<Company_Departments_Users_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  company_department_aggregate?: Maybe<Company_Departments_Users_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<User_Roles_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "vercel_projects" */
export type Vercel_Projects = {
  __typename?: 'vercel_projects';
  id: Scalars['String'];
  /** An object relationship */
  microsite?: Maybe<Microsites>;
  name: Scalars['String'];
};

/** aggregated selection of "vercel_projects" */
export type Vercel_Projects_Aggregate = {
  __typename?: 'vercel_projects_aggregate';
  aggregate?: Maybe<Vercel_Projects_Aggregate_Fields>;
  nodes: Array<Vercel_Projects>;
};

/** aggregate fields of "vercel_projects" */
export type Vercel_Projects_Aggregate_Fields = {
  __typename?: 'vercel_projects_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Vercel_Projects_Max_Fields>;
  min?: Maybe<Vercel_Projects_Min_Fields>;
};


/** aggregate fields of "vercel_projects" */
export type Vercel_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vercel_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vercel_projects" */
export type Vercel_Projects_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Vercel_Projects_Max_Order_By>;
  min?: Maybe<Vercel_Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vercel_projects" */
export type Vercel_Projects_Arr_Rel_Insert_Input = {
  data: Array<Vercel_Projects_Insert_Input>;
  on_conflict?: Maybe<Vercel_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vercel_projects". All fields are combined with a logical 'AND'. */
export type Vercel_Projects_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Vercel_Projects_Bool_Exp>>>;
  _not?: Maybe<Vercel_Projects_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Vercel_Projects_Bool_Exp>>>;
  id?: Maybe<String_Comparison_Exp>;
  microsite?: Maybe<Microsites_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vercel_projects" */
export enum Vercel_Projects_Constraint {
  /** unique or primary key constraint */
  VercelProjectsNameKey = 'vercel_projects_name_key',
  /** unique or primary key constraint */
  VercelProjectsPkey = 'vercel_projects_pkey'
}

/** input type for inserting data into table "vercel_projects" */
export type Vercel_Projects_Insert_Input = {
  id?: Maybe<Scalars['String']>;
  microsite?: Maybe<Microsites_Obj_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vercel_Projects_Max_Fields = {
  __typename?: 'vercel_projects_max_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vercel_projects" */
export type Vercel_Projects_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Vercel_Projects_Min_Fields = {
  __typename?: 'vercel_projects_min_fields';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vercel_projects" */
export type Vercel_Projects_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "vercel_projects" */
export type Vercel_Projects_Mutation_Response = {
  __typename?: 'vercel_projects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Vercel_Projects>;
};

/** input type for inserting object relation for remote table "vercel_projects" */
export type Vercel_Projects_Obj_Rel_Insert_Input = {
  data: Vercel_Projects_Insert_Input;
  on_conflict?: Maybe<Vercel_Projects_On_Conflict>;
};

/** on conflict condition type for table "vercel_projects" */
export type Vercel_Projects_On_Conflict = {
  constraint: Vercel_Projects_Constraint;
  update_columns: Array<Vercel_Projects_Update_Column>;
  where?: Maybe<Vercel_Projects_Bool_Exp>;
};

/** ordering options when selecting data from "vercel_projects" */
export type Vercel_Projects_Order_By = {
  id?: Maybe<Order_By>;
  microsite?: Maybe<Microsites_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "vercel_projects" */
export type Vercel_Projects_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "vercel_projects" */
export enum Vercel_Projects_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "vercel_projects" */
export type Vercel_Projects_Set_Input = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "vercel_projects" */
export enum Vercel_Projects_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type OndemandSourceFieldsFragment = (
  { __typename?: 'ondemand_sources' }
  & Pick<Ondemand_Sources, 'id' | 'name' | 'provider' | 'src' | 'config'>
);

export type EventSpeakerFieldsFragment = (
  { __typename?: 'speakers' }
  & Pick<Speakers, 'id' | 'avatar' | 'pre_title' | 'firstname' | 'lastname' | 'description' | 'display_name' | 'display_name_academic'>
);

export type EventScheduleFieldsFragment = (
  { __typename?: 'event_schedules' }
  & Pick<Event_Schedules, 'id' | 'ub_id' | 'type_id' | 'title' | 'start_time' | 'end_time' | 'parent_id' | 'ext_link_button_timeline' | 'ondemand_seconds'>
  & { speakers: Array<(
    { __typename?: 'event_schedules_speakers' }
    & { speaker: (
      { __typename?: 'speakers' }
      & EventSpeakerFieldsFragment
    ) }
  )>, accreditation_points: Array<(
    { __typename?: 'event_schedule_accreditation_points' }
    & Pick<Event_Schedule_Accreditation_Points, 'type' | 'points' | 'certified' | 'accreditation_number'>
  )>, ondemand_source?: Maybe<(
    { __typename?: 'ondemand_sources' }
    & OndemandSourceFieldsFragment
  )> }
);

export type EventFieldsFragment = (
  { __typename?: 'events' }
  & { event_schedules: Array<(
    { __typename?: 'event_schedules' }
    & EventScheduleFieldsFragment
  )> }
  & EventFieldsWithoutSchedulesFragment
);

export type EventFieldsWithoutSchedulesFragment = (
  { __typename?: 'events' }
  & Pick<Events, 'id' | 'drupal_id' | 'title' | 'description' | 'start_time' | 'end_time' | 'locale_code' | 'ext_login_url' | 'ext_url' | 'ext_expo_url' | 'ext_downloads_url' | 'cover_image' | 'slides_com_url' | 'support_email' | 'event_sequence' | 'ondemand_player' | 'accreditation_type_id' | 'accreditation_points_short' | 'accreditation_points_info' | 'ondemand_redirect' | 'country' | 'require_efn_number' | 'feedback_url' | 'wonder_room_url'>
  & { event_location?: Maybe<(
    { __typename?: 'event_locations' }
    & Pick<Event_Locations, 'city'>
  )>, accreditation_points: Array<(
    { __typename?: 'event_accreditation_points' }
    & Pick<Event_Accreditation_Points, 'type' | 'points' | 'certified' | 'accreditation_type'>
  )>, event_content: Array<(
    { __typename?: 'event_content' }
    & Pick<Event_Content, 'type_value' | 'text'>
  )> }
);

export type UserFieldsFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'id' | 'first_name' | 'last_name' | 'email' | 'role' | 'disabled' | 'created_at' | 'updated_at'>
);

export type InsertEventScheduleOndemandSourceMutationVariables = Exact<{
  object: Ondemand_Sources_Insert_Input;
}>;


export type InsertEventScheduleOndemandSourceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_ondemand_sources_one?: Maybe<(
    { __typename?: 'ondemand_sources' }
    & OndemandSourceFieldsFragment
  )> }
);

export type UpdateEventScheduleOndemandIdMutationVariables = Exact<{
  event_schedule_id: Scalars['uuid'];
  ondemand_source_id: Scalars['uuid'];
}>;


export type UpdateEventScheduleOndemandIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_event_schedules_by_pk?: Maybe<(
    { __typename?: 'event_schedules' }
    & { ondemand_source?: Maybe<(
      { __typename?: 'ondemand_sources' }
      & OndemandSourceFieldsFragment
    )> }
  )> }
);

export type DeleteEventScheduleOndemandIdMutationVariables = Exact<{
  event_schedule_id: Scalars['uuid'];
}>;


export type DeleteEventScheduleOndemandIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_event_schedules_by_pk?: Maybe<(
    { __typename?: 'event_schedules' }
    & Pick<Event_Schedules, 'id' | 'ondemand_id'>
  )> }
);

export type UpdateEventScheduleExtLinkMutationVariables = Exact<{
  id: Scalars['uuid'];
  ext_link?: Maybe<Scalars['json']>;
}>;


export type UpdateEventScheduleExtLinkMutation = (
  { __typename?: 'mutation_root' }
  & { update_event_schedules_by_pk?: Maybe<(
    { __typename?: 'event_schedules' }
    & Pick<Event_Schedules, 'id' | 'ext_link_button_timeline'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  data: Users_Set_Input;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserFieldsFragment
  )> }
);

export type GetEventSponsoringsByEventQueryVariables = Exact<{
  event_id: Scalars['uuid'];
  type?: Event_Sponsoring_Types_Enum;
}>;


export type GetEventSponsoringsByEventQuery = (
  { __typename?: 'query_root' }
  & { event_sponsorings: Array<(
    { __typename?: 'event_sponsorings' }
    & Pick<Event_Sponsorings, 'id' | 'event_id' | 'company_department_id' | 'config' | 'sponsoring_type'>
    & { company_department: (
      { __typename?: 'company_departments' }
      & { company: (
        { __typename?: 'companies' }
        & Pick<Companies, 'name' | 'logo_url' | 'id'>
      ), users: Array<(
        { __typename?: 'company_departments_users' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name'>
        ) }
      )> }
    ) }
  )> }
);

export type GetEventSponsoringByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEventSponsoringByIdQuery = (
  { __typename?: 'query_root' }
  & { event_sponsorings_by_pk?: Maybe<(
    { __typename?: 'event_sponsorings' }
    & Pick<Event_Sponsorings, 'id' | 'event_id' | 'company_department_id' | 'config' | 'sponsoring_type'>
    & { company_department: (
      { __typename?: 'company_departments' }
      & { company: (
        { __typename?: 'companies' }
        & Pick<Companies, 'name' | 'logo_url' | 'id'>
      ), users: Array<(
        { __typename?: 'company_departments_users' }
        & { user: (
          { __typename?: 'users' }
          & Pick<Users, 'id' | 'first_name' | 'last_name' | 'email'>
        ) }
      )> }
    ) }
  )> }
);

export type GetEventQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetEventQuery = (
  { __typename?: 'query_root' }
  & { events_by_pk?: Maybe<(
    { __typename?: 'events' }
    & EventFieldsFragment
  )> }
);

export type GetAdminEventQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAdminEventQuery = (
  { __typename?: 'query_root' }
  & { events_by_pk?: Maybe<(
    { __typename?: 'events' }
    & Pick<Events, 'reg_from_email' | 'reg_bcc_email'>
    & EventFieldsFragment
  )> }
);

export type GetLiveEventScheduleSubscriptionVariables = Exact<{
  event_id: Scalars['uuid'];
}>;


export type GetLiveEventScheduleSubscription = (
  { __typename?: 'subscription_root' }
  & { event_schedules: Array<(
    { __typename?: 'event_schedules' }
    & EventScheduleFieldsFragment
  )> }
);

export type EventListWithoutSchedulesQueryVariables = Exact<{
  where?: Maybe<Events_Bool_Exp>;
}>;


export type EventListWithoutSchedulesQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & EventFieldsWithoutSchedulesFragment
  )> }
);

export type LivestreamEventsQueryVariables = Exact<{
  _end_time?: Maybe<Scalars['timestamptz']>;
}>;


export type LivestreamEventsQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & { microsites: Array<(
      { __typename?: 'events_microsites' }
      & { microsite: (
        { __typename?: 'microsites' }
        & Pick<Microsites, 'id' | 'name' | 'hostname' | 'logo'>
        & { logo_localized?: Maybe<(
          { __typename?: 'localized_files' }
          & LocalizedFileFieldsFragment
        )>, firebase_project?: Maybe<(
          { __typename?: 'firebase_projects' }
          & Pick<Firebase_Projects, 'serviceAccount' | 'firebaseConfig'>
        )> }
      ) }
    )> }
    & EventFieldsFragment
  )> }
);

export type LivestreamEventsByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type LivestreamEventsByIdQuery = (
  { __typename?: 'query_root' }
  & { events: Array<(
    { __typename?: 'events' }
    & { microsites: Array<(
      { __typename?: 'events_microsites' }
      & { microsite: (
        { __typename?: 'microsites' }
        & Pick<Microsites, 'id' | 'name' | 'hostname' | 'logo'>
        & { logo_localized?: Maybe<(
          { __typename?: 'localized_files' }
          & LocalizedFileFieldsFragment
        )>, firebase_project?: Maybe<(
          { __typename?: 'firebase_projects' }
          & Pick<Firebase_Projects, 'serviceAccount' | 'firebaseConfig'>
        )> }
      ) }
    )> }
    & EventFieldsFragment
  )> }
);

export type GetMicrositesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMicrositesQuery = (
  { __typename?: 'query_root' }
  & { microsites: Array<(
    { __typename?: 'microsites' }
    & Pick<Microsites, 'id' | 'name' | 'hostname' | 'logo'>
    & { logo_localized?: Maybe<(
      { __typename?: 'localized_files' }
      & LocalizedFileFieldsFragment
    )>, firebase_project?: Maybe<(
      { __typename?: 'firebase_projects' }
      & Pick<Firebase_Projects, 'firebaseConfig' | 'serviceAccount'>
    )>, events: Array<(
      { __typename?: 'events_microsites' }
      & { event: (
        { __typename?: 'events' }
        & Pick<Events, 'id' | 'title' | 'locale_code' | 'start_time' | 'end_time'>
      ) }
    )> }
  )> }
);

export type MicrositeConfigFragment = (
  { __typename?: 'microsites' }
  & Pick<Microsites, 'name' | 'hostname' | 'logo'>
  & { logo_localized?: Maybe<(
    { __typename?: 'localized_files' }
    & LocalizedFileFieldsFragment
  )>, ui_theme?: Maybe<(
    { __typename?: 'ui_themes' }
    & Pick<Ui_Themes, 'theme'>
  )>, firebase_project?: Maybe<(
    { __typename?: 'firebase_projects' }
    & Pick<Firebase_Projects, 'firebaseConfig'>
  )> }
);

export type LocalizedFileFieldsFragment = (
  { __typename?: 'localized_files' }
  & Pick<Localized_Files, 'id' | 'firebase_project_id' | 'default' | 'de' | 'de_AT' | 'de_CH' | 'en_GB' | 'fr_CH'>
);

export type GetMicrositeConfigQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMicrositeConfigQuery = (
  { __typename?: 'query_root' }
  & { microsites_by_pk?: Maybe<(
    { __typename?: 'microsites' }
    & MicrositeConfigFragment
  )> }
);

export type GetAdminMicrositeConfigQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAdminMicrositeConfigQuery = (
  { __typename?: 'query_root' }
  & { microsites_by_pk?: Maybe<(
    { __typename?: 'microsites' }
    & MicrositeConfigFragment
    & MicrositeAdminFieldsFragment
  )> }
);

export type GetMicrositeConfigWithTranslationsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMicrositeConfigWithTranslationsQuery = (
  { __typename?: 'query_root' }
  & { microsites_by_pk?: Maybe<(
    { __typename?: 'microsites' }
    & MicrositeConfigFragment
  )>, string_translations: Array<(
    { __typename?: 'string_translations' }
    & StringTranslationFieldsFragment
  )> }
);

export type GetMicrositeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMicrositeQuery = (
  { __typename?: 'query_root' }
  & { microsites_by_pk?: Maybe<(
    { __typename?: 'microsites' }
    & Pick<Microsites, 'name' | 'hostname' | 'logo'>
    & { logo_localized?: Maybe<(
      { __typename?: 'localized_files' }
      & LocalizedFileFieldsFragment
    )>, ui_theme?: Maybe<(
      { __typename?: 'ui_themes' }
      & Pick<Ui_Themes, 'theme'>
    )>, events: Array<(
      { __typename?: 'events_microsites' }
      & Pick<Events_Microsites, 'event_id'>
      & { event: (
        { __typename?: 'events' }
        & Pick<Events, 'title' | 'locale_code'>
      ) }
    )> }
  )> }
);

export type MicrositeAdminFieldsFragment = (
  { __typename?: 'microsites' }
  & Pick<Microsites, 'id' | 'name' | 'logo' | 'hostname' | 'firebase_project_id'>
  & { logo_localized?: Maybe<(
    { __typename?: 'localized_files' }
    & LocalizedFileFieldsFragment
  )>, firebase_project?: Maybe<(
    { __typename?: 'firebase_projects' }
    & Pick<Firebase_Projects, 'serviceAccount' | 'firebaseConfig'>
  )>, ui_theme?: Maybe<(
    { __typename?: 'ui_themes' }
    & Pick<Ui_Themes, 'theme'>
  )> }
);

export type GetMicrositesByEventQueryVariables = Exact<{
  event_id: Scalars['uuid'];
}>;


export type GetMicrositesByEventQuery = (
  { __typename?: 'query_root' }
  & { microsites: Array<(
    { __typename?: 'microsites' }
    & MicrositeAdminFieldsFragment
  )> }
);

export type GetMicrositeEventsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetMicrositeEventsQuery = (
  { __typename?: 'query_root' }
  & { microsites_by_pk?: Maybe<(
    { __typename?: 'microsites' }
    & { events: Array<(
      { __typename?: 'events_microsites' }
      & { event: (
        { __typename?: 'events' }
        & Pick<Events, 'id' | 'title' | 'locale_code' | 'start_time' | 'end_time' | 'ondemand_redirect' | 'event_sequence'>
      ) }
    )> }
  )> }
);

export type GetFirebaseProjectQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetFirebaseProjectQuery = (
  { __typename?: 'query_root' }
  & { firebase_projects_by_pk?: Maybe<(
    { __typename?: 'firebase_projects' }
    & Pick<Firebase_Projects, 'firebaseConfig' | 'serviceAccount'>
  )> }
);

export type GetFirebaseProjectsByMicrositeIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFirebaseProjectsByMicrositeIdQuery = (
  { __typename?: 'query_root' }
  & { firebase_projects: Array<(
    { __typename?: 'firebase_projects' }
    & Pick<Firebase_Projects, 'id' | 'firebaseConfig' | 'serviceAccount'>
  )> }
);

export type GetVercelProjectsByMicrositeIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetVercelProjectsByMicrositeIdQuery = (
  { __typename?: 'query_root' }
  & { vercel_projects: Array<(
    { __typename?: 'vercel_projects' }
    & Pick<Vercel_Projects, 'id' | 'name'>
  )> }
);

export type GetVercelProjectsByEventIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetVercelProjectsByEventIdQuery = (
  { __typename?: 'query_root' }
  & { vercel_projects: Array<(
    { __typename?: 'vercel_projects' }
    & Pick<Vercel_Projects, 'id' | 'name'>
  )> }
);

export type GetFirebaseProjectsByEventIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFirebaseProjectsByEventIdQuery = (
  { __typename?: 'query_root' }
  & { firebase_projects: Array<(
    { __typename?: 'firebase_projects' }
    & Pick<Firebase_Projects, 'id'>
  )> }
);

export type StringTranslationFieldsFragment = (
  { __typename?: 'string_translations' }
  & Pick<String_Translations, 'id' | 'source_string' | 'de' | 'de_AT' | 'de_CH' | 'en_GB' | 'fr_CH'>
);

export type GetStringTranslationsByIdsQueryVariables = Exact<{
  ids?: Maybe<Array<Scalars['String']>>;
}>;


export type GetStringTranslationsByIdsQuery = (
  { __typename?: 'query_root' }
  & { string_translations: Array<(
    { __typename?: 'string_translations' }
    & StringTranslationFieldsFragment
  )> }
);

export type GetUsersByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type GetUsersByEmailQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & UserFieldsFragment
  )> }
);

export const EventFieldsWithoutSchedulesFragmentDoc = gql`
    fragment eventFieldsWithoutSchedules on events {
  id
  drupal_id
  title
  description
  start_time
  end_time
  locale_code
  ext_login_url
  ext_url
  ext_expo_url
  ext_downloads_url
  event_location {
    city
  }
  cover_image
  slides_com_url
  support_email
  event_sequence
  ondemand_player
  accreditation_type_id
  accreditation_points {
    type
    points
    certified
    accreditation_type
  }
  accreditation_points_short
  accreditation_points_info
  event_content {
    type_value
    text
  }
  ondemand_redirect
  ext_login_url
  country
  require_efn_number
  feedback_url
  wonder_room_url
}
    `;
export const EventSpeakerFieldsFragmentDoc = gql`
    fragment eventSpeakerFields on speakers {
  id
  avatar
  pre_title
  firstname
  lastname
  description
  display_name
  display_name_academic
}
    `;
export const OndemandSourceFieldsFragmentDoc = gql`
    fragment ondemandSourceFields on ondemand_sources {
  id
  name
  provider
  src
  config
}
    `;
export const EventScheduleFieldsFragmentDoc = gql`
    fragment eventScheduleFields on event_schedules {
  id
  ub_id
  type_id
  title
  start_time
  end_time
  parent_id
  ext_link_button_timeline
  speakers {
    speaker {
      ...eventSpeakerFields
    }
  }
  accreditation_points {
    type
    points
    certified
    accreditation_number
  }
  ondemand_source {
    ...ondemandSourceFields
  }
  ondemand_seconds
}
    ${EventSpeakerFieldsFragmentDoc}
${OndemandSourceFieldsFragmentDoc}`;
export const EventFieldsFragmentDoc = gql`
    fragment eventFields on events {
  ...eventFieldsWithoutSchedules
  event_schedules(
    order_by: {start_time: asc, end_time: desc, type: {name: asc}}
    where: {type_id: {_in: [day, block, stream, topic_block, lecture, org, click, symposium, start_or_end]}}
  ) {
    ...eventScheduleFields
  }
}
    ${EventFieldsWithoutSchedulesFragmentDoc}
${EventScheduleFieldsFragmentDoc}`;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on users {
  id
  first_name
  last_name
  email
  role
  disabled
  created_at
  updated_at
}
    `;
export const LocalizedFileFieldsFragmentDoc = gql`
    fragment localizedFileFields on localized_files {
  id
  firebase_project_id
  default
  de
  de_AT
  de_CH
  en_GB
  fr_CH
}
    `;
export const MicrositeConfigFragmentDoc = gql`
    fragment micrositeConfig on microsites {
  name
  hostname
  logo
  logo_localized {
    ...localizedFileFields
  }
  ui_theme {
    theme
  }
  firebase_project {
    firebaseConfig
  }
}
    ${LocalizedFileFieldsFragmentDoc}`;
export const MicrositeAdminFieldsFragmentDoc = gql`
    fragment micrositeAdminFields on microsites {
  id
  name
  logo
  logo_localized {
    ...localizedFileFields
  }
  hostname
  firebase_project_id
  firebase_project {
    serviceAccount
    firebaseConfig
  }
  ui_theme {
    theme
  }
}
    ${LocalizedFileFieldsFragmentDoc}`;
export const StringTranslationFieldsFragmentDoc = gql`
    fragment stringTranslationFields on string_translations {
  id
  source_string
  de
  de_AT
  de_CH
  en_GB
  fr_CH
}
    `;
export const InsertEventScheduleOndemandSourceDocument = gql`
    mutation InsertEventScheduleOndemandSource($object: ondemand_sources_insert_input!) {
  insert_ondemand_sources_one(
    object: $object
    on_conflict: {constraint: ondemand_sources_pkey, update_columns: [name, provider, src, config]}
  ) {
    ...ondemandSourceFields
  }
}
    ${OndemandSourceFieldsFragmentDoc}`;
export type InsertEventScheduleOndemandSourceMutationFn = Apollo.MutationFunction<InsertEventScheduleOndemandSourceMutation, InsertEventScheduleOndemandSourceMutationVariables>;

/**
 * __useInsertEventScheduleOndemandSourceMutation__
 *
 * To run a mutation, you first call `useInsertEventScheduleOndemandSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEventScheduleOndemandSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEventScheduleOndemandSourceMutation, { data, loading, error }] = useInsertEventScheduleOndemandSourceMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertEventScheduleOndemandSourceMutation(baseOptions?: Apollo.MutationHookOptions<InsertEventScheduleOndemandSourceMutation, InsertEventScheduleOndemandSourceMutationVariables>) {
        return Apollo.useMutation<InsertEventScheduleOndemandSourceMutation, InsertEventScheduleOndemandSourceMutationVariables>(InsertEventScheduleOndemandSourceDocument, baseOptions);
      }
export type InsertEventScheduleOndemandSourceMutationHookResult = ReturnType<typeof useInsertEventScheduleOndemandSourceMutation>;
export type InsertEventScheduleOndemandSourceMutationResult = Apollo.MutationResult<InsertEventScheduleOndemandSourceMutation>;
export type InsertEventScheduleOndemandSourceMutationOptions = Apollo.BaseMutationOptions<InsertEventScheduleOndemandSourceMutation, InsertEventScheduleOndemandSourceMutationVariables>;
export const UpdateEventScheduleOndemandIdDocument = gql`
    mutation UpdateEventScheduleOndemandId($event_schedule_id: uuid!, $ondemand_source_id: uuid!) {
  update_event_schedules_by_pk(
    pk_columns: {id: $event_schedule_id}
    _set: {ondemand_id: $ondemand_source_id}
  ) {
    ondemand_source {
      ...ondemandSourceFields
    }
  }
}
    ${OndemandSourceFieldsFragmentDoc}`;
export type UpdateEventScheduleOndemandIdMutationFn = Apollo.MutationFunction<UpdateEventScheduleOndemandIdMutation, UpdateEventScheduleOndemandIdMutationVariables>;

/**
 * __useUpdateEventScheduleOndemandIdMutation__
 *
 * To run a mutation, you first call `useUpdateEventScheduleOndemandIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventScheduleOndemandIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventScheduleOndemandIdMutation, { data, loading, error }] = useUpdateEventScheduleOndemandIdMutation({
 *   variables: {
 *      event_schedule_id: // value for 'event_schedule_id'
 *      ondemand_source_id: // value for 'ondemand_source_id'
 *   },
 * });
 */
export function useUpdateEventScheduleOndemandIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventScheduleOndemandIdMutation, UpdateEventScheduleOndemandIdMutationVariables>) {
        return Apollo.useMutation<UpdateEventScheduleOndemandIdMutation, UpdateEventScheduleOndemandIdMutationVariables>(UpdateEventScheduleOndemandIdDocument, baseOptions);
      }
export type UpdateEventScheduleOndemandIdMutationHookResult = ReturnType<typeof useUpdateEventScheduleOndemandIdMutation>;
export type UpdateEventScheduleOndemandIdMutationResult = Apollo.MutationResult<UpdateEventScheduleOndemandIdMutation>;
export type UpdateEventScheduleOndemandIdMutationOptions = Apollo.BaseMutationOptions<UpdateEventScheduleOndemandIdMutation, UpdateEventScheduleOndemandIdMutationVariables>;
export const DeleteEventScheduleOndemandIdDocument = gql`
    mutation DeleteEventScheduleOndemandId($event_schedule_id: uuid!) {
  update_event_schedules_by_pk(
    pk_columns: {id: $event_schedule_id}
    _set: {ondemand_id: null}
  ) {
    id
    ondemand_id
  }
}
    `;
export type DeleteEventScheduleOndemandIdMutationFn = Apollo.MutationFunction<DeleteEventScheduleOndemandIdMutation, DeleteEventScheduleOndemandIdMutationVariables>;

/**
 * __useDeleteEventScheduleOndemandIdMutation__
 *
 * To run a mutation, you first call `useDeleteEventScheduleOndemandIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventScheduleOndemandIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventScheduleOndemandIdMutation, { data, loading, error }] = useDeleteEventScheduleOndemandIdMutation({
 *   variables: {
 *      event_schedule_id: // value for 'event_schedule_id'
 *   },
 * });
 */
export function useDeleteEventScheduleOndemandIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventScheduleOndemandIdMutation, DeleteEventScheduleOndemandIdMutationVariables>) {
        return Apollo.useMutation<DeleteEventScheduleOndemandIdMutation, DeleteEventScheduleOndemandIdMutationVariables>(DeleteEventScheduleOndemandIdDocument, baseOptions);
      }
export type DeleteEventScheduleOndemandIdMutationHookResult = ReturnType<typeof useDeleteEventScheduleOndemandIdMutation>;
export type DeleteEventScheduleOndemandIdMutationResult = Apollo.MutationResult<DeleteEventScheduleOndemandIdMutation>;
export type DeleteEventScheduleOndemandIdMutationOptions = Apollo.BaseMutationOptions<DeleteEventScheduleOndemandIdMutation, DeleteEventScheduleOndemandIdMutationVariables>;
export const UpdateEventScheduleExtLinkDocument = gql`
    mutation UpdateEventScheduleExtLink($id: uuid!, $ext_link: json) {
  update_event_schedules_by_pk(
    pk_columns: {id: $id}
    _set: {ext_link_button_timeline: $ext_link}
  ) {
    id
    ext_link_button_timeline
  }
}
    `;
export type UpdateEventScheduleExtLinkMutationFn = Apollo.MutationFunction<UpdateEventScheduleExtLinkMutation, UpdateEventScheduleExtLinkMutationVariables>;

/**
 * __useUpdateEventScheduleExtLinkMutation__
 *
 * To run a mutation, you first call `useUpdateEventScheduleExtLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventScheduleExtLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventScheduleExtLinkMutation, { data, loading, error }] = useUpdateEventScheduleExtLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ext_link: // value for 'ext_link'
 *   },
 * });
 */
export function useUpdateEventScheduleExtLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventScheduleExtLinkMutation, UpdateEventScheduleExtLinkMutationVariables>) {
        return Apollo.useMutation<UpdateEventScheduleExtLinkMutation, UpdateEventScheduleExtLinkMutationVariables>(UpdateEventScheduleExtLinkDocument, baseOptions);
      }
export type UpdateEventScheduleExtLinkMutationHookResult = ReturnType<typeof useUpdateEventScheduleExtLinkMutation>;
export type UpdateEventScheduleExtLinkMutationResult = Apollo.MutationResult<UpdateEventScheduleExtLinkMutation>;
export type UpdateEventScheduleExtLinkMutationOptions = Apollo.BaseMutationOptions<UpdateEventScheduleExtLinkMutation, UpdateEventScheduleExtLinkMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $data: users_set_input!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: $data) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetEventSponsoringsByEventDocument = gql`
    query GetEventSponsoringsByEvent($event_id: uuid!, $type: event_sponsoring_types_enum! = expo) {
  event_sponsorings(
    where: {sponsoring_type: {_eq: $type}, event_id: {_eq: $event_id}}
  ) {
    id
    event_id
    company_department_id
    config
    sponsoring_type
    company_department {
      company {
        name
        logo_url
        id
      }
      users {
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventSponsoringsByEventQuery__
 *
 * To run a query within a React component, call `useGetEventSponsoringsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventSponsoringsByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventSponsoringsByEventQuery({
 *   variables: {
 *      event_id: // value for 'event_id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetEventSponsoringsByEventQuery(baseOptions?: Apollo.QueryHookOptions<GetEventSponsoringsByEventQuery, GetEventSponsoringsByEventQueryVariables>) {
        return Apollo.useQuery<GetEventSponsoringsByEventQuery, GetEventSponsoringsByEventQueryVariables>(GetEventSponsoringsByEventDocument, baseOptions);
      }
export function useGetEventSponsoringsByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventSponsoringsByEventQuery, GetEventSponsoringsByEventQueryVariables>) {
          return Apollo.useLazyQuery<GetEventSponsoringsByEventQuery, GetEventSponsoringsByEventQueryVariables>(GetEventSponsoringsByEventDocument, baseOptions);
        }
export type GetEventSponsoringsByEventQueryHookResult = ReturnType<typeof useGetEventSponsoringsByEventQuery>;
export type GetEventSponsoringsByEventLazyQueryHookResult = ReturnType<typeof useGetEventSponsoringsByEventLazyQuery>;
export type GetEventSponsoringsByEventQueryResult = Apollo.QueryResult<GetEventSponsoringsByEventQuery, GetEventSponsoringsByEventQueryVariables>;
export const GetEventSponsoringByIdDocument = gql`
    query GetEventSponsoringById($id: uuid!) {
  event_sponsorings_by_pk(id: $id) {
    id
    event_id
    company_department_id
    config
    sponsoring_type
    company_department {
      company {
        name
        logo_url
        id
      }
      users {
        user {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
}
    `;

/**
 * __useGetEventSponsoringByIdQuery__
 *
 * To run a query within a React component, call `useGetEventSponsoringByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventSponsoringByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventSponsoringByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventSponsoringByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetEventSponsoringByIdQuery, GetEventSponsoringByIdQueryVariables>) {
        return Apollo.useQuery<GetEventSponsoringByIdQuery, GetEventSponsoringByIdQueryVariables>(GetEventSponsoringByIdDocument, baseOptions);
      }
export function useGetEventSponsoringByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventSponsoringByIdQuery, GetEventSponsoringByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetEventSponsoringByIdQuery, GetEventSponsoringByIdQueryVariables>(GetEventSponsoringByIdDocument, baseOptions);
        }
export type GetEventSponsoringByIdQueryHookResult = ReturnType<typeof useGetEventSponsoringByIdQuery>;
export type GetEventSponsoringByIdLazyQueryHookResult = ReturnType<typeof useGetEventSponsoringByIdLazyQuery>;
export type GetEventSponsoringByIdQueryResult = Apollo.QueryResult<GetEventSponsoringByIdQuery, GetEventSponsoringByIdQueryVariables>;
export const GetEventDocument = gql`
    query GetEvent($id: uuid!) {
  events_by_pk(id: $id) {
    ...eventFields
  }
}
    ${EventFieldsFragmentDoc}`;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions?: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, baseOptions);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, baseOptions);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetAdminEventDocument = gql`
    query GetAdminEvent($id: uuid!) {
  events_by_pk(id: $id) {
    ...eventFields
    reg_from_email
    reg_bcc_email
  }
}
    ${EventFieldsFragmentDoc}`;

/**
 * __useGetAdminEventQuery__
 *
 * To run a query within a React component, call `useGetAdminEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminEventQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminEventQuery, GetAdminEventQueryVariables>) {
        return Apollo.useQuery<GetAdminEventQuery, GetAdminEventQueryVariables>(GetAdminEventDocument, baseOptions);
      }
export function useGetAdminEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminEventQuery, GetAdminEventQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminEventQuery, GetAdminEventQueryVariables>(GetAdminEventDocument, baseOptions);
        }
export type GetAdminEventQueryHookResult = ReturnType<typeof useGetAdminEventQuery>;
export type GetAdminEventLazyQueryHookResult = ReturnType<typeof useGetAdminEventLazyQuery>;
export type GetAdminEventQueryResult = Apollo.QueryResult<GetAdminEventQuery, GetAdminEventQueryVariables>;
export const GetLiveEventScheduleDocument = gql`
    subscription GetLiveEventSchedule($event_id: uuid!) {
  event_schedules(
    where: {_and: {event_id: {_eq: $event_id}, type_id: {_in: [day, block, stream, topic_block, lecture, org, click, symposium, start_or_end]}}}
    order_by: {start_time: asc, end_time: desc, type: {name: asc}}
  ) {
    ...eventScheduleFields
  }
}
    ${EventScheduleFieldsFragmentDoc}`;

/**
 * __useGetLiveEventScheduleSubscription__
 *
 * To run a query within a React component, call `useGetLiveEventScheduleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveEventScheduleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveEventScheduleSubscription({
 *   variables: {
 *      event_id: // value for 'event_id'
 *   },
 * });
 */
export function useGetLiveEventScheduleSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetLiveEventScheduleSubscription, GetLiveEventScheduleSubscriptionVariables>) {
        return Apollo.useSubscription<GetLiveEventScheduleSubscription, GetLiveEventScheduleSubscriptionVariables>(GetLiveEventScheduleDocument, baseOptions);
      }
export type GetLiveEventScheduleSubscriptionHookResult = ReturnType<typeof useGetLiveEventScheduleSubscription>;
export type GetLiveEventScheduleSubscriptionResult = Apollo.SubscriptionResult<GetLiveEventScheduleSubscription>;
export const EventListWithoutSchedulesDocument = gql`
    query EventListWithoutSchedules($where: events_bool_exp = {}) {
  events(where: $where, order_by: {start_time: desc}) {
    ...eventFieldsWithoutSchedules
  }
}
    ${EventFieldsWithoutSchedulesFragmentDoc}`;

/**
 * __useEventListWithoutSchedulesQuery__
 *
 * To run a query within a React component, call `useEventListWithoutSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListWithoutSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListWithoutSchedulesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEventListWithoutSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<EventListWithoutSchedulesQuery, EventListWithoutSchedulesQueryVariables>) {
        return Apollo.useQuery<EventListWithoutSchedulesQuery, EventListWithoutSchedulesQueryVariables>(EventListWithoutSchedulesDocument, baseOptions);
      }
export function useEventListWithoutSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventListWithoutSchedulesQuery, EventListWithoutSchedulesQueryVariables>) {
          return Apollo.useLazyQuery<EventListWithoutSchedulesQuery, EventListWithoutSchedulesQueryVariables>(EventListWithoutSchedulesDocument, baseOptions);
        }
export type EventListWithoutSchedulesQueryHookResult = ReturnType<typeof useEventListWithoutSchedulesQuery>;
export type EventListWithoutSchedulesLazyQueryHookResult = ReturnType<typeof useEventListWithoutSchedulesLazyQuery>;
export type EventListWithoutSchedulesQueryResult = Apollo.QueryResult<EventListWithoutSchedulesQuery, EventListWithoutSchedulesQueryVariables>;
export const LivestreamEventsDocument = gql`
    query LivestreamEvents($_end_time: timestamptz = "now()") {
  events(where: {_not: {end_time: {_lt: $_end_time}}}) {
    ...eventFields
    microsites {
      microsite {
        id
        name
        hostname
        logo
        logo_localized {
          ...localizedFileFields
        }
        firebase_project {
          serviceAccount
          firebaseConfig
        }
      }
    }
  }
}
    ${EventFieldsFragmentDoc}
${LocalizedFileFieldsFragmentDoc}`;

/**
 * __useLivestreamEventsQuery__
 *
 * To run a query within a React component, call `useLivestreamEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivestreamEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivestreamEventsQuery({
 *   variables: {
 *      _end_time: // value for '_end_time'
 *   },
 * });
 */
export function useLivestreamEventsQuery(baseOptions?: Apollo.QueryHookOptions<LivestreamEventsQuery, LivestreamEventsQueryVariables>) {
        return Apollo.useQuery<LivestreamEventsQuery, LivestreamEventsQueryVariables>(LivestreamEventsDocument, baseOptions);
      }
export function useLivestreamEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivestreamEventsQuery, LivestreamEventsQueryVariables>) {
          return Apollo.useLazyQuery<LivestreamEventsQuery, LivestreamEventsQueryVariables>(LivestreamEventsDocument, baseOptions);
        }
export type LivestreamEventsQueryHookResult = ReturnType<typeof useLivestreamEventsQuery>;
export type LivestreamEventsLazyQueryHookResult = ReturnType<typeof useLivestreamEventsLazyQuery>;
export type LivestreamEventsQueryResult = Apollo.QueryResult<LivestreamEventsQuery, LivestreamEventsQueryVariables>;
export const LivestreamEventsByIdDocument = gql`
    query LivestreamEventsById($id: uuid) {
  events(where: {id: {_eq: $id}}) {
    ...eventFields
    microsites {
      microsite {
        id
        name
        hostname
        logo
        logo_localized {
          ...localizedFileFields
        }
        firebase_project {
          serviceAccount
          firebaseConfig
        }
      }
    }
  }
}
    ${EventFieldsFragmentDoc}
${LocalizedFileFieldsFragmentDoc}`;

/**
 * __useLivestreamEventsByIdQuery__
 *
 * To run a query within a React component, call `useLivestreamEventsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivestreamEventsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivestreamEventsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLivestreamEventsByIdQuery(baseOptions?: Apollo.QueryHookOptions<LivestreamEventsByIdQuery, LivestreamEventsByIdQueryVariables>) {
        return Apollo.useQuery<LivestreamEventsByIdQuery, LivestreamEventsByIdQueryVariables>(LivestreamEventsByIdDocument, baseOptions);
      }
export function useLivestreamEventsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivestreamEventsByIdQuery, LivestreamEventsByIdQueryVariables>) {
          return Apollo.useLazyQuery<LivestreamEventsByIdQuery, LivestreamEventsByIdQueryVariables>(LivestreamEventsByIdDocument, baseOptions);
        }
export type LivestreamEventsByIdQueryHookResult = ReturnType<typeof useLivestreamEventsByIdQuery>;
export type LivestreamEventsByIdLazyQueryHookResult = ReturnType<typeof useLivestreamEventsByIdLazyQuery>;
export type LivestreamEventsByIdQueryResult = Apollo.QueryResult<LivestreamEventsByIdQuery, LivestreamEventsByIdQueryVariables>;
export const GetMicrositesDocument = gql`
    query GetMicrosites {
  microsites {
    id
    name
    hostname
    logo
    logo_localized {
      ...localizedFileFields
    }
    firebase_project {
      firebaseConfig
      serviceAccount
    }
    events {
      event {
        id
        title
        locale_code
        start_time
        end_time
      }
    }
  }
}
    ${LocalizedFileFieldsFragmentDoc}`;

/**
 * __useGetMicrositesQuery__
 *
 * To run a query within a React component, call `useGetMicrositesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMicrositesQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositesQuery, GetMicrositesQueryVariables>) {
        return Apollo.useQuery<GetMicrositesQuery, GetMicrositesQueryVariables>(GetMicrositesDocument, baseOptions);
      }
export function useGetMicrositesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositesQuery, GetMicrositesQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositesQuery, GetMicrositesQueryVariables>(GetMicrositesDocument, baseOptions);
        }
export type GetMicrositesQueryHookResult = ReturnType<typeof useGetMicrositesQuery>;
export type GetMicrositesLazyQueryHookResult = ReturnType<typeof useGetMicrositesLazyQuery>;
export type GetMicrositesQueryResult = Apollo.QueryResult<GetMicrositesQuery, GetMicrositesQueryVariables>;
export const GetMicrositeConfigDocument = gql`
    query GetMicrositeConfig($id: uuid!) {
  microsites_by_pk(id: $id) {
    ...micrositeConfig
  }
}
    ${MicrositeConfigFragmentDoc}`;

/**
 * __useGetMicrositeConfigQuery__
 *
 * To run a query within a React component, call `useGetMicrositeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositeConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMicrositeConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositeConfigQuery, GetMicrositeConfigQueryVariables>) {
        return Apollo.useQuery<GetMicrositeConfigQuery, GetMicrositeConfigQueryVariables>(GetMicrositeConfigDocument, baseOptions);
      }
export function useGetMicrositeConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositeConfigQuery, GetMicrositeConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositeConfigQuery, GetMicrositeConfigQueryVariables>(GetMicrositeConfigDocument, baseOptions);
        }
export type GetMicrositeConfigQueryHookResult = ReturnType<typeof useGetMicrositeConfigQuery>;
export type GetMicrositeConfigLazyQueryHookResult = ReturnType<typeof useGetMicrositeConfigLazyQuery>;
export type GetMicrositeConfigQueryResult = Apollo.QueryResult<GetMicrositeConfigQuery, GetMicrositeConfigQueryVariables>;
export const GetAdminMicrositeConfigDocument = gql`
    query GetAdminMicrositeConfig($id: uuid!) {
  microsites_by_pk(id: $id) {
    ...micrositeConfig
    ...micrositeAdminFields
  }
}
    ${MicrositeConfigFragmentDoc}
${MicrositeAdminFieldsFragmentDoc}`;

/**
 * __useGetAdminMicrositeConfigQuery__
 *
 * To run a query within a React component, call `useGetAdminMicrositeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminMicrositeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminMicrositeConfigQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminMicrositeConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminMicrositeConfigQuery, GetAdminMicrositeConfigQueryVariables>) {
        return Apollo.useQuery<GetAdminMicrositeConfigQuery, GetAdminMicrositeConfigQueryVariables>(GetAdminMicrositeConfigDocument, baseOptions);
      }
export function useGetAdminMicrositeConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminMicrositeConfigQuery, GetAdminMicrositeConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetAdminMicrositeConfigQuery, GetAdminMicrositeConfigQueryVariables>(GetAdminMicrositeConfigDocument, baseOptions);
        }
export type GetAdminMicrositeConfigQueryHookResult = ReturnType<typeof useGetAdminMicrositeConfigQuery>;
export type GetAdminMicrositeConfigLazyQueryHookResult = ReturnType<typeof useGetAdminMicrositeConfigLazyQuery>;
export type GetAdminMicrositeConfigQueryResult = Apollo.QueryResult<GetAdminMicrositeConfigQuery, GetAdminMicrositeConfigQueryVariables>;
export const GetMicrositeConfigWithTranslationsDocument = gql`
    query GetMicrositeConfigWithTranslations($id: uuid!) {
  microsites_by_pk(id: $id) {
    ...micrositeConfig
  }
  string_translations {
    ...stringTranslationFields
  }
}
    ${MicrositeConfigFragmentDoc}
${StringTranslationFieldsFragmentDoc}`;

/**
 * __useGetMicrositeConfigWithTranslationsQuery__
 *
 * To run a query within a React component, call `useGetMicrositeConfigWithTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositeConfigWithTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositeConfigWithTranslationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMicrositeConfigWithTranslationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositeConfigWithTranslationsQuery, GetMicrositeConfigWithTranslationsQueryVariables>) {
        return Apollo.useQuery<GetMicrositeConfigWithTranslationsQuery, GetMicrositeConfigWithTranslationsQueryVariables>(GetMicrositeConfigWithTranslationsDocument, baseOptions);
      }
export function useGetMicrositeConfigWithTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositeConfigWithTranslationsQuery, GetMicrositeConfigWithTranslationsQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositeConfigWithTranslationsQuery, GetMicrositeConfigWithTranslationsQueryVariables>(GetMicrositeConfigWithTranslationsDocument, baseOptions);
        }
export type GetMicrositeConfigWithTranslationsQueryHookResult = ReturnType<typeof useGetMicrositeConfigWithTranslationsQuery>;
export type GetMicrositeConfigWithTranslationsLazyQueryHookResult = ReturnType<typeof useGetMicrositeConfigWithTranslationsLazyQuery>;
export type GetMicrositeConfigWithTranslationsQueryResult = Apollo.QueryResult<GetMicrositeConfigWithTranslationsQuery, GetMicrositeConfigWithTranslationsQueryVariables>;
export const GetMicrositeDocument = gql`
    query GetMicrosite($id: uuid!) {
  microsites_by_pk(id: $id) {
    name
    hostname
    logo
    logo_localized {
      ...localizedFileFields
    }
    ui_theme {
      theme
    }
    events {
      event_id
      event {
        title
        locale_code
      }
    }
  }
}
    ${LocalizedFileFieldsFragmentDoc}`;

/**
 * __useGetMicrositeQuery__
 *
 * To run a query within a React component, call `useGetMicrositeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMicrositeQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositeQuery, GetMicrositeQueryVariables>) {
        return Apollo.useQuery<GetMicrositeQuery, GetMicrositeQueryVariables>(GetMicrositeDocument, baseOptions);
      }
export function useGetMicrositeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositeQuery, GetMicrositeQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositeQuery, GetMicrositeQueryVariables>(GetMicrositeDocument, baseOptions);
        }
export type GetMicrositeQueryHookResult = ReturnType<typeof useGetMicrositeQuery>;
export type GetMicrositeLazyQueryHookResult = ReturnType<typeof useGetMicrositeLazyQuery>;
export type GetMicrositeQueryResult = Apollo.QueryResult<GetMicrositeQuery, GetMicrositeQueryVariables>;
export const GetMicrositesByEventDocument = gql`
    query GetMicrositesByEvent($event_id: uuid!) {
  microsites(where: {events: {event_id: {_eq: $event_id}}}) {
    ...micrositeAdminFields
  }
}
    ${MicrositeAdminFieldsFragmentDoc}`;

/**
 * __useGetMicrositesByEventQuery__
 *
 * To run a query within a React component, call `useGetMicrositesByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositesByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositesByEventQuery({
 *   variables: {
 *      event_id: // value for 'event_id'
 *   },
 * });
 */
export function useGetMicrositesByEventQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositesByEventQuery, GetMicrositesByEventQueryVariables>) {
        return Apollo.useQuery<GetMicrositesByEventQuery, GetMicrositesByEventQueryVariables>(GetMicrositesByEventDocument, baseOptions);
      }
export function useGetMicrositesByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositesByEventQuery, GetMicrositesByEventQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositesByEventQuery, GetMicrositesByEventQueryVariables>(GetMicrositesByEventDocument, baseOptions);
        }
export type GetMicrositesByEventQueryHookResult = ReturnType<typeof useGetMicrositesByEventQuery>;
export type GetMicrositesByEventLazyQueryHookResult = ReturnType<typeof useGetMicrositesByEventLazyQuery>;
export type GetMicrositesByEventQueryResult = Apollo.QueryResult<GetMicrositesByEventQuery, GetMicrositesByEventQueryVariables>;
export const GetMicrositeEventsDocument = gql`
    query GetMicrositeEvents($id: uuid!) {
  microsites_by_pk(id: $id) {
    events(order_by: {event: {start_time: desc}}) {
      event {
        id
        title
        locale_code
        start_time
        end_time
        ondemand_redirect
        event_sequence
      }
    }
  }
}
    `;

/**
 * __useGetMicrositeEventsQuery__
 *
 * To run a query within a React component, call `useGetMicrositeEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMicrositeEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMicrositeEventsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMicrositeEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetMicrositeEventsQuery, GetMicrositeEventsQueryVariables>) {
        return Apollo.useQuery<GetMicrositeEventsQuery, GetMicrositeEventsQueryVariables>(GetMicrositeEventsDocument, baseOptions);
      }
export function useGetMicrositeEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMicrositeEventsQuery, GetMicrositeEventsQueryVariables>) {
          return Apollo.useLazyQuery<GetMicrositeEventsQuery, GetMicrositeEventsQueryVariables>(GetMicrositeEventsDocument, baseOptions);
        }
export type GetMicrositeEventsQueryHookResult = ReturnType<typeof useGetMicrositeEventsQuery>;
export type GetMicrositeEventsLazyQueryHookResult = ReturnType<typeof useGetMicrositeEventsLazyQuery>;
export type GetMicrositeEventsQueryResult = Apollo.QueryResult<GetMicrositeEventsQuery, GetMicrositeEventsQueryVariables>;
export const GetFirebaseProjectDocument = gql`
    query GetFirebaseProject($id: String!) {
  firebase_projects_by_pk(id: $id) {
    firebaseConfig
    serviceAccount
  }
}
    `;

/**
 * __useGetFirebaseProjectQuery__
 *
 * To run a query within a React component, call `useGetFirebaseProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirebaseProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFirebaseProjectQuery(baseOptions?: Apollo.QueryHookOptions<GetFirebaseProjectQuery, GetFirebaseProjectQueryVariables>) {
        return Apollo.useQuery<GetFirebaseProjectQuery, GetFirebaseProjectQueryVariables>(GetFirebaseProjectDocument, baseOptions);
      }
export function useGetFirebaseProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirebaseProjectQuery, GetFirebaseProjectQueryVariables>) {
          return Apollo.useLazyQuery<GetFirebaseProjectQuery, GetFirebaseProjectQueryVariables>(GetFirebaseProjectDocument, baseOptions);
        }
export type GetFirebaseProjectQueryHookResult = ReturnType<typeof useGetFirebaseProjectQuery>;
export type GetFirebaseProjectLazyQueryHookResult = ReturnType<typeof useGetFirebaseProjectLazyQuery>;
export type GetFirebaseProjectQueryResult = Apollo.QueryResult<GetFirebaseProjectQuery, GetFirebaseProjectQueryVariables>;
export const GetFirebaseProjectsByMicrositeIdDocument = gql`
    query GetFirebaseProjectsByMicrositeId($id: uuid!) {
  firebase_projects(where: {microsite: {id: {_eq: $id}}}) {
    id
    firebaseConfig
    serviceAccount
  }
}
    `;

/**
 * __useGetFirebaseProjectsByMicrositeIdQuery__
 *
 * To run a query within a React component, call `useGetFirebaseProjectsByMicrositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseProjectsByMicrositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirebaseProjectsByMicrositeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFirebaseProjectsByMicrositeIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFirebaseProjectsByMicrositeIdQuery, GetFirebaseProjectsByMicrositeIdQueryVariables>) {
        return Apollo.useQuery<GetFirebaseProjectsByMicrositeIdQuery, GetFirebaseProjectsByMicrositeIdQueryVariables>(GetFirebaseProjectsByMicrositeIdDocument, baseOptions);
      }
export function useGetFirebaseProjectsByMicrositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirebaseProjectsByMicrositeIdQuery, GetFirebaseProjectsByMicrositeIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFirebaseProjectsByMicrositeIdQuery, GetFirebaseProjectsByMicrositeIdQueryVariables>(GetFirebaseProjectsByMicrositeIdDocument, baseOptions);
        }
export type GetFirebaseProjectsByMicrositeIdQueryHookResult = ReturnType<typeof useGetFirebaseProjectsByMicrositeIdQuery>;
export type GetFirebaseProjectsByMicrositeIdLazyQueryHookResult = ReturnType<typeof useGetFirebaseProjectsByMicrositeIdLazyQuery>;
export type GetFirebaseProjectsByMicrositeIdQueryResult = Apollo.QueryResult<GetFirebaseProjectsByMicrositeIdQuery, GetFirebaseProjectsByMicrositeIdQueryVariables>;
export const GetVercelProjectsByMicrositeIdDocument = gql`
    query GetVercelProjectsByMicrositeId($id: uuid!) {
  vercel_projects(where: {microsite: {id: {_eq: $id}}}) {
    id
    name
  }
}
    `;

/**
 * __useGetVercelProjectsByMicrositeIdQuery__
 *
 * To run a query within a React component, call `useGetVercelProjectsByMicrositeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVercelProjectsByMicrositeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVercelProjectsByMicrositeIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVercelProjectsByMicrositeIdQuery(baseOptions?: Apollo.QueryHookOptions<GetVercelProjectsByMicrositeIdQuery, GetVercelProjectsByMicrositeIdQueryVariables>) {
        return Apollo.useQuery<GetVercelProjectsByMicrositeIdQuery, GetVercelProjectsByMicrositeIdQueryVariables>(GetVercelProjectsByMicrositeIdDocument, baseOptions);
      }
export function useGetVercelProjectsByMicrositeIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVercelProjectsByMicrositeIdQuery, GetVercelProjectsByMicrositeIdQueryVariables>) {
          return Apollo.useLazyQuery<GetVercelProjectsByMicrositeIdQuery, GetVercelProjectsByMicrositeIdQueryVariables>(GetVercelProjectsByMicrositeIdDocument, baseOptions);
        }
export type GetVercelProjectsByMicrositeIdQueryHookResult = ReturnType<typeof useGetVercelProjectsByMicrositeIdQuery>;
export type GetVercelProjectsByMicrositeIdLazyQueryHookResult = ReturnType<typeof useGetVercelProjectsByMicrositeIdLazyQuery>;
export type GetVercelProjectsByMicrositeIdQueryResult = Apollo.QueryResult<GetVercelProjectsByMicrositeIdQuery, GetVercelProjectsByMicrositeIdQueryVariables>;
export const GetVercelProjectsByEventIdDocument = gql`
    query GetVercelProjectsByEventId($id: uuid!) {
  vercel_projects(where: {microsite: {events: {event_id: {_eq: $id}}}}) {
    id
    name
  }
}
    `;

/**
 * __useGetVercelProjectsByEventIdQuery__
 *
 * To run a query within a React component, call `useGetVercelProjectsByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVercelProjectsByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVercelProjectsByEventIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVercelProjectsByEventIdQuery(baseOptions?: Apollo.QueryHookOptions<GetVercelProjectsByEventIdQuery, GetVercelProjectsByEventIdQueryVariables>) {
        return Apollo.useQuery<GetVercelProjectsByEventIdQuery, GetVercelProjectsByEventIdQueryVariables>(GetVercelProjectsByEventIdDocument, baseOptions);
      }
export function useGetVercelProjectsByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVercelProjectsByEventIdQuery, GetVercelProjectsByEventIdQueryVariables>) {
          return Apollo.useLazyQuery<GetVercelProjectsByEventIdQuery, GetVercelProjectsByEventIdQueryVariables>(GetVercelProjectsByEventIdDocument, baseOptions);
        }
export type GetVercelProjectsByEventIdQueryHookResult = ReturnType<typeof useGetVercelProjectsByEventIdQuery>;
export type GetVercelProjectsByEventIdLazyQueryHookResult = ReturnType<typeof useGetVercelProjectsByEventIdLazyQuery>;
export type GetVercelProjectsByEventIdQueryResult = Apollo.QueryResult<GetVercelProjectsByEventIdQuery, GetVercelProjectsByEventIdQueryVariables>;
export const GetFirebaseProjectsByEventIdDocument = gql`
    query GetFirebaseProjectsByEventId($id: uuid!) {
  firebase_projects(where: {microsite: {events: {event_id: {_eq: $id}}}}) {
    id
  }
}
    `;

/**
 * __useGetFirebaseProjectsByEventIdQuery__
 *
 * To run a query within a React component, call `useGetFirebaseProjectsByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseProjectsByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirebaseProjectsByEventIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFirebaseProjectsByEventIdQuery(baseOptions?: Apollo.QueryHookOptions<GetFirebaseProjectsByEventIdQuery, GetFirebaseProjectsByEventIdQueryVariables>) {
        return Apollo.useQuery<GetFirebaseProjectsByEventIdQuery, GetFirebaseProjectsByEventIdQueryVariables>(GetFirebaseProjectsByEventIdDocument, baseOptions);
      }
export function useGetFirebaseProjectsByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirebaseProjectsByEventIdQuery, GetFirebaseProjectsByEventIdQueryVariables>) {
          return Apollo.useLazyQuery<GetFirebaseProjectsByEventIdQuery, GetFirebaseProjectsByEventIdQueryVariables>(GetFirebaseProjectsByEventIdDocument, baseOptions);
        }
export type GetFirebaseProjectsByEventIdQueryHookResult = ReturnType<typeof useGetFirebaseProjectsByEventIdQuery>;
export type GetFirebaseProjectsByEventIdLazyQueryHookResult = ReturnType<typeof useGetFirebaseProjectsByEventIdLazyQuery>;
export type GetFirebaseProjectsByEventIdQueryResult = Apollo.QueryResult<GetFirebaseProjectsByEventIdQuery, GetFirebaseProjectsByEventIdQueryVariables>;
export const GetStringTranslationsByIdsDocument = gql`
    query GetStringTranslationsByIds($ids: [String!]) {
  string_translations(where: {id: {_in: $ids}}) {
    ...stringTranslationFields
  }
}
    ${StringTranslationFieldsFragmentDoc}`;

/**
 * __useGetStringTranslationsByIdsQuery__
 *
 * To run a query within a React component, call `useGetStringTranslationsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStringTranslationsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStringTranslationsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetStringTranslationsByIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetStringTranslationsByIdsQuery, GetStringTranslationsByIdsQueryVariables>) {
        return Apollo.useQuery<GetStringTranslationsByIdsQuery, GetStringTranslationsByIdsQueryVariables>(GetStringTranslationsByIdsDocument, baseOptions);
      }
export function useGetStringTranslationsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStringTranslationsByIdsQuery, GetStringTranslationsByIdsQueryVariables>) {
          return Apollo.useLazyQuery<GetStringTranslationsByIdsQuery, GetStringTranslationsByIdsQueryVariables>(GetStringTranslationsByIdsDocument, baseOptions);
        }
export type GetStringTranslationsByIdsQueryHookResult = ReturnType<typeof useGetStringTranslationsByIdsQuery>;
export type GetStringTranslationsByIdsLazyQueryHookResult = ReturnType<typeof useGetStringTranslationsByIdsLazyQuery>;
export type GetStringTranslationsByIdsQueryResult = Apollo.QueryResult<GetStringTranslationsByIdsQuery, GetStringTranslationsByIdsQueryVariables>;
export const GetUsersByEmailDocument = gql`
    query GetUsersByEmail($email: String!) {
  users(where: {email: {_eq: $email}}) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUsersByEmailQuery__
 *
 * To run a query within a React component, call `useGetUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUsersByEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
        return Apollo.useQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, baseOptions);
      }
export function useGetUsersByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>(GetUsersByEmailDocument, baseOptions);
        }
export type GetUsersByEmailQueryHookResult = ReturnType<typeof useGetUsersByEmailQuery>;
export type GetUsersByEmailLazyQueryHookResult = ReturnType<typeof useGetUsersByEmailLazyQuery>;
export type GetUsersByEmailQueryResult = Apollo.QueryResult<GetUsersByEmailQuery, GetUsersByEmailQueryVariables>;